import React from "react";
import { shareRef } from "../../lib";
export const IFrame = React.forwardRef(function IFrame({ width = "100%", ...props }, ref) {
    const [height, setHeight] = React.useState(null);
    const innerRef = React.useRef();
    const onLoad = React.useCallback(params => {
        props.onLoad?.(params);
        if (props.autoHeight)
            setHeight(`${innerRef.current.contentWindow.document.body.scrollHeight}px`);
    }, [props.onLoad]);
    return (React.createElement("iframe", { ...props, width: width, height: height ?? props.height, ref: shareRef(innerRef, ref), onLoad: onLoad }));
});
// type: hotel, flight
