import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Color from "color";
import { omit } from "lodash";
import React from "react";
import styled, { css } from "styled-components";
import { useThemeColor } from "../../Css";
import { Dropdown } from "../Dropdown";
import { Label } from "../Label";
const WizardSteps = function Wizard(props) {
    return (React.createElement(WizardContainer, { ...props }, props.children));
};
const WizardItem = function WizardItem(props) {
    const theme = useThemeColor(props.color);
    return React.createElement(WizardListItem, { disabled: props.disabled, themeColor: props.active ? theme?.[100].main ?? "#b4b4b4" : undefined, style: { borderTop: `2px solid ${theme?.[500].main ?? "transparent"}`, color: props.active ? theme?.[100].font : undefined, ...props.style }, ...omit(props, "style") }, props.children);
};
const WizardDropdown = function WizardDropdown(props) {
    return React.createElement("div", null,
        React.createElement(Dropdown, { component: (p) => React.createElement("div", { ...p }), title: React.createElement(FontAwesomeIcon, { icon: faCaretDown }) }, props.children));
};
const WizardContainer = styled.ul `
	margin: 0 auto;
    padding: 0;
    height: 39px;
	font-size: 13px;
    transform: scale(1);
	line-height: 2;
    display: ${e => e.fillSpace ? "flex" : "inline-block"};
    /* background: #fff; */
    counter-reset: step -1;
    width: auto;

	span.badge-in-label {
		position: unset;
		right: unset;
		top: unset;
		margin-left: 3px;
	}
`;
const Badge = (props) => {
    const classes = classNames(props.className, { "badge-in-label": props.inLabel });
    return React.createElement(BadgeStyle, { ...props, className: classes });
};
const BadgeStyle = styled(Label) `
	font-size: 11px;
	right: -12px;
	top: -10px;
	z-index: 10;
	display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
	position: absolute;
`;
const WizardListItem = styled.li `
    position: relative;
	font-variant: small-caps;
	flex: 1;
	// font-size: .8rem;
    margin-right: 5px;
	display: inline-block;
	&:last-child {
		margin-right: 0px;
	}
	padding: 6px 11px 6px 24px;
    list-style: none;
    background: ${e => e.themeColor ?? "#dddddd"};
    zoom: 1;
    *display: inline;
	cursor: pointer;
    counter-increment: step;
	${e => {
    if (e.disabled) {
        let color = Color(e.themeColor ?? "#dddddd");
        color = color.lighten(0.1);
        return css `
				pointer-events: none;
				background: ${color.hex()};
				cursor: not-allowed !important;
				&::after {
					border-left-color: ${color.hex()} !important;
					cursor: not-allowed !important;
				}
			`;
    }
    return "";
}}
	&:hover {
		${p => {
    let cl = Color(p.themeColor ?? "#dddddd");
    cl = cl.darken(.1);
    return css `
			background: ${cl.hex()};
			&::after {
				border-left: 16px solid ${cl.hex()};
			}
		`;
}}
	}

	&::before {
		position: absolute;
		left: 0;
		top: 0;
		height: 0;
		width: 0;
		border-bottom: 22px inset transparent;
		border-left: 16px solid #fff;
		border-top: 16px inset transparent;
		content: "";
	}
	&::after {
		position: absolute;
		right: -16px;
		top: 0;
		height: 0;
		width: 0;
		border-bottom: 22px inset transparent;
		border-left: 16px solid ${e => e.themeColor ?? "#dddddd"};
		border-top: 16px inset transparent;
		content: "";
		z-index: 2;
	}
	&:first-child {
		padding-left: 12px;
		:before {
			position: absolute;
			left: 0;
			top: 0;
			height: 0;
			width: 0;
			border-bottom: none;
			border-left: none;
			border-top: none;
			content: "";
		}
	}

	&:last-child {
		:after {
		position: absolute;
		right: -16px;
		top: 0;
		height: 0;
		width: 0;
		border-bottom: none;
		border-left: none;
		border-top: none;
		content: "";
		z-index: 2;
	}
	}
`;
/**
 * @deprecated Use WorkflowWidget instead!
 */
export const Wizard = Object.assign(WizardSteps, {
    Step: WizardItem,
    Badge: Badge,
    Dropdown: WizardDropdown,
});
