import { ClientCategoryUtil, FpApi } from "@tcs-rliess/fp-core";
import { uniq } from "lodash-es";

import { FleetplanApp } from "../FleetplanApp";

export class ContactUtil {
	/**
	 * Check if a contact is a flight crew member.  
	 * If the setting "contact.flightCrewByTag" is enabled, the contact is considered a flight crew member if it has a flightcrew tag.  
	 * If the setting is disabled, all contacts are considered flight crew members.
	 *
	 */
	public static isFlightCrew(app: FleetplanApp, dscaid: number, ignoreSetting = false): boolean {
		if (app.store.settingsProject.getBoolean("contact.flightCrewByTag") || ignoreSetting) {
			const contact = app.store.contact.getId(dscaid);
			const mappedTagsDir = contact.tags_dir?.map(e => e.split(":")[1]);
			if (mappedTagsDir?.length) {
				// check if any of the tags is a flightcrew tag
				const isFlightCrew = mappedTagsDir.some(tag => {
					// tag can be any possible string, not just an enum from the EventResourceRole
					const contactDirTag = ClientCategoryUtil.byEnum(FpApi.Calendar.Event.EventResourceRole).getOption(tag as any);
					return contactDirTag?.type?.includes("flightcrew");
				});
				if (isFlightCrew) return true;
			}

			return false;
		} else {
			// old default - everyone is "flight crew"
			return true;
		}
	}

	/**
	 * Get the distinct roles of all contacts in the project. Returns a list of all roles that are assigned to at least one contact.
	 */
	public static getDistinctRoles(app: FleetplanApp): string[] {
		const contacts = app.store.contact.getAll();

		// step 1: get all tags_dir that start with "R:" (R = Role)
		// step 2: extract the role name from the tag
		// step 3: remove duplicates
		const tags = uniq(
			contacts.flatMap(c => c.tags_dir)
				.filter(t => t.startsWith("R:"))
				.map(t => t.split(":")[1])
		);

		return tags;
	}
}
