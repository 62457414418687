import { merge } from "lodash";
import React from "react";
import styled, { css } from "styled-components";
import { Button, Flex, FlexItem } from "../../Components";
import { DockerLayoutContext } from "./context";
import { DockedLayoutSide } from "./util";
/** @deprecated use docked manager */
const DockedLayoutRoot = styled(Flex) `
	position: relative;
`;
/** @deprecated use docked manager */
const LeftBar = styled(FlexItem) `
	max-width: 29px;
	border-right: 1px solid #dddddd;
	position: relative;
	z-index: 2;
	background: #ffffff;
`;
/** @deprecated use docked manager */
const LeftTabs = styled.div `
	transform-origin: top left;
	transform: rotate(90deg) translateY(-28px);
	white-space: nowrap;

	& > * {
		transform: rotate(180deg);
		padding-top: 6px;
	}
`;
/** @deprecated use docked manager */
const RightBar = styled(FlexItem) `
	max-width: 29px;
	border-left: 1px solid #dddddd;
	position: relative;
	z-index: 2;
	background: #ffffff;
`;
/** @deprecated use docked manager */
const RightTabs = styled.div `
	transform-origin: top right;
	transform: rotate(90deg) translateX(28px);
	white-space: nowrap;

	& > * {
		padding-bottom: 6px;
	}
`;
/** @deprecated use docked manager */
const StyledButton = styled(Button) `
	${props => {
    return props.active && css `
			background-color: #337ab7 !important;
			color: white !important;
		`;
}}
	&:hover {
		background-color: #337ab7 !important;
		color: white !important;
	}
`;
/** @deprecated use docked manager */
const LeftPanel = styled(FlexItem) `
	width: 29px;
	border-right: 1px solid #dddddd;
	background: #ffffff;

	${p => p.pinned ? "" : `
		position: absolute;
		top: 0;
		bottom: 0;
		left: ${p.singleElement ? "0px" : "29px"};
		z-index: 3;
	`}
`;
/** @deprecated use docked manager */
const RightPanel = styled(FlexItem) `
	width: 29px;
	border-left: 1px solid #dddddd;
	background: #ffffff;

	${p => p.pinned ? "" : `
		position: absolute;
		top: 0;
		right: ${p.singleElement ? "0px" : "29px"};
		bottom: 0;
		z-index: 3;
	`}
`;
/** @deprecated use docked manager */
export const DockedLayout = function DockedLayout({ state, panels, leftDefaultWidth = 350, leftCollapse = "enabled", leftTogglePin = "enabled", rightDefaultWidth = 350, rightCollapse = "enabled", rightTogglePin = "enabled", onStateChange, style, children, }) {
    const leftActivePanel = state.left.key ? panels.find(p => p.key === state.left.key) : null;
    const rightActivePanel = state.right.key ? panels.find(p => p.key === state.right.key) : null;
    const panelsSorted = {
        [DockedLayoutSide.Left]: [],
        [DockedLayoutSide.Right]: [],
    };
    for (const panel of panels) {
        // only panels with label and side get a button
        if (panel.label == null || panel.side == null)
            continue;
        panelsSorted[panel.side].push(panel);
    }
    function setState(newState) {
        onStateChange(merge({}, state, newState));
    }
    return React.createElement(DockedLayoutRoot, { style: style },
        panelsSorted.LEFT.length ? (React.createElement(React.Fragment, null, panelsSorted.LEFT.length === 1 && panelsSorted.LEFT[0].key === leftActivePanel?.key ? null : (React.createElement(LeftBar, null,
            React.createElement(LeftTabs, null, panelsSorted.LEFT.map(panel => {
                if (panel.spacer) {
                    return React.createElement(StyledButton, { key: panel.key, disabled: true, style: { padding: 0, height: "100%", border: "none", borderRight: "1px solid #ddd" } },
                        React.createElement("span", null, "\u00A0"));
                }
                return React.createElement(StyledButton, { disabled: panel.disabled, key: panel.key, title: panel.title, active: panel.key === state.left.key, size: "sm", style: { border: "none" }, onClick: () => {
                        panel.onClick?.();
                        if (!panel.content)
                            return;
                        if (panel.disabled)
                            return;
                        if (panel.key === state.left.key) {
                            setState({ left: { key: null } });
                        }
                        else {
                            setState({ left: { key: panel.key } });
                        }
                    } }, panel.label);
            })))))) : null,
        leftActivePanel ? (React.createElement(DockerLayoutContext.Provider, { value: {
                side: DockedLayoutSide.Left,
                isPinned: state.left.pinned ?? true,
                collapseMode: leftCollapse,
                pinMode: leftTogglePin,
                onCollapse: () => setState({ left: { key: null } }),
                onPinnedToggle: () => setState({ left: { pinned: !(state.left.pinned ?? true) } }),
            } },
            React.createElement(LeftPanel, { basis: leftActivePanel.width ?? leftDefaultWidth, style: { width: leftActivePanel.width ?? leftDefaultWidth }, pinned: state.left.pinned ?? true, singleElement: panelsSorted.LEFT.length === 1 && panelsSorted.LEFT[0].key === leftActivePanel?.key },
                React.createElement(leftActivePanel.content, null)))) : null,
        React.createElement(FlexItem, { grow: true, style: { overflow: "auto" } }, children),
        rightActivePanel ? (React.createElement(DockerLayoutContext.Provider, { value: {
                side: DockedLayoutSide.Right,
                isPinned: state.right.pinned ?? true,
                collapseMode: rightCollapse,
                pinMode: rightTogglePin,
                onCollapse: () => setState({ right: { key: null } }),
                onPinnedToggle: () => setState({ right: { pinned: !(state.right.pinned ?? true) } }),
            } },
            React.createElement(RightPanel, { basis: rightActivePanel.width ?? rightDefaultWidth, style: { width: rightActivePanel.width ?? rightDefaultWidth }, pinned: state.right.pinned ?? true, singleElement: panelsSorted.RIGHT.length === 1 && panelsSorted.RIGHT[0].key === rightActivePanel?.key },
                React.createElement(rightActivePanel.content, null)))) : null,
        panelsSorted.RIGHT.length ? (React.createElement(React.Fragment, null, panelsSorted.RIGHT.length === 1 && panelsSorted.RIGHT[0].key === rightActivePanel?.key ? null : (React.createElement(RightBar, null,
            React.createElement(RightTabs, null, panelsSorted.RIGHT.map(panel => {
                if (panel.spacer) {
                    return React.createElement(StyledButton, { key: panel.key, disabled: true, style: { padding: 0, height: "100%", border: "none", borderRight: "1px solid #ddd" } },
                        React.createElement("span", null, "\u00A0"));
                }
                return React.createElement(StyledButton, { disabled: panel.disabled, key: panel.key, title: panel.title, active: panel.key === state.right.key, size: "sm", style: { border: "none" }, onClick: () => {
                        panel.onClick?.();
                        if (!panel.content)
                            return;
                        if (panel.disabled)
                            return;
                        if (panel.key === state.right.key) {
                            setState({ right: { key: null } });
                        }
                        else {
                            setState({ right: { key: panel.key } });
                        }
                    } }, panel.label);
            })))))) : null);
};
