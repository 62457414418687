import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { css } from "styled-components";
import { useThemeColor } from "../../Css";
import { Flex } from "../Flex";
import { Text } from "../Text";
const COLOR_FALLBACK = "#777777";
export const AlertStyled = styled.div `
	padding: .75rem 1.25rem;
	font-weight: 400;
	line-height: 200%;
	vertical-align: baseline;
	border: 1px solid transparent;
	margin: auto;
	background: ${props => props.$background};
	${props => {
    if (props.$elevation) {
        return css `
				box-shadow: -1px 2px 14px 0px ${props.$fontColor} !important;
			`;
    }
    return "";
}}

	color: ${p => p.$fontColor};
	& a {
		color: ${p => p.$fontColor};
	}
`;
export const Alert = function Alert({ color = "info", fullWidth, elevation, icon, style, onClick, children, ...props }) {
    const themeColor = useThemeColor(color, COLOR_FALLBACK);
    const borderColor = themeColor[500].main;
    const background = themeColor[100].main;
    return React.createElement(AlertStyled
    // passed
    , { 
        // passed
        color: color, "$elevation": elevation, onClick: onClick, "$fontColor": borderColor, "$background": background, style: {
            borderColor: borderColor,
            width: fullWidth ? "100%" : undefined,
            ...style,
        }, ...props },
        React.createElement(Flex, { gap: "1rem" },
            icon ? (React.createElement(Text, { size: "lg", color: borderColor },
                React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: icon }))) : (null),
            React.createElement("div", { style: { width: "100%" } }, children)));
};
