import React from "react";
import styled, { useTheme } from "styled-components";
import { useOverlay } from "../OverlayTrigger/OverlayTrigger";
export const Tooltip = function Tooltip({ placement: position = "top", ...props }) {
    const overlay = useOverlay();
    const theme = useTheme();
    position = overlay?.placement || position;
    const color = (() => {
        if (theme.color[props.variant]) {
            return theme.color[props.variant][500].main;
        }
        return props.variant;
    })();
    return (React.createElement(InternalTooltip, { "$variant": color, ref: (ref) => overlay?.register(ref), className: props.className, style: props.style },
        props.children,
        position === "top" && React.createElement(TopTick, { "$variant": color }),
        position === "left" && React.createElement(LeftTick, { "$variant": color }),
        position === "bottom" && React.createElement(BottomTick, { "$variant": color }),
        position === "right" && React.createElement(RightTick, { "$variant": color })));
};
const InternalTooltip = styled.div `
	border: 1px solid ${p => p.theme.defaults.borderColor};
	background-color: ${p => p.theme.background};
	max-width: 300px;
	padding: 3px 6px;
	box-shadow: 0 0 1em rgba(0, 0, 0, .25);
	font-size: 12px;
	position: relative;
	z-index: 10;
	isolation: isolate;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 2px;
		height: 100%;
		background-color: ${p => p.$variant};
	}
`;
const LeftTick = styled.div `
	position: absolute;
	left: -6px;
	width: 0;
	height: 0;
	top: calc(50% - 6px);
	right: 100%;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-right: 6px solid ${p => p.$variant};
`;
const RightTick = styled.div `
	position: absolute;
	right: -6px;
	width: 0;
	height: 0;
	top: calc(50% - 6px);
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 6px solid white;
`;
const TopTick = styled.div `
	position: absolute;
	top: -6px;
	left: calc(50% - 2px - 6px);
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid ${p => p.theme.background};
`;
const BottomTick = styled.div `
	position: absolute;
	bottom: -6px;
	left: calc(50% - 2px - 6px);
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid ${p => p.theme.background};
`;
