import { FpLandingFieldClient } from "@tcs-rliess/fp-core";
import type { MapGeoJSONFeature, MapStyle } from "@vis.gl/react-maplibre";
import {
	LayerSpecification,
	SourceSpecification,
	SymbolLayerSpecification,
} from "maplibre-gl";
import {
	observable,
	action,
	computed,
	decorate,
} from "mobx";

import type { FleetplanApp } from "../../../FleetplanApp";
import { handleError } from "../../../handleError";

const landingFieldClient = new FpLandingFieldClient({ use: "FPMap State" });
// Store map style json from server into ClientStore
export class GlobalMapStore {
	baseStyle?: MapStyle
	baseStyleTimer?;

	async initBaseStyle(app:FleetplanApp): Promise<void> {
		const response = await landingFieldClient.getConfig(app.dscid);
		//only for testing: get json directly to bypass caches
		//const query = await fetch(`https://maps.fleetplan.cloud/net/json/${app.dscid}.json`, { headers: { "fp-api-key": "549f86a56727831902a8d6951bd43e61" }});
		//const response = await query.json();
		if (response) { this.baseStyle = response; }
		if (this.baseStyleTimer === undefined) {
			this.baseStyleTimer = setInterval(() => { this.initBaseStyle(app).catch(handleError); }, 8640000);
		}
	}
}

// Store for each separate map widget (since we can theoretically have at least 3 open at the same time)
export class MapStore {
	baseStyle?: MapStyle;
	features: MapGeoJSONFeature[] = [];
	filter = new Set<string>();
	visibilities: { [key: string]: boolean } = {};
	tools = { layerSelection: false, search: false, poiSelection: false };
	showNames = true;
	geoSearchTerm = "";
	poiSearchTerm = "";
	searchResults: { type?: "FeatureCollection", features?: MapGeoJSONFeature[] } = {};
	dscaid: number;

	constructor(app: FleetplanApp) {
		this.dscaid = app.dscaid;
		const storedSettings = JSON.parse(
			localStorage.getItem(`map_settings_${this.dscaid}`) ||
			'{"layerfilter": [], "visibilities": {}, "showNames": true}'
		);
		this.filter = new Set(storedSettings.layerfilter);
		this.visibilities = storedSettings.visibilities;
		this.showNames = storedSettings.showNames;
		if (!app.store?.fpMap.baseStyle) {
			(async () => { await app.store.fpMap.initBaseStyle(app); this.baseStyle = app.store.fpMap.baseStyle; })().catch(handleError);
		} else {
			this.baseStyle = app.store.fpMap.baseStyle;
		}

		this.setNames();
		for (const [ layer, visibility ] of Object.entries(this.visibilities)) {
			if (visibility === false) {
				this.setLayerVisibility(layer, visibility);
			}
		}
		if (this.filter.size > 0) {
			const layer = this.baseStyle?.layers.find(
				(layer) => layer.id === "customerpoilayer"
			) as SymbolLayerSpecification | undefined;
			if (layer) {
				layer.filter = [
					"!",
					[ "in", [ "get", "type" ], [ "literal", [ ...this.filter ]]],
				];
			}
		}
	}

	// Computed variable listing the types of customer features and their count
	get typeCounts(): {name: string, count: number}[] {
		if (this.features.length > 0) {
			const returnArray: { name: string; count: number }[] = [];
			const featureTypes = [
				...new Set(this.features.map((feature) => feature.properties.type)),
			].sort();
			for (const featureType of featureTypes) {
				returnArray.push({
					name: featureType,
					count: this.features.filter(
						(feature) => feature.properties.type === featureType
					).length,
				});
			}
			return returnArray;
		} else {
			return undefined;
		}
	}

	// Computed variable listing customer features matching the filter (min. 3 characters)
	get poiSearchResults(): MapGeoJSONFeature[] {
		if (this.poiSearchTerm.length > 2) {
			return this.features
				.filter(
					(feature) =>
						feature.properties.name.toLowerCase().includes(this.poiSearchTerm.toLowerCase()) ||
						feature.properties.code.toLowerCase().includes(this.poiSearchTerm.toLowerCase())
				)
				.slice(0, 5);
		} else {
			return [];
		}
	}

	// Computed variable saving the settings object to be saved in localStorage
	get localStorageSettings(): string {
		return JSON.stringify({
			layerfilter: [ ...this.filter ],
			visibilities: this.visibilities,
			showNames: this.showNames,
		});
	}

	// Is any tool active? Used for class of mapoverlay
	get active(): boolean {
		return Object.values(this.tools).some(status => status);
	}

	updateFeatures(newFeatures: MapGeoJSONFeature[]): void {
		this.features = newFeatures || [];
	}

	getLayerVisibility(layerID: string): "visible" | "none" {
		const layer = this.baseStyle?.layers.find((layer) => layer.id === layerID);
		if (layer) {
			// If a layer's visibility is not defined, it defaults to visible
			return layer.layout?.visibility || "visible";
		}
	}

	setLayerVisibility(layerID: string, visibility: boolean): void {
		const layer = this.baseStyle?.layers.find((layer) => layer.id === layerID);
		if (layer) {
			layer.layout ??= { visibility: "visible" };
			layer.layout.visibility = visibility ? "visible" : "none";
		}
	}

	toggleLayerVisibility(layerID: string): void {
		const layer = this.baseStyle?.layers.find((layer) => layer.id === layerID);
		if (layer) {
			layer.layout ??= { visibility: "visible" };
			layer.layout.visibility =
				layer.layout.visibility == "none" ? "visible" : "none";
			if (layerID !== "rasterlayer") {
				this.visibilities[layerID] =
					layer.layout.visibility == "none" ? false : true;
				localStorage.setItem(`map_settings_${this.dscaid}`, this.localStorageSettings);
			}
		}
	}

	toggleTerrain(): void {
		if (this.baseStyle?.terrain) {
			delete this.baseStyle.terrain;
			const layer = this.baseStyle.layers.find(
				(layer) => layer.id === "hillshading"
			);
			if (layer) {
				layer.layout.visibility = "none";
			}
		} else {
			this.baseStyle.terrain = {
				source: "terrarium",
				exaggeration: 1,
			};
			const layer = this.baseStyle?.layers.find(
				(layer) => layer.id === "hillshading"
			);
			if (layer) {
				layer.layout.visibility = "visible";
			}
		}
	}

	toggleTerrainOnly(): void {
		if (this.baseStyle?.terrain) {
			delete this.baseStyle.terrain;
		} else {
			this.baseStyle.terrain = {
				source: "terrarium",
				exaggeration: 1,
			};
		}
	}
	// Checks if the supplied string is NOT being filtered out
	checkInFilter(type: string): boolean {
		if (this.filter.size === 0) {
			return true;
		} else {
			return !this.filter.has(type);
		}
	}

	addToFilter(type: string): void {
		this.filter.add(type);
		localStorage.setItem(`map_settings_${this.dscaid}`, this.localStorageSettings);
		const layer = this.baseStyle?.layers.find(
			(layer) => layer.id === "customerpoilayer"
		) as SymbolLayerSpecification | undefined;
		if (layer) {
			layer.filter = [
				"!",
				[ "in", [ "get", "type" ], [ "literal", [ ...this.filter ]]],
			];
		}
	}

	removeFromFilter(type: string): void {
		this.filter.delete(type);
		localStorage.setItem(`map_settings_${this.dscaid}`, this.localStorageSettings);
		if (this.filter.size > 0) {
			const layer = this.baseStyle?.layers.find(
				(layer) => layer.id === "customerpoilayer"
			) as SymbolLayerSpecification | undefined;
			if (layer) {
				layer.filter = [
					"!",
					[ "in", [ "get", "type" ], [ "literal", [ ...this.filter ]]],
				];
			}
		} else {
			const layer = this.baseStyle?.layers.find(
				(layer) => layer.id === "customerpoilayer"
			) as SymbolLayerSpecification | undefined;
			if (layer) {
				delete layer.filter;
			}
		}
	}

	// Toggles the selected tool - if it is now active, all other tools are set to inactive
	toggleTools(activeTool: string): void {
		this.tools[activeTool] = !this.tools[activeTool];
		if (this.tools[activeTool]) {
			for (const tool of Object.keys(this.tools).filter(tool => tool !== activeTool)) {
				this.tools[tool] = false;
			}
		}
	}
	// Get the static JSON of the basestyle for use in the props of the map component
	get toJSON(): string {
		return JSON.stringify(this.baseStyle);
	}

	toggleNames(): void {
		this.showNames = !this.showNames;
		this.setNames();
		localStorage.setItem(`map_settings_${this.dscaid}`, this.localStorageSettings);
	}

	// sets or removes the "text-field" for customer & FP POI layers
	setNames(): void {
		const poilayer = this.baseStyle?.layers.find(
			(layer) => layer.id === "customerpoilayer"
		);
		const otherlayer = this.baseStyle?.layers.find(
			(layer) => layer.id === "generalpoilayer-other"
		);
		const largelayer = this.baseStyle?.layers.find(
			(layer) => layer.id === "generalpoilayer-large"
		);

		for (const layer of [ poilayer, otherlayer, largelayer ]) {
			if (layer && this.showNames) {
				layer.layout["text-field"] = "{code}";
				layer.layout["text-font"] ??= [ "noto_sans_regular" ];
			} else if (layer) {
				delete layer.layout["text-field"];
			}
		}
	}

	addLayer(
		sourceName: string,
		source: SourceSpecification,
		newLayer: LayerSpecification
	): void {
		if (this.baseStyle) {
			this.baseStyle.sources[sourceName] = source;
			if (
				!this.baseStyle.layers.find((layer) => {
					return layer.id === newLayer.id;
				})
			) {
				this.baseStyle.layers.push(newLayer);
			}
		}
	}

	setSearchTerm(searchTerm: string, state: "geoSearchTerm" | "poiSearchTerm"): void {
		this[state] = searchTerm;
	}

	setSearchResults(searchResults: { type?: "FeatureCollection", features?: MapGeoJSONFeature[] }): void {
		this.searchResults = searchResults;
	}

	removeLayer(layerID: string): void {
		this.baseStyle.layers = this.baseStyle?.layers.filter(
			(layer) => layer.id !== layerID
		);
	}
}

decorate(MapStore, {
	baseStyle: observable,
	features: observable,
	filter: observable,
	visibilities: observable,
	showNames: observable,
	geoSearchTerm: observable,
	poiSearchTerm: observable,
	searchResults: observable,
	tools: observable,
	toggleLayerVisibility: action,
	setLayerVisibility: action,
	toggleTerrain: action,
	toggleTerrainOnly: action,
	updateFeatures: action,
	typeCounts: computed,
	toJSON: computed,
	active: computed,
	poiSearchResults: computed,
	localStorageSettings: computed,
	removeFromFilter: action,
	addToFilter: action,
	toggleTools: action,
	toggleNames: action,
	setNames: action,
	addLayer: action,
	removeLayer: action,
	setSearchTerm: action,
	setSearchResults: action
});

decorate(GlobalMapStore, {
	baseStyle: observable.ref,
	baseStyleTimer: observable,
	initBaseStyle: action
});
