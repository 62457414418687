import { FpApi, FpDirNodeKind } from "@tcs-rliess/fp-core";
import { StringSuggestion } from "@tcs-rliess/fp-web-ui";


export const CALENDAR_CONFIGURATION_EMPTY: CalendarConfiguration = {
	id: -1,
	name: "",
	events: {},
	resources: [],
};

export interface CalendarConfiguration {
	id: number;
	name: string;

	events: {
		/**
		 * - Calendar Holiday Sets
		 */
		holiday?: {
			enabled?: boolean;

			/**
			 * output as Events
			 */
			outputEvent?: boolean;
			/**
			 * output as time ranges
			 */
			outputTimeRange?: boolean;

			/**
			 * set id
			 */
			setId: number;
			/**
			 * 
			 */
			subdivision: string[];
		};
		/**
		 * - Resource Duty
		 * - Resource Duty Wish
		 * - Setup Validation
		 */
		resourceDuty?: {
			enabled?: boolean;
			creatable?: boolean;
		};
		/**
		 * - Resource Schedule
		 */
		resourceSchedule?: {
			enabled?: boolean;
			creatable?: boolean;
			// types?: FpApi.Resource.Duty.ScheduleType[];
		};
		/**
		 * - Flight Bookings
		 * - Trainings
		 * - Calendar Events
		 */
		calendarEvents?: {
			/** @deprecated */
			creatable?: boolean;

			types: Partial<Record<"DEFAULT" | FpApi.Calendar.Event.EventType, boolean | {
				enabled?: boolean;
				creatable?: boolean;
			}>>;
		};
		/**
		 * - Aircraft Leg
		 */
		aircraftLeg?: {
			enabled?: boolean;
			creatable?: boolean;
		};
		/**
		 * - Crew Leg
		 */
		crewLeg?: {
			enabled?: boolean;
		};
		/**
		 * - Flight & Duty Times
		 * - Activity
		 * - Duty / Required Rest
		 */
		activity?: {
			enabled?: boolean;
		};
		/**
		 * - Weekend
		 */
		weekend?: {
			enabled?: boolean;
			/**
			 * number between 1-7
			 * 
			 * 1 Monday
			 * 2 Tuesday
			 * 3 Wednesday
			 * 4 Thursday
			 * 5 Friday
			 * 6 Saturday
			 * 7 Sunday
			 */
			days: number[];
		};
		/**
		 * certificate
		 */
		certificate?: {
			enabled?: boolean;
		};
	};

	plugins?: {
		/**
		 * Scheduler in Contact Profile
		 * Also affects how resource schedules are loaded.
		 */
		aircraft?: {
			enabled?: boolean;
			showRemaining?: boolean;
			showLastFlight?: boolean;
			showStatus?: boolean;
			showCertificate?: boolean;
			showFlightTime?: boolean;
		},
		flightFollowing?: {
			enabled?: boolean;
			startOnOpen?: boolean;
			allowStop?: boolean;
			playSound?: boolean;
		},
		bosStatus?: {
			enabled?: boolean;
			fpdirloc?: number;
		};
		checkIn?: {
			enabled?: boolean;
		};
		debugger?: {
			enabled?: boolean;
		};
		directory?: {
			enabled?: boolean;
		};
		gridBridge?: {
			enabled?: boolean;
		};
		holiday?: {
			enabled?: boolean;
		};
		contactScheduler?: {
			enabled?: boolean
			dscaid: number;
			showWeekdays?: boolean;
			eventsPerDay?: number;
		};
		filter?: {
			enabled?: boolean;
		};
		// this is kind of an super plugin. It will be extended by other plugins
		// selection?: {
		// 	enabled?: boolean;
		// };
		shift?: {
			enabled?: boolean;
		};
		smart?: {
			enabled?: boolean;
		};
		standby?: {
			enabled?: boolean;
		};
		localDay?: {
			enabled?: boolean;
		};
		taskLoader?: {
			enabled?: boolean;
		};
		flightPlanning?: {
			enabled?: boolean;
			flightTemplate?: {
				filter?: Omit<FpApi.InlineRelation, "category">[]
			}
		}
		scrollTo?: {
			enabled?: boolean;
			block:"start"|"end"|"center";
			edgeOffset?: number;
		}
		timezone?: {
			/**
			 * project: use the project timezone (settings)
			 * user: use the user timezone (settings)
			 * infer: infer the timezone from resources.
			 */
			zone?: StringSuggestion<"user">;
			allowChange?: boolean;
			useDirectoryCtx?: boolean;
		};
		workPattern?: {
			enabled?: boolean;
			workPatternColumn?: boolean;
		}
		contact?: {
			enabled?: boolean;
			allowAddTask?: boolean;
			allowPersonalCalendar?: boolean;
			/** Permissions still apply */
			allowEditContact?: boolean;
			showLocations?: boolean;
			showInfo?: boolean;
			showCertificate?: boolean;
			/** displays INexperience when a contact is not experienced in a role */
			showExperience?: boolean;
			/** ADT-YDT: Yearly Actual Duty Time (to today)*/
			showAdtytd?: boolean;
			workPatternColumn?: boolean;
			inlineFilter?: boolean;
		}
		/** views */
		agenda?: {
			enabled?: boolean;
		},
		day?: {
			enabled?: boolean;
		},
		month?: {
			enabled?: boolean;
		},
		schedulerpro?: {
			enabled?: boolean;
			allowZoom?: boolean;
			preset?: "dutyRoster" | "default";
			initialView?: string;
			minZoomLevel?: number;
			maxZoomLevel?: number;
			zoomKeepsOriginalTimespan?: boolean;
			hours?: {
				startHours?: number;
				endHours?: number;
			};
			sort?: { field: string, ascending: boolean }
		},
		week?: {
			enabled?: boolean;
		},
		year?: {
			enabled?: boolean;
		},
		resource?: {
			enabled?: boolean;
		},
		resourceDay?: {
			enabled?: boolean;
		},
		resourceMonth?: {
			enabled?: boolean;
		},
		verticalScheduler?: {
			enabled?: boolean;
			allowZoom?: boolean;
			preset?: "dutyRoster" | "default";
		},
		dutyRoster?: {
			showWorkflowPanel?: boolean;
			hideChangesPanel?: boolean;
			enabled?: boolean;
			filter?: {
				/** only applies, when disableSync:true */
				initialFilter?: Array<string>;
				disableSync?: boolean;
			}
		},
		dutyRosterValidation?: {
			enabled?: boolean;
		};
		multiNodeCalendar?: {
			enabled?: boolean;
			kind: FpDirNodeKind;
		},
		interaction?: {
			showTasks?: boolean;
			disabled?: boolean;
		},
		timeManagement?: {
			enabled?: boolean;
		},
		dutyRosterREDIS?: {
			enabled?: boolean;
			fpdirloc?: number;
			initialDistance?: number
		};
		/**
		 * works only in in smart scheduler
		 */
		//#region smart
		connector?: {
			enabled?: boolean;
		};
		map?: {
			enabled?: boolean;
		};

		//#endregion
	}

	initialView?: string;

	resources: Array<CalendarConfigurationItem>;
}

export interface CalendarConfigurationItem {
	/**
	 * not used by the scheduler store
	 */
	id: string;
	kind: "FOLDER" | "RESOURCE" | "SMART";

	folder?: {
		id: string;
		name: string;
		linkExtra?: any;
		expanded?: boolean;
	};

	resource?: {
		linkType: string;
		linkId: string;
		/** extra value to allow the same link multiple time within one parent */
		linkIdExtra?: string;
		linkExtra?: any;
	};

	smart?: {
		linkType: "fpvid" | "fpdirid" | "fpdiridMerge" | "positionSetup";
		linkExtra?: any;
		fpdirid?: {
			filter: {
				/**
				 * only required if `parent` is 0
				 */
				kind?: FpDirNodeKind;

				fpdirloc?: number;
				fpdirgrp?: number;
				fpdirpos?: number;
				fpdirsec?: number;
			}

			/**
			 * Add children of this node
			 */
			recursive: boolean;

			/**
			 * only on locations
			 */
			resourceSetup?: boolean;
			/**
			 * only on locations
			 */
			aircraft?: boolean;
			/**
			 * Contacts that are members of this node
			 */
			contact?: {
				enabled?: boolean;
				groupBy?: "SETUP_ROLE" | FpDirNodeKind;
				/**
				 * show contacts that have a duties matching the current directory filter
				 */
				eventContacts?: boolean;
				workPatternColumn?: boolean;
			};
		};
		fpdiridMerge?: {
			filter: {
				/**
				 * only required if `parent` is 0
				 */
				kind?: FpDirNodeKind;

				fpdirloc?: number;
				fpdirgrp?: number;
				fpdirpos?: number;
				fpdirsec?: number;
			}
			/**
			 * only on locations
			 */
			resourceSetup?: boolean;
			/**
			 * only on locations
			 */
			aircraft?: boolean;
			/**
			 * Contacts that are members of this node
			 */
			contact?: {
				enabled?: boolean;
				groupBy?: "SETUP_ROLE" | FpDirNodeKind;
				/**
				 * show contacts that have a duties matching the current directory filter
				 */
				eventContacts?: boolean;
				workPatternColumn?: boolean;
			};
		};
		positionSetup?: {
			filter: {
				fpdirloc?: number;
				fpdirgrp?: number;
				fpdirpos?: number;
				fpdirsec?: number;
			};
			role: FpApi.Calendar.Event.EventResourceRole;
		};
	};

	children?: Array<CalendarConfigurationItem>;
}
