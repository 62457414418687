import { faCircle, faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { css } from "styled-components";
import { Form } from "../../Layout/Form/Form";
import { Button } from "../Button/Button";
import { Dropdown } from "../Dropdown/Dropdown";
import { Flex } from "../Flex";
export const useStackedInput = (props) => {
    const initialValue = props.fields.reduce((acc, curr) => {
        acc[curr.field] = props.value?.[curr.field] || "";
        return acc;
    }, {});
    const [value, setValue] = React.useState(initialValue);
    const [shownFields, setShownFields] = React.useState([props.fields[0].field]);
    const handleSetValue = (field, value) => {
        setValue(e => ({ ...e, [field]: value }));
    };
    React.useEffect(() => {
        props.setValue(value);
    }, [value]);
    React.useEffect(() => {
        for (const key in value) {
            if (!shownFields.includes(key)) {
                setValue(e => ({ ...e, [key]: "" }));
            }
        }
    }, [shownFields]);
    return [value, handleSetValue, shownFields, setShownFields];
};
export const StackedInput = (props) => {
    const [value, handleSetValue, shownFields, setShownFields] = useStackedInput(props);
    const fields = props.fields.filter(e => shownFields.includes(e.field));
    const ui = React.useMemo(() => {
        return React.createElement(React.Fragment, null,
            React.createElement("div", null, fields.map((field) => {
                return React.createElement(StyledField, { widthFactor: 5, extendLast: shownFields.length !== props.fields.length, size: fields.length, label: field.label, key: field.field },
                    React.createElement(Flex, null,
                        React.createElement(InnerField, { baseProps: props, field: field, handleSetValue: handleSetValue, value: value }),
                        (shownFields.length > 1) && React.createElement(Button, { style: { borderLeft: 0 }, onClick: () => setShownFields(shownFields.filter(e => e !== field.field)) },
                            React.createElement(FontAwesomeIcon, { icon: faTimes }))));
            })),
            shownFields.length !== props.fields.length && React.createElement("div", { style: { position: "relative", left: -14 } },
                React.createElement(Dropdown, { component: Div, title: React.createElement("span", { className: "fa-layers fa-fw", style: { backgroundColor: "white" } },
                        React.createElement(FontAwesomeIcon, { color: "#333", transform: "shrink-6", fixedWidth: true, icon: faPlus }),
                        React.createElement(FontAwesomeIcon, { color: "#ccc", fixedWidth: true, icon: faCircle })) }, props.fields.filter(e => !shownFields.includes(e.field)).map(field => {
                    return React.createElement(Dropdown.Item, { title: field.label, key: field.field, onClick: () => {
                            setShownFields([...shownFields, field.field]);
                        } });
                }))));
    }, [value, shownFields]);
    return ui;
};
const InnerField = (props) => {
    const ui = React.useMemo(() => {
        return React.createElement(React.Fragment, null, props.field.component ?
            React.createElement(props.field.component, { key: props.field.field, ...props.field.componentProps, value: props.value[props.field.field], onChange: (e) => props.handleSetValue(props.field.field, props.field.getDataValue(e)) }) :
            React.createElement(React.Fragment, null,
                React.createElement(props.baseProps.component, { ...props.baseProps.componentProps, onChange: e => props.handleSetValue(props.field.field, props.baseProps.getDataValue(e)), value: props.value[props.field.field] })));
    }, [props.field.field, props.value]);
    return ui;
};
const Input = (props) => {
    return React.createElement("input", { className: "form-control", ...props });
};
StackedInput.defaultProps = {
    component: Input,
    getDataValue: e => e.target.value,
};
const Div = styled.span ``;
const StyledField = styled(Form.Field) `
${props => {
    if (props.size === 1) {
        return css `
				${props.extendLast ? css `
				position: relative;
				&::before {
					content: '';
					position: absolute;
					/* border: 1px solid #ccc; */
					height: 0px;
					border-top: 1px solid #ccc;
					width: ${props.widthFactor}px;
					top: 75%;
					left: -${props.widthFactor}px;
				}
				&::after{
					content: '';
					position: absolute;
					/* border: 1px solid #ccc; */
					height: 0px;
					width: ${props.widthFactor}px;
					top: 75%;
					left: -${props.widthFactor}px;
					border-left: 1px solid #ccc;
					height: 50%;
				}
				` : ""}
				
				}`;
    }
    else {
        return css `
			position: relative;
			&:not(:first-child):not(:last-child) {
				::before {
					content: '';
					position: absolute;
					/* border: 1px solid #ccc; */
					height: 0px;
					border-top: 1px solid #ccc;
					width: ${props.widthFactor}px;
					top: 75%;
					left: -${props.widthFactor}px;
					border-left: 1px solid #ccc;
					height: 50%;
				}
				::after{
					content: '';
					position: absolute;
					/* border: 1px solid #ccc; */
					height: 0px;
					width: ${props.widthFactor}px;
					top: 25%;
					left: -${props.widthFactor}px;
					border-left: 1px solid #ccc;
					height: 50%;
				}
			}
			&:first-child {
				::before {
					content: '';
					position: absolute;
					/* border: 1px solid #ccc; */
					height: 0px;
					border-top: 1px solid #ccc;
					width: ${props.widthFactor}px;
					top: 75%;
					left: -${props.widthFactor}px;
					border-left: 1px solid #ccc;
					height: 50%;
				}
				::after{
					content: '';
					position: absolute;
					border: 1px solid #ccc;
				}
			}
			
			&:last-child {
				::before {
					content: '';
					position: absolute;
					/* border: 1px solid #ccc; */
					height: 0px;
					border-top: 1px solid #ccc;
					width: ${props.widthFactor}px;
					top: 75%;
					left: -${props.widthFactor}px;
					${() => {
            if (props.extendLast) {
                return css `
							border-left: 1px solid #ccc;
							height: 50%;
						`;
            }
        }}
				}
				::after{
					content: '';
					position: absolute;
					/* border: 1px solid #ccc; */
					height: 0px;
					width: ${props.widthFactor}px;
					top: 25%;
					left: -${props.widthFactor}px;
					border-left: 1px solid #ccc;
					height: 50%;
				}
			}
		`;
    }
}}

`;
