import { DataFieldConfig, Model, Store } from "@bryntum/core-thin";
import { ResourceTimeRangeModel, ResourceTimeRangeModelConfig, TimeRangeModel, TimeRangeModelConfig, } from "@bryntum/scheduler-thin";
import { EventModel, EventModelConfig, ResourceModel, ResourceModelConfig } from "@bryntum/schedulerpro-thin";
import { FPEvent, FpApi, ResolvedHoliday } from "@tcs-rliess/fp-core";

export type FpResourceModelConfig = ResourceModelConfig & { fpLinkType: string; fpLinkId: string | number; fpLinkIdExtra: string | number; fpLinkExtra: any; fpData: any; };
export type FpEventModelConfig = EventModelConfig & { fpLinkType: string; fpLinkId: string; fpData: any; };
export type FpTimeRangeModelConfig = TimeRangeModelConfig & { fpLinkType: string; fpLinkId: string | number; fpData: any; };
export type FpResourceTimeRangeModelConfig = ResourceTimeRangeModelConfig & { fpLinkType: string; fpLinkId: string | number; fpData: any; };

export class FpResourceModel<T = unknown> extends ResourceModel {
	declare public fpLinkType: string;
	declare public fpLinkId: string;
	declare public fpLinkIdExtra: string;
	declare public fpLinkExtra: any;
	declare public fpData: T;

	public static isFpResourceModel(model: EventModel | Model): model is FpResourceModel {
		return model instanceof FpResourceModel;
	}

	static get fields(): Partial<DataFieldConfig>[] {
		return [
			{ name: "fpLinkType" },
			{ name: "fpLinkId" },
			{ name: "fpLinkIdExtra" },
			{ name: "fpLinkExtra" },
			{ name: "fpData" },
			// { name: "calendar", defaultValue: null },
		];
	}

	constructor(config?: Partial<FpResourceModelConfig>, store?: Store, meta?: any) {
		super(config, store, meta);
	}
}

export class FpEventModel<T = unknown> extends EventModel {
	declare public fpLinkType: string;
	declare public fpLinkId: string;
	declare public fpData: T;

	public static isFpEvent(model: FpEventModel<any>): model is FpEventModel<FPEvent> {
		return model.fpLinkType === "dseid";
	}

	public static isSchedule(model: FpEventModel<any>): model is FpEventModel<FpApi.Resource.Duty.Schedule> {
		return [
			`dsrschid:${FpApi.Resource.Duty.ScheduleType.MedicalLeave}`,
			`dsrschid:${FpApi.Resource.Duty.ScheduleType.Vacation}`,
			`dsrschid:${FpApi.Resource.Duty.ScheduleType.Duty}`,
			`dsrschid:${FpApi.Resource.Duty.ScheduleType.Standby}`,
			`dsrschid:${FpApi.Resource.Duty.ScheduleType.LocalDay}`,
			`dsrschid:${FpApi.Resource.Duty.ScheduleType.Off}`,
			`dsrschid:${FpApi.Resource.Duty.ScheduleType.DutyWish}`,
			"dsrschid",
		].includes(model.fpLinkType);
	}

	/**
	 * this getter specifies the type of the event further. For example: dsrschid:DUTY
	 */
	public get fpType(): string {
		if(FpEventModel.isSchedule(this)) {
			const type = (this.fpData as FpApi.Resource.Duty.Schedule)?.type;
			if(!type) return "dsrschid";
			return `dsrschid:${type}`;
		} else if(FpEventModel.isFpEvent(this)) {
			const type = (this.fpData as FPEvent)?.type;
			if(!type) return "dseid";
			return `dseid:${(this as FpEventModel<FPEvent>).fpData.type}`;
		}
		return (this as FpEventModel<T>).fpLinkType;
	}

	public static isHoliday(model: FpEventModel<any>): model is FpEventModel<ResolvedHoliday> {
		return model.fpLinkType === "dschsid";
	}

	public static isFpEventModel(model: EventModel | Model): model is FpEventModel {
		return model instanceof FpEventModel && model.fpData != null;
	}


	static get fields(): Partial<DataFieldConfig>[] {
		return [
			{ name: "fpLinkType" },
			{ name: "fpLinkId" },
			{ name: "fpData" },
			// { name: "calendar", defaultValue: null },
		];
	}

	constructor(config?: Partial<FpEventModelConfig>) {
		super(config);
	}
}

export class FpTimeRangeModel<T = unknown> extends TimeRangeModel {
	declare public fpLinkType: string;
	declare public fpLinkId: string;
	declare public fpData: T;

	static get fields(): Partial<DataFieldConfig>[] {
		return [
			{ name: "fpLinkType" },
			{ name: "fpLinkId" },
			{ name: "fpData" },
			// { name: "calendar", defaultValue: null },
		];
	}

	constructor(config?: Partial<FpTimeRangeModelConfig>) {
		super(config);
	}
}

export class FpResourceTimeRangeModel<T = unknown> extends ResourceTimeRangeModel {
	declare public fpLinkType: string;
	declare public fpLinkId: string;
	declare public fpData: T;

	static get fields(): Partial<DataFieldConfig>[] {
		return [
			{ name: "fpLinkType" },
			{ name: "fpLinkId" },
			{ name: "fpData" },
			// { name: "calendar", defaultValue: null },
		];
	}

	constructor(config?: Partial<FpResourceTimeRangeModelConfig>) {
		super(config);
	}
}
