import { EventFlightInformation, EventGenericAction, EventUtil, FpApi, FPEvent, FPEventFilter, FPEventResource, PutStatusData, ResourceScheduleAction } from "@tcs-rliess/fp-core";
import { DateRange, DateRangePreset } from "@tcs-rliess/fp-web-ui";
import autobind from "autobind-decorator";
import EventEmitter from "events";
import { clone, cloneDeep, groupBy, isEqual, uniqBy } from "lodash-es";
import { DateTime } from "luxon";
import { action, autorun, computed, observable, reaction, transaction } from "mobx";
import moment from "moment";

import { FleetplanApp } from "../../../FleetplanApp";
import { EventStreamImpact } from "../../mqtt";

const DateRangePresets = {
	nowTillEndOfYear: {
		label: "Now +6 Months",
		getRange: (): DateRange => { return new DateRange(DateTime.now().startOf("day"), DateTime.now().plus({ months: 6 }).endOf("day")); }
	},
	thisYear: {
		label: "This Year",
		getRange: (): DateRange => { return new DateRange(DateTime.now().startOf("year"), DateTime.now().endOf("year")); }
	},
	lastYear: {
		label: "Last Year",
		getRange: (): DateRange => { return new DateRange(DateTime.now().minus({ year: 1 }).startOf("year"), DateTime.now().minus({ year: 1 }).endOf("year")); }
	},
};

// add years 2014 till now to the presets (database import/mig starts from 2014)
function extendPresets() {
	const minusTwoYears = DateTime.now().minus({ year: 2 }); // since we already have this year and last year
	for (let year = minusTwoYears.year; year >= 2014; year--) { // loop is backwards - so we have the years in right order in UI
		DateRangePresets[`y${year}`] = { // y is for the sorting problem -> without y 2014 will be on top
			label: year.toString(),
			getRange: (): DateRange => { return new DateRange(DateTime.local(year).startOf("year"), DateTime.local(year).endOf("year")); }
		};
	}
}

export class EventStore extends EventEmitter {
	private app: FleetplanApp;
	private load = new Map<string, Promise<FPEvent>>();

	@observable.ref public data = new Map<string, FPEvent>();
	@observable.ref public dataByYearMonth = new Map<string, FPEvent[]>();
	@observable.ref protected _templates = new Map<string, FPEvent>();
	@observable protected _repeatMasters = new Map<string, FPEvent>();
	@observable protected loadedYears = new Set<number>();
	// @observable protected loadedMonths = new Set<string>();

	// used to get all children of a certain container
	@observable.ref protected byParentId = new Map<string, FPEvent[]>();

	@observable protected _flightInformationViews = new Map<number, EventFlightInformation>();

	public eventDateRangePresetsAsArray: DateRangePreset[] = [];

	constructor(app: FleetplanApp) {
		super();
		this.app = app;

		extendPresets();
		this.eventDateRangePresetsAsArray = Array.from(Object.values(DateRangePresets));

		autorun(() => {
			if (app.flags.flightOpsNew) {
				void app.mqtt.subscribe("/event", this.mqttCb);

				if (this.app.ctx.isAuthorized) {
					void this.loadTemplates();
					// void this.loadYear(DateTime.now().year);
				} else {
					// wait until user logged in
					reaction(
						() => this.app.ctx.isAuthorized,
						async () => {
							if (!this.app.ctx.isAuthorized) return;
							await this.loadTemplates();
							//await this.loadYear(DateTime.now().year);
						}
					);
				}
			}
		});

		this.app.eventStream.subscribe({
			impact: EventStreamImpact.AllUsers,
			filter: { a: "media", s: "events_event" },
			callback: async (items) => {
				await this.eventStreamMediaCb(items);
			}
		});
	}

	@autobind
	private async mqttCb(...args): Promise<void> {
		const [ , data ] = args;

		const { action, data: events } = data;
		if (action === "update" || action == "put_status") {
			for (const event of events) {
				if (event.id.split("-").length === 0) return; // do not upsert containers, they cannot change and hold no important value
				await this.handleInsertEvent(event, true);
			}

			this.byParentId = new Map(this.byParentId); // remap because we don't remap in the handleInsertEvent above
			this.data = new Map(this.data); // remap because we don't remap in the handleInsertEvent above
		} else if (action === "delete") {
			for (const event of events) {
				const id = event.id;
				if (this.data.has(id)) {
					this.data.delete(id);
				}

				if (this.load.has(id)) {
					this.load.delete(id);
				}
				this.emit("delete", id);
			}
		} else {
			console.error("MQTT EventStore: unknown event ->", data);
		}
	}

	public async loadYear(year: number): Promise<void> {
		if (this.loadedYears.has(year)) {
			return;
		}

		this.loadedYears.add(year);

		await this.loadRange({
			from: DateTime.now().set({ year }).startOf("year").toUTC().toISO(),
			to: DateTime.now().set({ year }).endOf("year").toUTC().toISO()
		});
	}

	/*public async loadMonth(year: number, month: number): Promise<void> {
		const key = `${year}-${month}`;
		if (this.loadedMonths.has(key)) {
			return;
		}

		await this.loadRange({
			from: DateTime.now().toUTC().set({ year, month }).startOf("month").toISO(),
			to: DateTime.now().toUTC().set({ year, month }).endOf("month").toISO()
		});
		this.loadedMonths.add(key);
	}*/

	public async getRange(from: DateTime, to: DateTime) {
		// ensure correct years are loaded
		await this.app.store.event.loadYear(from.year);
		if (to.year !== from.year) await this.app.store.event.loadYear(to.year);

		// get all months (aswell as year) between from and to
		let current = from.startOf("month");
		const yearMonthKeys = [];
		while (current <= to.startOf("month") || current.year < to.year) {
			yearMonthKeys.push({ year: current.year, month: current.month });
			current = current.plus({ months: 1 });
		}

		let _data = [];
		for (const pair of yearMonthKeys) {
			_data.push(...(this.dataByYearMonth.get(`${pair.year}-${pair.month}`) ?? []));
		}

		_data = _data.filter(e => DateTime.fromISO(e.date_start) >= from && DateTime.fromISO(e.date_start) <= to);

		return _data;
	}

	/*public async getRange2(from: DateTime, to: DateTime) {
		// load the given range of events, get all unique year-month combinations and check against loadedMonths
		// if the month is not loaded yet, load it
		const months = new Set<string>();
		for (let i = from.month; i <= to.month; i++) {
			const key = `${from.year}-${i}`;
			if (this.loadedMonths.has(key)) continue;
			months.add(key);
		}

		const monthsArr = Array.from(months);
		if (monthsArr.length) {
			await this.loadRange({
				from: DateTime.now().toUTC().set({ year: +monthsArr[0].split("-")[0], month: +monthsArr[0].split("-")[1] }).startOf("month").toISO(),
				to: DateTime.now().toUTC().set({ year: +monthsArr[monthsArr.length - 1].split("-")[0], month: +monthsArr[monthsArr.length - 1].split("-")[1] }).endOf("month").toISO()
			});

			for (const month of monthsArr) {
				this.loadedMonths.add(month);
			}
		}

		const _data = [];
		for (let i = from.month; i <= to.month; i++) {
			const key = `${from.year}-${i}`;
			_data.push(...(this.dataByYearMonth.get(key) ?? []));
		}

		return Array.from(_data);
	}*/

	@action
	public async loadRange({ from, to }): Promise<Array<FPEvent>> {
		const events = await this.get({
			range: {
				from,
				to,
			}
		});

		// create a new map because this.data is a ref observable
		this.byParentId = new Map(this.byParentId);
		this.data = new Map(this.data);

		return events;
	}

	@action
	private async loadTemplates(): Promise<void> {
		await this.get({
			templates: true
		});

		// this._templates = new Map(templates.map(t => [ t.id, t ]));
	}

	@computed
	public get eventsByYear(): { [key: number]: FPEvent[] } {
		return groupBy(Array.from(this.data.values()).sort((a, b) => moment(a.date_start) > moment(b.date_start) ? 1 : -1), (e) => moment(e.date_start).year());
	}

	@computed
	public get templates(): FPEvent[] {
		return Array.from(this._templates.values());
	}

	@computed
	public get repeatMasters(): FPEvent[] {
		return Array.from(this._repeatMasters.values());
	}

	@computed
	public get flightEvents(): FPEvent[] {
		return Array.from(this.data.values()).filter(e => e.type === FpApi.Calendar.Event.EventType.Flight);
	}

	@computed
	public get flightInformation(): EventFlightInformation[] {
		return Array.from(this._flightInformationViews.values());
	}

	protected fetchIds(ids: string[]): Promise<FPEvent[]> {
		return this.get({ ids });
	}

	protected fetchId(id: string): Promise<FPEvent> {
		return new Promise<FPEvent | null>((resolve, reject) => {
			this.get({ ids: [ id ] }).then((ev) => resolve(ev.find(e => e.id === id))).catch(reject);
		});
	}

	public getIds(ids: string[]): Promise<FPEvent[]> {
		return new Promise<FPEvent[] | null>((resolve, reject) => {
			this.get({ ids }).then((ev) => resolve(ev.filter(e => ids.includes(e.id)))).catch(reject);
		});
	}

	@action
	public async flushId(id: string): Promise<void> {
		const ev = await this.getId(id);

		// delete (and reload) all subs if necessary
		if (ev?.type === FpApi.Calendar.Event.EventType.Container) {
			for (const event of Object.values(this.byParentId.get(id))) {
				this.load.delete(event.id);
				this.data.delete(event.id);
			}

			// delete all 'fake' events with this rule
			/*if (ev.rrule) {
				const events = Array.from(this.data.values()).filter(e => e.dseidRepeatParent === ev.id);
				const realEvents = events.filter(e => e.status !== FpApi.Calendar.Event.EventStatus.Repeat);
				for (const ev of events) {
					this.load.delete(ev.id);
					this.data.delete(ev.id);
				}

				if (realEvents.length) await this.loadIds(realEvents.map(e => e.id));
			}*/
		}

		this.load.delete(id);
		this.data.delete(id);
		this._repeatMasters.delete(id);

		await this.getId(id);
	}

	@action
	public async deleteId(id: string): Promise<void> {
		const oldEv = clone(this.data.get(id) ?? this._templates.get(id) ?? this._repeatMasters.get(id));

		await this.delete(id);

		this.load.delete(id);
		this.data.delete(id);

		this._templates.delete(id);
		this._repeatMasters.delete(id);

		// refresh container if event existed
		if (oldEv) {
			const parentId = this.getParentId(id);
			if (this.byParentId.has(parentId)) {
				this.byParentId.set(parentId, this.byParentId.get(parentId).filter(e => e.id !== id));
			}
		}

		// delete virtual / fake repeat events
		if (oldEv && oldEv.status === FpApi.Calendar.Event.EventStatus.RepeatMaster) {
			// get all events which had this event as master and are not in status repeat (status repeat = virtual)
			const events = Array.from(this.data.values()).filter(e => e.dseid_repeat_parent === oldEv.id && e.status === FpApi.Calendar.Event.EventStatus.Repeat);

			for (const fakeEv of events) {
				this.data.delete(fakeEv.id);
			}
		}

		this.byParentId = new Map(this.byParentId);
		this.data = new Map(this.data);
		this._templates = new Map(this._templates);
		this._repeatMasters = new Map(this._repeatMasters);

		this.emit("delete", id);
	}

	@action
	public async getId(id: string): Promise<FPEvent> {
		if (id == null) return undefined;

		if (this._repeatMasters.has(id)) {
			return this._repeatMasters.get(id);
		}

		if (this._templates.has(id)) {
			return this._templates.get(id);
		}

		if (this.data.has(id)) {
			await this.load.get(id);
			return this.data.get(id);
		} else {
			try {
				const loadEv = this.fetchId(id);
				this.load.set(id, loadEv);

				const item = await loadEv;

				if (item) await this.handleInsertEvent(item);

				return item;
			} catch (e) {
				this.load.delete(id);
				this.data.delete(id);
				throw e;
			}
		}
	}

	@action
	public async updateEventRole(event: FPEvent, role: FpApi.Calendar.Event.EventResourceRole, linkId: string): Promise<FPEvent> {
		const resource = event.resources.find(e => e.link_type === "dscaid" && e.dserrid === role);
		resource.link_id = linkId;

		const _event = await this.putEvent(event);

		return _event;
	}

	@action
	public async put(data: FPEvent): Promise<FPEvent> {
		const event = await this.putEvent(data);

		if (event.status === FpApi.Calendar.Event.EventStatus.RepeatMaster) {
			// await eventService.updateRepeatEvents(this.app.ctx, { data });
		}

		// rrule events are special because they have fake events
		if (event.rrule) {
			// await this.flushId(event.id);
		} else {
			// await this.handleInsertEvent(event);
		}

		return event;
	}

	@action
	public async putStatus(opts: PutStatusData): Promise<FPEvent> {
		const request = await fetch("/api/events/status", {
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(opts),
			method: "PUT"
		});
		const requestJson = await request.json();

		// save event to store
		for (const row of requestJson.rows) {
			await this.handleInsertEvent(row, true);
		}

		this.byParentId = new Map(this.byParentId);
		this.data = new Map(this.data);

		return requestJson.rows[0];
	}

	@action
	public async putCrew(opts: any): Promise<FPEvent> {
		const request = await fetch("/api/events/crew", {
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(opts),
			method: "PUT"
		});
		const requestJson = await request.json();

		// save event to store
		for (const row of requestJson.rows) {
			await this.handleInsertEvent(row, true);
		}

		this.byParentId = new Map(this.byParentId);
		this.data = new Map(this.data);

		return requestJson.rows[0];
	}

	@action
	public async releaseEvent(id: string): Promise<FPEvent> {
		const request = await fetch(`/api/events/release/${id}`, {
			headers: {
				"Content-Type": "application/json"
			},
			method: "PUT"
		});
		const requestJson = await request.json();

		// save event to store
		for (const row of requestJson.rows) {
			await this.handleInsertEvent(row, true);
		}

		this.byParentId = new Map(this.byParentId);
		this.data = new Map(this.data);

		return requestJson.rows[0];
	}

	/*@action
	public async loadRepeatMasters(): Promise<void> {
		const events = await this.get({
			repeats: {
				date: DateTime.now().toUTC().minus({ year: 1 }).toISO()
			}
		}, true);

		for (const event of events) {
			await this.handleInsertEvent(event, true);
		}

		this._repeatMasters = new Map(this._repeatMasters);
	}*/

	@action
	public async handleInsertEvent(event: FPEvent, noRemap = false): Promise<void> {
		// if item is container, re-set all subs
		if (event.type === FpApi.Calendar.Event.EventType.Container) {
			/*for (const subEvent of eventsFlattened) {
				if (subEvent.status === FpApi.Calendar.Event.EventStatus.RepeatMaster) {
					this._repeatMasters.set(subEvent.id, subEvent);
				} else {
					this.data.set(subEvent.id, subEvent);
				}
			}*/

			/*if (event.type === FpApi.Calendar.Event.EventType.Container) {
				const bookingItemIds = event.resources.filter(r => r.linkType === "dsbiid").map(r => r.linkId);
				await this.app.store.bookingItem.get({ id: bookingItemIds });
			}*/
		} else {
			const [ parentId ] = event.id.split("-");
			if (!this.byParentId.has(parentId)) {
				this.byParentId.set(parentId, [ event ]);
			} else {
				this.byParentId.set(parentId, uniqBy([ event, ...this.byParentId.get(parentId) ], e => e.id));
			}
		}

		if (event.status === FpApi.Calendar.Event.EventStatus.RepeatMaster) {
			// create fake repeat events
			if (event.type !== FpApi.Calendar.Event.EventType.Container) {
				// cleanup old events
				const events = Array.from(this.data.values()).filter(e => e.dseid_repeat_parent === event.id);

				// only keep real events
				event.repeat_exceptions = events.filter(e => e.status !== FpApi.Calendar.Event.EventStatus.Repeat).map(e => e.date_start);

				// delete all 'fake' events with this rule, they will be re-created at the bottom
				const oldFakeEvs = events.filter(e => e.status === FpApi.Calendar.Event.EventStatus.Repeat);
				for (const oldFakeEv of oldFakeEvs) {
					this.data.delete(oldFakeEv.id);
				}

				// create new 'fake' repeat events
				const repeats = EventUtil.mapRepeatEvents(event, event.tz ?? this.app.formatter.options.tz);
				const repeatEvents = [ ...repeats, /*...EventUtil.flattenEvents(repeats)*/ ];
				for (const repeat of repeatEvents) {
					if (!isEqual(this.data.get(repeat.id), repeat)) {
						this.dataByYearMonth.set(moment(repeat.date_start).format("YYYY-M"), [ ...(this.dataByYearMonth.get(moment(repeat.date_start).format("YYYY-M")) ?? []).filter(r => r.id !== repeat.id), repeat ]);
						this.data.set(repeat.id, repeat);
						this.emit("put", repeat);
					}
				}
			}

			this._repeatMasters.set(event.id, event);
		} else {
			if (event.is_template) {
				if (!isEqual(this._templates.get(event.id), event)) this._templates.set(event.id, event);
			} else {
				if (!isEqual(this.data.get(event.id), event)) {
					this.dataByYearMonth.set(moment(event.date_start).format("YYYY-M"), [ ...(this.dataByYearMonth.get(moment(event.date_start).format("YYYY-M")) ?? []).filter(e => e.id !== event.id), event ]);
					this.data.set(event.id, event);

					// load container if it isnt loaded yet
					if (event.type !== FpApi.Calendar.Event.EventType.Container && this.getParent(this.getParentId(event.id)) == null) {
						await this.get({ ids: [ this.getParentId(event.id) ] });
					}

					this.emit("put", event);
				}
			}
		}

		if (!noRemap) {
			this.byParentId = new Map(this.byParentId);
			this._templates = new Map(this._templates);
			this.data = new Map(this.data);
		}
	}

	@action
	public deleteFakeRepeat(id: string) {
		this.data.delete(id);
		this.data = new Map(this.data);

		this.emit("delete", id);
	}

	private async get(params: FPEventFilter, ignoreStore?: boolean): Promise<FPEvent[]> {
		const request = await fetch("/api/events/get", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ filter: params })
		});
		const requestJson = await request.json();

		// save event to store
		if (!ignoreStore) {
			// rrule events last
			requestJson.rows.sort((a: FPEvent, b) => {
				// containers need to be first, then every other type
				if (a.type === FpApi.Calendar.Event.EventType.Container) return -1;
				if (b.type === FpApi.Calendar.Event.EventType.Container) return 1;

				return a.rrule && !b.rrule ? 1 : -1;
			});

			for (const row of cloneDeep(requestJson.rows)) {
				await this.handleInsertEvent(row, true);
			}
		}

		return requestJson.rows;
	}

	public async putEvent(event: Partial<FPEvent>, ignoreStore?: boolean): Promise<FPEvent> {
		const request = await fetch(event.id ? `/api/events/${event.id}` : "/api/events/", {
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(event),
			method: "PUT"
		});
		const requestJson = await request.json();

		// save event to store
		if (!ignoreStore) {
			// containers need to be inserted first
			const rows = requestJson.rows.sort((a, b) => {
				if (a.type === FpApi.Calendar.Event.EventType.Container) return -1;
				else return 1;
			});
			for (const row of rows) {
				await this.handleInsertEvent(row, true);
			}
		}

		return requestJson.rows.sort((a, b) => a.date_modified - b.date_modified).find(e => ![ "CONTAINER" ].includes(e.type));
	}

	public async putResource(data: { event_id: string, resource: FPEventResource }): Promise<FPEventResource> {
		const request = await fetch("/api/events/resource", {
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
			method: "PUT"
		});

		const requestJson = await request.json();
		return requestJson.rows.find(e => e.dsefwpid_from === data.resource.dsefwpid_from &&  e.dsefwpid_to === data.resource.dsefwpid_to && e.link_id === data.resource.link_id && e.link_type === data.resource.link_type && e.dserrid === data.resource.dserrid);
	}

	private async delete(event_id: string): Promise<FPEvent> {
		const request = await fetch(`/api/events/${event_id}`, {
			method: "DELETE"
		});

		const requestJson = await request.json();
		return requestJson.rows[0];
	}

	public getParent(eventId: string): FPEvent | null {
		const pid = this.getParentId(eventId);

		if (this._repeatMasters.has(pid)) return this._repeatMasters.get(pid);
		if (this._templates.has(pid)) return this._templates.get(pid);

		return this.data.get(pid);
	}

	public getParentId(eventId: string): string {
		if (typeof eventId !== "string") return null;

		const [ parentId ] = eventId.split("-");
		return parentId;
	}

	public getChildren(parentId: string): FPEvent[] {
		return cloneDeep(this.byParentId.get(parentId))?.sort((a, b) => {
			// always prioritize order events
			if (a.type === FpApi.Calendar.Event.EventType.Order && b.type !== FpApi.Calendar.Event.EventType.Order) return -1;
			if (a.type !== FpApi.Calendar.Event.EventType.Order && b.type == FpApi.Calendar.Event.EventType.Order) return 1;

			return moment(a.date_start) > moment(b.date_start) ? 1 : -1;
		}) ?? [];
	}

	public async getByLink(link_id: string, link_type: string): Promise<FPEvent[]> {
		const events = await this.get({ link: { link_id, link_type }});
		events.sort((a, b) => new Date(a.date_start) < new Date(b.date_start) ? -1 : 1);
		return events;
	}

	public async getByLinks(links: FPEventFilter["links"]): Promise<FPEvent[]> {
		return await this.get({ links });
	}

	public async loadFlightInformation(): Promise<void> {
		const request = await fetch("/api/events/dashboard", {
			method: "GET"
		});

		const requestJson = await request.json();
		this._flightInformationViews = new Map(requestJson.rows.map(r => [ r.id, r ]));
	}

	public async getFlightInformationById(id: number): Promise<EventFlightInformation> {
		// if there are no dashboards, assume they're not loaded yet
		if (!this._flightInformationViews.has(id)) {
			await this.loadFlightInformation();
		}

		return this._flightInformationViews.get(id);
	}

	public async upsertFlightInformation(data: EventFlightInformation): Promise<EventFlightInformation> {
		const request = await fetch("/api/events/dashboard", {
			method: "PUT",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		});

		const json = await request.json();
		if (request.status === 200) {
			const dashboard = json.rows[0];
			this._flightInformationViews.set(dashboard.id, dashboard);

			return dashboard;
		} else {
			throw json;
		}
	}

	public async deleteFlightInformation(id: number): Promise<void> {
		const request = await fetch(`/api/events/dashboard/${id}`, {
			method: "DELETE"
		});

		if (request.status === 200) {
			this._flightInformationViews.delete(id);
		} else {
			throw await request.json();
		}
	}

	public isAllowed(action: "edit" | "add" | "delete" | "release" | "assign_crew" | "manage_load" | "add_leg", params?: { id?: string; addType?: FpApi.Calendar.Event.EventType }): boolean {
		if (action === "add") {
			const { addType } = params;

			// 2024-11-14 - [AP, DL] add check for training events
			if (addType === FpApi.Calendar.Event.EventType.Training) {
				if (!this.app.store.settingsProject.getBoolean("event.training.enabled")) return false;
				return this.app.ctx.hasRole([ "training_manager", "dscertificatemanager" ]);
				// 2024-12-08 [AP/ET] removed based on AP, doesn't make sense, it's meant to be training or certificate manager
				//  || this.app.ctx.hasTypePermission("dsrschid", ResourceScheduleAction.Manager);
			}

			if (addType === FpApi.Calendar.Event.EventType.Flight) return this.app.store.settingsProject.getBoolean("event.flight.enabled") && (this.app.ctx.hasTypePermission("fpvid.aircraft", FpApi.Security.PermissionAircraftLvl.ScheduleEditor) || this.app.ctx.hasTypePermission("dscalid.aircraft", FpApi.Security.PermissionCalendarLvl.EventEditor));
			if (addType === FpApi.Calendar.Event.EventType.Order) return this.app.store.settingsProject.getBoolean("event.order.enabled") && this.app.ctx.hasTypePermission("fpvid.aircraft", FpApi.Security.PermissionAircraftLvl.OrderEditor);
			if (addType === FpApi.Calendar.Event.EventType.Maintenance) return this.app.store.settingsProject.getBoolean("event.maintenance.enabled") && this.app.ctx.hasTypePermission("fpvid.aircraft", FpApi.Security.PermissionAircraftLvl.MaintenanceEditor);

			// 2024-12-04 - [PR, AP, RW] Permission needs to be added!
			if (addType === FpApi.Calendar.Event.EventType.General) {
				if (!this.app.store.settingsProject.getBoolean("event.general.enabled")) return false;
				return this.app.store.settingsProject.getBoolean("event.general.enabled");
			}

			if (addType === FpApi.Calendar.Event.EventType.Generic) {
				if (!this.app.store.settingsProject.getBoolean("event.generic.enabled")) return false;
				return this.app.ctx.hasPermission("dseid.generic", null, EventGenericAction.Editor);
			}

			// fallback
			return this.app.ctx.isSuperUser || this.app.ctx.hasProjectPermission(FpApi.Security.ProjectPermission.GlobalManager);
		} else {
			const ev = this.data.get(params?.id) ?? this._templates.get(params?.id) ?? this._repeatMasters.get(params?.id);
			if (!ev) return false;

			// repeats cannot be edited!
			if (ev.status === FpApi.Calendar.Event.EventStatus.Repeat) return false;

			// 2024-11-14 - [AP, DL] add check for training events
			if (ev.type === FpApi.Calendar.Event.EventType.Training) {
				return this.app.ctx.hasRole("training_manager") || this.app.ctx.hasRole("dscertificatemanager") || this.app.ctx.hasTypePermission("dsrschid", ResourceScheduleAction.Manager);
			}

			// 2024-12-04 - [PR, AP, RW] Permission needs to be added!
			if (ev.type === FpApi.Calendar.Event.EventType.General) return this.app.store.settingsProject.getBoolean("event.general.enabled");

			if (ev.type === FpApi.Calendar.Event.EventType.Generic) {
				if (action === "delete") return this.app.ctx.hasPermission("dseid.generic", ev.id, EventGenericAction.Delete);
				else return this.app.ctx.hasPermission("dseid.generic", ev.id, EventGenericAction.Editor);
			}

			// 2024-11-21 - [DL] aircraft related events
			const ac = ev.resources?.find(r => r.dserrid === FpApi.Calendar.Event.EventResourceRole.Aircraft);
			const dscalid = ev.resources?.find(r => r.dserrid === FpApi.Calendar.Event.EventResourceRole.CalendarAircraft);
			if (action === "edit") {
				if (ac) {
					if (ev.type === FpApi.Calendar.Event.EventType.Flight) {
						// if user is part of this event, he is allowed to edit
						if (ev.resources.find(e => e.link_type === FpApi.Calendar.Event.ResourceLinkType.dscaid && +e.link_id === this.app.ctx.dscaid)) {
							return this.app.ctx.hasPermission("fpvid.aircraft", ac.link_id, FpApi.Security.PermissionAircraftLvl.ScheduleOwnEditor);
						}

						return this.app.ctx.hasPermission("fpvid.aircraft", ac.link_id, FpApi.Security.PermissionAircraftLvl.ScheduleEditor);
					} else if (ev.type === FpApi.Calendar.Event.EventType.Order) {
						return this.app.ctx.hasPermission("fpvid.aircraft", ac.link_id, FpApi.Security.PermissionAircraftLvl.OrderEditor);
					} else if (ev.type === FpApi.Calendar.Event.EventType.Maintenance) {
						return this.app.ctx.hasPermission("fpvid.aircraft", ac.link_id, FpApi.Security.PermissionAircraftLvl.MaintenanceEditor);
					}
				} else if (dscalid) {
					return this.app.ctx.hasPermission("dscalid.aircraft", dscalid.link_id, FpApi.Security.PermissionCalendarLvl.EventEditor);
				}
			} else if (action === "release") {
				if (!ac) return false; // cannot release events with no aircraft
				// no ac => cannot release
				return this.app.ctx.hasPermission("fpvid.aircraft", ac?.link_id, FpApi.Security.PermissionAircraftLvl.ScheduleRelease);
			} else if (action === "assign_crew") {
				if (ac) return this.app.ctx.hasPermission("fpvid.aircraft", ac?.link_id, FpApi.Security.PermissionAircraftLvl.ScheduleAssignCrew);
				if (dscalid) return this.app.ctx.hasPermission("dscalid.aircraft", dscalid?.link_id, FpApi.Security.PermissionCalendarLvl.EventEditor);
			} else if (action === "delete") {
				if (ac) { return this.app.ctx.hasPermission("fpvid.aircraft", ac?.link_id, FpApi.Security.PermissionAircraftLvl.ScheduleDelete); }
				if (dscalid) { return this.app.ctx.hasPermission("dscalid.aircraft", dscalid?.link_id, FpApi.Security.PermissionCalendarLvl.EventEditor); }
			} else if (action === "manage_load") {
				if (dscalid) { return this.app.ctx.hasPermission("dscalid.aircraft", dscalid?.link_id, FpApi.Security.PermissionCalendarLvl.EventEditor); }
				if (ac) return this.app.ctx.hasPermission("fpvid.aircraft", ac?.link_id, FpApi.Security.PermissionAircraftLvl.ScheduleLoad);

				return false;
			} else if (action === "add_leg") {
				if (!ac) return false;

				// if user is part of this event, he is allowed
				if (ev.resources.find(e => e.link_type === FpApi.Calendar.Event.ResourceLinkType.dscaid && +e.link_id === this.app.ctx.dscaid)) {
					return this.app.ctx.hasPermission("fpvid.aircraft", ac.link_id, FpApi.Security.PermissionAircraftLvl.LegOwnEditor);
				}

				// must be leg editor to add legs
				return this.app.ctx.hasPermission("fpvid.aircraft", ac?.link_id, FpApi.Security.PermissionAircraftLvl.LegEditor);
			}

			// fallback
			return this.app.ctx.isSuperUser || this.app.ctx.hasProjectPermission(FpApi.Security.ProjectPermission.GlobalManager);
		}
	}

	public async uploadFiles(eventId: string, files: any[]): Promise<void> {
		let uploadedFiles = 0;
		const eventStreamSubcription = this.app.eventStream.subscribe({
			impact: EventStreamImpact.OneUserActivelyWaiting,
			filter: { a: "media", s: "events_event", t: "upload" },
			callback: async (items) => {
				uploadedFiles += items.length;
				await this.eventStreamMediaCb(items);
				if (uploadedFiles === files.length) {
					this.app.eventStream.unsubscribe(eventStreamSubcription);
				}
			}
		});

		const signaturesResponse = await fetch("/api/events/files/signature", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				id: eventId,
				files: Array.from(files).map((f: any) => ({
					name: f.name,
					size: f.size,
					type: f.type
				}))
			})
		});

		const signatures = await signaturesResponse.json();

		for (const file of files) {
			const signature = signatures.find(e => e.filename === file.name);
			await fetch(signature.signed.url, { method: "PUT", body: file, headers: signature.signed.headers });
		}
	}

	public async deleteFile(eventId: string, fileKey: string): Promise<void> {
		const eventStreamSubcription = this.app.eventStream.subscribe({
			impact: EventStreamImpact.OneUserActivelyWaiting,
			filter: { a: "media", s: "events_event", t: "delete" },
			callback: async (items) => {
				await this.eventStreamMediaCb(items);
				this.app.eventStream.unsubscribe(eventStreamSubcription);
			}
		});

		const response = await fetch("/api/events/files/delete", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				id: eventId,
				key: fileKey
			})
		});

		await response.json(); // "validate" that we got a json response
	}

	private async eventStreamMediaCb(items) {
		const idList: string[] = [];

		for (const item of items) {
			if ([ "delete", "upload" ].includes(item.t)) {
				idList.push(item.mt);
			} else if (item.t === "upload_error") {
				alert("Upload failed");
			} else {
				console.warn("MQTT EventStore: unknown event ->", item);
			}
		}

		if (idList.length) {
			// refresh event in store
			await this.get({ ids: idList });

			transaction(() => {
				// the get above doesnt trigger re-renders
				this.data = new Map(this.data);
				this._templates = new Map(this._templates);
				this._repeatMasters = new Map(this._repeatMasters);
			});
		}
	}

	/**
	 * This method is used for @vt's new Grid to display Flights with attached Booking Items
	 * @param from ISO String
	 * @param to ISO String
	 * @returns all Flight Events with atleast one Booking Item as resource
	 */
	public async getRangeWithBookingItems(from: string, to: string): Promise<FPEvent[]> {
		const events = await this.get({
			range: {
				from,
				to,
			}
		});

		return events.filter(e => e.type === FpApi.Calendar.Event.EventType.Flight && e.resources.some(r => [ FpApi.Calendar.Event.EventResourceRole.BookingItemPax, FpApi.Calendar.Event.EventResourceRole.BookingItemCargo ].includes(r.dserrid)));
	}
}
