import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toNumber } from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Format, Label, Button } from "../../../Components";
import { Flex } from "../../../Components/Flex";
const StyledSlider = styled.input `
-webkit-appearance: none;
width: 100%;
height: 19px;
background: #d3d3d3;
outline: none;
opacity: 0.7;
-webkit-transition: .2s;
transition: opacity .2s;

&:hover {
	opacity: 1;
}

&::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 10px;
	height: 20px;
	background: ${p => p.theme.color.primary["500"].main};
	cursor: pointer;
}

&::-moz-range-thumb {
	width: 10px;
	height: 19px;
	background: ${p => p.theme.color.primary["500"].main};
	cursor: pointer;
}
`;
const AutoBorderWrapper = styled(Flex) `
	border-right: none;
	&:first-child {
		border-left: none;
	}
`;
export const DateSlider = observer(function DateSlider(props) {
    const [sliderValue, setSliderValue] = React.useState(props.date?.diff(props.date?.startOf("month")).as("minutes"));
    const [validationDate, setValidationDate] = React.useState(props.date);
    const max = React.useMemo(() => {
        return props.date.endOf("month").diff(props.date.startOf("month")).as("minutes");
    }, [validationDate]);
    React.useEffect(() => {
        setValidationDate(props.date);
        setSliderValue(props.date?.diff(props.date?.startOf("month")).as("minutes"));
    }, [props.date]);
    const [year, setYear] = React.useState(validationDate?.year);
    React.useEffect(() => {
        setYear(validationDate?.year);
    }, [validationDate]);
    const left = React.useMemo(() => {
        // calculate percentage of slider
        const newValue = Number((sliderValue - 1) * 100 / (max - 1)), newPosition = 10 - (newValue * 0.2);
        if (newValue >= 90)
            return `calc(${newValue}% + (${newPosition}px) - 10%)`;
        return `calc(${newValue}% + (${newPosition}px))`;
    }, [sliderValue]);
    return (React.createElement(Flex, { alignItems: "center", row: true, style: { marginLeft: "1.5rem", marginRight: "1.5rem", width: 437 } },
        React.createElement(Button, { onClick: () => {
                if (props.hideSlider) {
                    const v = props.date?.set({ year, month: props.date.month - 1 });
                    setValidationDate(v);
                    props.onChange(v);
                }
                else {
                    setYear(e => e - 1);
                }
            }, size: "xs", style: { height: 39 } },
            React.createElement(FontAwesomeIcon, { icon: faChevronLeft })),
        React.createElement(Flex, { column: true, style: { width: "100%", position: "relative" }, alignItems: "center" },
            props.hideSlider &&
                React.createElement(Flex, null,
                    React.createElement(Button, { size: "xs", style: { height: 20 }, onClick: () => setYear(e => e - 1) },
                        React.createElement(FontAwesomeIcon, { icon: faChevronLeft })),
                    React.createElement(Label, { variant: "primary", style: { height: 20, textAlign: "center" }, fixedWidth: "x3" }, year),
                    React.createElement(Button, { size: "xs", style: { height: 20 }, onClick: () => setYear(e => e + 1) },
                        React.createElement(FontAwesomeIcon, { icon: faChevronRight }))),
            React.createElement(AutoBorderWrapper, { style: { width: "100%", height: props.hideSlider ? 30 : 20 } },
                moment.monthsShort().slice(0, 6).map((month, i) => {
                    return React.createElement(Button, { size: "xs", variant: ((year === validationDate.year) && (i + 1) === validationDate.month) ? "primary" : null, key: month, style: { height: props.hideSlider ? 30 : 20, width: "100%", textTransform: "uppercase", lineHeight: props.hideSlider ? "30px" : "20px", borderLeft: "none", borderBottom: "none" }, onClick: () => {
                            const v = props.date?.set({ year, month: i + 1 });
                            setValidationDate(v);
                            props.onChange(v);
                        } }, month);
                }),
                !props.hideSlider && React.createElement(Label, { variant: "primary" }, year),
                moment.monthsShort().slice(6).map((month, i) => {
                    return React.createElement(Button, { size: "xs", variant: ((year === validationDate.year) && (i + 7) === validationDate.month) ? "primary" : null, key: month, style: { height: props.hideSlider ? 30 : 20, width: "100%", textTransform: "uppercase", lineHeight: props.hideSlider ? "30px" : "20px", borderLeft: "none", borderBottom: "none" }, onClick: () => {
                            const v = props.date?.set({ year, month: i + 7 });
                            setValidationDate(v);
                            props.onChange(v);
                        } }, month);
                })),
            !props.hideSlider && React.createElement(React.Fragment, null,
                React.createElement(StyledSlider, { style: { visibility: year === validationDate.year ? undefined : "hidden" }, onChange: e => {
                        setSliderValue(toNumber(e.target.value));
                        const v = props.date?.startOf("month").plus({ minutes: toNumber(e.target.value) });
                        setValidationDate(v);
                        props.onChange(v);
                    }, type: "range", min: 0, value: sliderValue, max: max }),
                React.createElement("span", { style: { visibility: year === validationDate.year ? undefined : "hidden", position: "absolute", left: left, pointerEvents: "none", color: "white", "background": "black", bottom: "3px", marginLeft: "20px", fontSize: "10px", paddingInline: 4 } },
                    validationDate.weekdayShort.slice(0, 2).toUpperCase(),
                    "\u00A0/\u00A0",
                    `${validationDate.day}`.padStart(2, "0"),
                    "\u00A0/\u00A0",
                    React.createElement(Format.Time, null, validationDate.toJSDate())))),
        React.createElement(Button, { size: "xs", style: { height: 39, }, onClick: () => {
                if (props.hideSlider) {
                    const v = props.date?.set({ year, month: props.date.month + 1 });
                    setValidationDate(v);
                    props.onChange(v);
                }
                else {
                    setYear(e => e + 1);
                }
            } },
            React.createElement(FontAwesomeIcon, { icon: faChevronRight }))));
});
