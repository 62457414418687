import React from "react";
import styled from "styled-components";
import { Flex, FlexItem, Button } from "../";
import { Form } from "../../";
import { useDialogProps } from "./Dialog";
export const DialogFooter = styled.div `
	border-top: 1px solid #ddd;
	background: ${p => p.theme.background};
	position: sticky;
    bottom: 0;
	padding: 12px 18px;
	width: 100%;
`;
export const DialogFooterOk = function DialogFooterOk(props) {
    return (React.createElement(DialogFooter, null,
        React.createElement(Button, { disabled: props.okDisabled, variant: "default", onClick: props.onOk }, "OK")));
};
export const DialogFooterReportClose = function DialogFooterReportClose(props) {
    const dialog = useDialogProps();
    const onClose = props.onClose || dialog.onHide;
    return (React.createElement(DialogFooter, null,
        React.createElement(Flex, null,
            props.children,
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, null,
                React.createElement(Button, { disabled: props.closeDisabled, variant: "link", onClick: onClose }, "Close"),
                React.createElement(Button, { disabled: props.reportDisabled, variant: "primary", onClick: props.onReport }, "Report")))));
};
export const DialogFooterSaveClose = function DialogFooterSaveClose(props) {
    const dialog = useDialogProps();
    const onClose = props.onClose || dialog.onHide;
    return (React.createElement(DialogFooter, null,
        React.createElement(Flex, null,
            props.children,
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, null,
                React.createElement(Button, { disabled: props.closeDisabled, variant: "link", onClick: onClose }, "Close"),
                React.createElement(Button, { disabled: props.saveDisabled, variant: "primary", onClick: props.onSave }, props.saveActivity
                    ? "Saving..."
                    : "Save")))));
};
export const DialogFooterSaveCancel = function DialogFooterSaveCancel(props) {
    const dialog = useDialogProps();
    const onCancel = props.onCancel || dialog.onHide;
    return (React.createElement(DialogFooter, null,
        React.createElement(Flex, null,
            props.children,
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, { style: props.style },
                React.createElement(Button, { disabled: props.cancelDisabled, variant: "link", onClick: onCancel }, "Cancel"),
                React.createElement(Button, { disabled: props.saveDisabled, variant: "primary", onClick: props.onSave }, props.saveActivity
                    ? "Saving..."
                    : "Save")))));
};
export const DialogFooterApplyCancel = function DialogFooterApplyCancel(props) {
    const dialog = useDialogProps();
    const onCancel = props.onCancel || dialog.onHide;
    return (React.createElement(DialogFooter, null,
        React.createElement(Flex, null,
            props.children,
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, null,
                React.createElement(Button, { disabled: props.cancelDisabled ? true : props.applyActivity, variant: "link", onClick: onCancel }, "Cancel"),
                React.createElement(Button, { disabled: props.applyDisabled ? true : props.applyActivity, variant: "primary", onClick: props.onApply }, props.applyActivity
                    ? "Applying..."
                    : "Apply")))));
};
export const DialogFooterCancelClose = function DialogFooterCancelClose(props) {
    const dialog = useDialogProps();
    const onClose = props.onClose || dialog.onHide;
    return (React.createElement(DialogFooter, null,
        React.createElement(Flex, null,
            props.children,
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, null,
                React.createElement(Button, { disabled: props.cancelDisabled ? true : props.applyActivity, variant: "link", onClick: onClose }, "Close"),
                React.createElement(Button, { disabled: props.applyDisabled ? true : props.applyActivity, variant: "danger", onClick: props.onCancel }, props.applyActivity
                    ? "Canceling..."
                    : "Cancel")))));
};
export const DialogFooterSaveCancelConfirm = function DialogFooterSaveCancelConfirm(props) {
    const dialog = useDialogProps();
    const onCancel = props.onCancel || dialog.onHide;
    return (React.createElement(DialogFooter, null,
        React.createElement(Flex, null,
            props.children,
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, null,
                React.createElement(Form.CancelSave, { confirm: true, isDirty: true, useCancelConfirm: props.cancelConfirm, confirmMessage: props.submitText, cancelLabel: "Cancel", saveLabel: "Submit", cancelType: "button", saveType: "button", cancelDisabled: props.cancelDisabled, onCancel: () => {
                        onCancel(null);
                    }, onSubmit: () => props.onSave(null), saveDisabled: props.saveDisabled })))));
};
export const DialogFooterClose = function DialogFooterClose(props) {
    const dialog = useDialogProps();
    const onClose = props.onClose || dialog.onHide;
    return (React.createElement(DialogFooter, null,
        React.createElement(Flex, null,
            props.children,
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, null,
                React.createElement(Button, { disabled: props.disabled, variant: "link", onClick: onClose }, "Close")))));
};
export const DialogFooterDeleteCancel = function DialogFooterDeleteCancel(props) {
    const dialog = useDialogProps();
    const onCancel = props.onCancel || dialog.onHide;
    return (React.createElement(DialogFooter, null,
        React.createElement(Flex, null,
            props.children,
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, null,
                React.createElement(Button, { disabled: props.cancelDisabled ? true : props.deleteActivity, variant: "link", onClick: onCancel }, "Cancel"),
                React.createElement(Button, { disabled: props.deleteDisabled ? true : props.deleteActivity, variant: "danger", onClick: props.onDelete }, props.deleteActivity
                    ? "Deleting..."
                    : "Delete")))));
};
export const DialogFooterRevokeCancel = function DialogFooterRevokeCancel(props) {
    const dialog = useDialogProps();
    const onCancel = props.onCancel || dialog.onHide;
    return (React.createElement(DialogFooter, null,
        React.createElement(Flex, null,
            props.children,
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, null,
                React.createElement(Button, { disabled: props.cancelDisabled ? true : props.revokeActivity, variant: "link", onClick: onCancel }, "Cancel"),
                React.createElement(Button, { disabled: props.revokeDisabled ? true : props.revokeActivity, variant: "danger", onClick: props.onRevoke }, props.revokeActivity
                    ? "Revoking..."
                    : "Revoke")))));
};
export const DialogFooterRemoveCancel = function DialogFooterRemoveCancel(props) {
    const dialog = useDialogProps();
    const onCancel = props.onCancel || dialog.onHide;
    return (React.createElement(DialogFooter, null,
        React.createElement(Flex, null,
            props.children,
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, null,
                React.createElement(Button, { disabled: props.cancelDisabled ? true : props.removeActivity, variant: "link", onClick: onCancel }, "Cancel"),
                React.createElement(Button, { disabled: props.removeDisabled ? true : props.removeActivity, variant: "danger", onClick: props.onRemove }, props.removeActivity
                    ? "Removing..."
                    : "Remove")))));
};
