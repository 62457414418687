import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React from "react";
import styled from "styled-components";
const ToggleButtonWrapper = function ToggleButtonWrapper({ offstyle, onstyle, width, height, size, disabled, checked, onChange, className, offlabel = "Off", onlabel = "On", }) {
    return React.createElement("div", { className: className },
        React.createElement(BootstrapSwitchButton, { offstyle: offstyle, onstyle: onstyle, width: width, height: height, size: size, disabled: disabled, checked: checked, onChange: onChange, offlabel: React.createElement("div", { className: "offlabel" }, offlabel), onlabel: React.createElement("div", { className: "onlabel" }, onlabel) }));
};
export const ToggleButton = styled(ToggleButtonWrapper) `
	.btn-notaudited {
		background-color: #777;
		color: white;
	}
	.switch.btn-notaudited .switch-handle {
		border-color: #777;
	}

	.offlabel {
		text-align: left;
		margin-left: 10px;
	}
	.onlabel {
		text-align: left;
		margin-left: 10px;
	}

	.btn {
		text-transform: uppercase;
	}
`;
