import React from "react";
import { OverlayTrigger } from "../OverlayTrigger/OverlayTrigger";
import { Text } from "../Text";
import { Tooltip } from "../Tooltip/Tooltip";
import { merge } from "lodash";
export const Hint = function Hint(props) {
    const overlayTriggerProps = merge({}, {
        trigger: {
            type: "hover",
            triggerTime: 200,
        }
    }, props.overlayTriggerProps ?? {});
    const tooltipProps = merge({}, {
        variant: "primary",
    }, props.tooltipProps ?? {});
    return React.createElement(OverlayTrigger, { ...overlayTriggerProps, avoidElement: true, overlay: React.createElement(Tooltip, { ...tooltipProps }, props.hint) },
        React.createElement(Text, { style: { cursor: "help", ...(props.style ?? {}) }, size: props.size, underline: true }, props.children));
};
