import React from "react";
import { UiContext } from "../../FpWebUiProvider";
import { getUnits, UnitConverter, UnitSize, UnitSystem } from "../../lib";
function isUnitSystem(value) {
    for (const i in UnitSystem) {
        if (UnitSystem[i] === value)
            return true;
    }
    return false;
}
export const Convert = function Convert({ unitType, valueUnit, defaultUnit, 
// decimalDigits = 2,
children, }) {
    const { formatter } = React.useContext(UiContext);
    const units = getUnits(unitType);
    let displayUnit;
    if (defaultUnit && isUnitSystem(defaultUnit)) {
        displayUnit = getUnits(unitType, defaultUnit).find(e => e.size === UnitSize.Medium);
    }
    else if (defaultUnit) {
        displayUnit = units.find(e => defaultUnit === e.id);
    }
    else {
        let sys = unitType;
        if (sys === "mass") {
            sys = "volume";
        }
        displayUnit = units.find(e => e.size === UnitSize.Medium && e.system === formatter.options.units[sys]) || units.find(e => valueUnit === e.id);
    }
    if (!displayUnit)
        return React.createElement(React.Fragment, null);
    return React.createElement(React.Fragment, null,
        formatter.number(UnitConverter.convert(children, valueUnit, displayUnit.id), {}),
        "\u00A0",
        displayUnit.abbr);
};
