var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action, computed } from "mobx";
import React from "react";
import styled, { css } from "styled-components";
// Generate random string
function randomString(len) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < len; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}
export class PaneState {
    constructor(title, dropdown = false, hasDropdownParent = false, mainState = null) {
        this.dropdown = dropdown;
        this.hasDropdownParent = hasDropdownParent;
        this.mainState = mainState;
        this.key = randomString(10);
        this.items = [];
        this.forceRerender = 1;
        this.title = title;
    }
    setTitle(title) {
        this.title = title;
        this.forceRerender = (this.forceRerender + 1) % 7;
    }
    updateChild(props) {
        // insert props into items if not already there
        const index = this.items.findIndex(item => item.tabKey === props.tabKey);
        if (index === -1) {
            this.items.push(props);
        }
        else {
            this.items = this.items.map(item => {
                if (item.tabKey === props.tabKey) {
                    return props;
                }
                return item;
            });
        }
        this.items = [...this.items];
    }
    forgetChild(tabKey) {
        this.items = this.items.filter(item => item.tabKey !== tabKey);
        this.items = [...this.items];
    }
    setSize(size) {
        if (!size)
            return;
        this.size = size;
    }
    setButton(isButton) {
        this.isButton = isButton;
    }
    setHideInMore(hideInMore) {
        this.hideInMore = hideInMore;
    }
    setItems(items) {
        this.forceRerender = (this.forceRerender + 1) % 7;
        this.items = items;
    }
    setColor(color) {
        this.color = color;
    }
    setHighlight(highlight) {
        this.highlight = highlight;
    }
    setOnTabClick(onClick) {
        this.onClick = onClick;
    }
    setDisabled(isDisabled) {
        this.isDisabled = isDisabled;
    }
    setHidden(isHidden) {
        this.isHidden = isHidden;
        if (this.isHidden) {
            this.mainState.reportIsHiddenChange();
        }
    }
    setUnmount(unmount) {
        this.unmount = unmount;
    }
    setRemount(remount) {
        this.remount = remount;
    }
    setProps(props) {
        this.props = props;
    }
}
__decorate([
    observable
], PaneState.prototype, "forceRerender", void 0);
__decorate([
    observable
], PaneState.prototype, "color", void 0);
__decorate([
    observable
], PaneState.prototype, "size", void 0);
__decorate([
    observable
], PaneState.prototype, "hideInMore", void 0);
__decorate([
    observable
], PaneState.prototype, "highlight", void 0);
__decorate([
    observable
], PaneState.prototype, "isDisabled", void 0);
__decorate([
    observable
], PaneState.prototype, "isHidden", void 0);
__decorate([
    observable
], PaneState.prototype, "isButton", void 0);
__decorate([
    observable
], PaneState.prototype, "onClick", void 0);
__decorate([
    action
], PaneState.prototype, "setTitle", null);
__decorate([
    action
], PaneState.prototype, "updateChild", null);
__decorate([
    action
], PaneState.prototype, "forgetChild", null);
__decorate([
    action
], PaneState.prototype, "setSize", null);
__decorate([
    action
], PaneState.prototype, "setButton", null);
__decorate([
    action
], PaneState.prototype, "setHideInMore", null);
__decorate([
    action
], PaneState.prototype, "setItems", null);
__decorate([
    action
], PaneState.prototype, "setColor", null);
__decorate([
    action
], PaneState.prototype, "setHighlight", null);
__decorate([
    action
], PaneState.prototype, "setOnTabClick", null);
__decorate([
    action
], PaneState.prototype, "setDisabled", null);
__decorate([
    action
], PaneState.prototype, "setHidden", null);
export class TabState {
    get tabSize() {
        this.knownPanels;
        return Array.from(this.knownPanels.values()).filter(e => !e.hasDropdownParent).reduce((acc, cur) => {
            return acc + (cur.size ?? 0);
        }, 0);
    }
    constructor(options) {
        this.tabLeave = "keep";
        this.initial = "mountOnEnter";
        this.knownPanels = new Map();
        if (options.tabLeave)
            this.tabLeave = options.tabLeave;
        if (options.initial)
            this.initial = options.initial;
        this.isControlled = !!options.isControlled;
    }
    reportIsHiddenChange() {
        // check if the active panel is hidden
        if (this.knownPanels.get(this.activePanel)?.isHidden) {
            // if it is, find the next panel that is not hidden
            const arrayPanels = Array.from(this.knownPanels.keys());
            const index = Array.from(arrayPanels).indexOf(this.activePanel);
            let idx = index;
            if (index === -1)
                idx = 0;
            else if (arrayPanels[idx + 1])
                idx = idx + 1;
            else if (arrayPanels[idx - 1])
                idx = idx - 1;
            else
                idx = 0;
            const nextKey = arrayPanels[idx];
            if (nextKey)
                this.setActivePanel(nextKey);
        }
    }
    setActivePanel(key) {
        // if the targeted panel is hidden or not even defined
        // this only applies, if the tab is not controlled
        if (!this.isControlled && (this.knownPanels.get(key)?.isHidden || this.knownPanels.get(key) == null)) {
            if (this.knownPanels.size === 0)
                return; // if we got no panels return here
            const arrayPanels = Array.from(this.knownPanels.keys());
            const index = Array.from(arrayPanels).indexOf(key);
            const nextKey = arrayPanels[index === -1 ? 0 : index + 1];
            if (nextKey)
                this.setActivePanel(nextKey);
            return;
        }
        // this.props?.onBeforeChangeTab({
        // 	from: this.activePanel,
        // 	to: key,
        // }, this);
        this.activePanel = key;
    }
    unmountTab(key) {
        if (key == null) {
            this.knownPanels.forEach((e, i) => {
                if (i !== this.activePanel) {
                    e?.unmount?.();
                }
            });
        }
        else if (Array.isArray(key)) {
            key.forEach(e => this.getState(e)?.unmount?.());
        }
        else {
            this.getState(key)?.unmount?.();
        }
    }
    remountTab(key) {
        if (key == null) {
            this.knownPanels.forEach((e, i) => {
                if (i !== this.activePanel) {
                    e?.remount?.();
                }
            });
        }
        else if (Array.isArray(key)) {
            key.forEach(e => this.getState(e)?.remount?.());
        }
        else {
            this.getState(key)?.remount?.();
        }
    }
    getState(key) {
        return this.knownPanels?.get(key);
    }
    registerPanel(key, title, options = { dropdown: false, hasDropdownParent: false, props: null }) {
        const pane = new PaneState(title, options.dropdown, options.hasDropdownParent, this);
        pane.setColor(options.props?.color);
        this.knownPanels.set(key, pane);
        this.knownPanels = new Map(Array.from(this.knownPanels.entries()));
    }
    unregisterPanel(key) {
        this.knownPanels.delete(key);
        this.knownPanels = new Map(Array.from(this.knownPanels.entries()));
    }
}
__decorate([
    observable
], TabState.prototype, "knownPanels", void 0);
__decorate([
    observable
], TabState.prototype, "activePanel", void 0);
__decorate([
    computed
], TabState.prototype, "tabSize", null);
__decorate([
    action.bound
], TabState.prototype, "setActivePanel", null);
__decorate([
    action.bound
], TabState.prototype, "registerPanel", null);
__decorate([
    action.bound
], TabState.prototype, "unregisterPanel", null);
const TabDivV1 = styled.div `
	padding: 0 16px;
	height: 38px;
	line-height: 38px;
	user-select: none;
	cursor: pointer;
	color: black;
	flex-flow: nowrap;
	white-space: nowrap;
	${p => p.$disabled && css `cursor: not-allowed;`}
	${p => p.$active ? css `
		background-color: ${p.theme.color[p.color ?? "primary"][500].main};
		color: ${p.theme.color[p.color ?? "primary"][500].font};
	` :
    !p.$disabled && css `
		&:hover {
			background-color: #e2e2e2;
		}`}
`;
const TabDivV2 = styled.div `
	padding: 0px 16px;
	height: 39px;
	line-height: 39px;
	user-select: none;
	cursor: pointer;
	color: black;
	flex-flow: nowrap;
	white-space: nowrap;
	background-color: white;
	${p => p.$disabled && css `cursor: not-allowed;`}
	${p => p.$active ? css `
		border-top: 2px solid ${p.theme.color[p.color ?? "primary"][500].main};
		border-right: 1px solid #ccc;
		margin-bottom: -1px;
		height: 40px;
		// color: ${p.theme.color[p.color ?? "primary"].font};
	` : !p.$disabled && css `
		&:hover {
			background-color: #e2e2e2;
		}
	`}
		
`;
export const TabDiv = function TabDiv({ active, variant, color, disabled, hideFirstBorder, children, ...otherProps }) {
    const ctxVariant = React.useContext(TabVariant);
    const finalVariant = variant ?? ctxVariant;
    switch (finalVariant) {
        case "light":
            return React.createElement(TabDivV2, { ...otherProps, "$active": active, "$color": color, "$disabled": disabled, "$hideFirstBorder": hideFirstBorder }, children);
        default:
        case "default":
            return React.createElement(TabDivV1, { ...otherProps, "$active": active, "$color": color, "$disabled": disabled }, children);
    }
};
export const TabVariant = React.createContext("default");
export const TabStateCtx = React.createContext(null);
export const PaneStateCtx = React.createContext(null);
export function usePaneState() {
    const ctx = React.useContext(PaneStateCtx);
    return ctx;
}
