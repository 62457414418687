import { DirectoryMember, DirectoryMemberData, FpApi, FpDirClientState, FpDirMember, LocationSetupStatus, LocationSetupUtilCore, LocationValidationSetupParams, LocationValidationSetupResult, RecencyType } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import { ThemeVariant } from "@tcs-rliess/fp-web-ui";
import { chain, chunk, every, flatten, isMatch, isNil, matches, omitBy, pickBy, uniq } from "lodash-es";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../FleetplanApp";

type PositionCheckCalculation = {
	totalVariant: ThemeVariant;
	fatal: boolean;
	isInvalid: boolean;
	set: {
		set: number;
		variant: ThemeVariant;
		certificatesValid: boolean;
		certificateFailReason?: "CERTIFICATE_INVALID" | "SET_MISSING_PERMISSION"
		recencyValid: boolean;
		recency: Record<string, {
			variant: ThemeVariant;
			status: {
				type: RecencyType;
				variant: ThemeVariant;
			}[];
		}>
	}[];
	isRemoved: boolean;
	isArchived: boolean;
	isMissingPosition: boolean;
}


export class LocationSetupUtil extends LocationSetupUtilCore {
	public static didLoadedSets = false;
	// 2024-07-30 - [PR] for now we ignore the set of the shifts (applying standbys will ignore the set of the shift (target))
	// needs to be checked later!
	public static partialEqualPosition(self: FpApi.Resource.Duty.Position, other: FpApi.Resource.Duty.Position) {
		// is using same set, so its ok
		if(self.set && other.set && self.set === other.set) {
			return true;
		}
		// partial compare targets of self and other
		if(self.target && other.target) {
			for(const target of self.target) {
				if(other.target.find(t => isMatch(pickBy(t, Boolean), pickBy(target, Boolean)))) {
					return true;
				}
			}
		}
		return false;
	}
	public isPrepared = false;
	public static async fromDscaids(app: FleetplanApp, dscaids: number[]) {
		const util = new LocationSetupUtil(app);
		await util.prepareUtil();
		await util.prepareDscaids(dscaids);
		return util;
	}
	constructor(private app: FleetplanApp) {
		super();
	}

	public async prepareDscaids(dscaids: number[]) {
		let neededDscaids = uniq(dscaids);
		neededDscaids = neededDscaids.filter(e => !this.contactStates.has(e));
		if(neededDscaids.length === 0) {
			return;
		}
		if(neededDscaids.length > 200) {
			fpLog.warn("prepareDscaids: dscaids length is greater than 200");
		}
		for (const c of chunk(neededDscaids, 200)) {
			const states = await this.app.store.contactState.getIdList(c);
			states.forEach(e => {
				this.contactStates.set(e.id, e);
			});
		}
	}
	// private cache: Map<string, Map<string, SetupStatus >> = new Map();

	private contactStates = new Map<number, FpDirClientState>();
	public async prepareUtil(): Promise<void> {
		if(this.isPrepared) return;
		if(LocationSetupUtil.didLoadedSets === false) {
			// special case: load sets with no certificates - this is why we use fetchSets and not ensureSets
			await this.app.store.certificateV3Store.fetchSets(undefined, false, true);
			LocationSetupUtil.didLoadedSets = true;
		}

		this.isPrepared = true;
	}

	/**
	 * 
	 * @param fpdirloc locations you want to check for roles
	 * @param role role you want to check
	 * @param dateTime date for the check. If not provided, it will use the current date. Setups are time based, so we need to provide a date
	 */
	public async getLocationsForRole(role: FpApi.Calendar.Event.EventResourceRole, dateTime: DateTime = DateTime.now(), fpdirloc?: number[]): Promise<number[]> {
		const setups = await this.app.store.resource.locationSetup.getRange(dateTime, dateTime).then(e => fpdirloc ? e.filter(e => fpdirloc.includes(e.fpdirloc)) : e);
		return setups.filter(s => this.hasSetupRole(s, role)).map(e => e.fpdirloc);
	}

	public async prepareAll() {
		await this.prepareUtil();
		const dscaids = uniq(this.app.store.fpDir.directory.getMembers().filter(e => e.linktype === "dscaid").map(e => +e.linkid));
		await this.prepareDscaids(dscaids);
	}
	/**
	 * 
	 *public async prepareUtil(dscaids = []): Promise<void> {
		if(this.isPrepared) return;
		const _contactStates = [];
		for (const c of chunk(dscaids, 500)) {
			const states = await this.app.store.contactState.getIdList(c);
			_contactStates.push(...states);
		}
		this.contactStates = new Map(_contactStates.map(e => [ e.id, e ]));

		if(LocationSetupUtil.didLoadedSets === false) {
			// special case: load sets with no certificates - this is why we use fetchSets and not ensureSets
			await this.app.store.certificateV3Store.fetchSets(undefined, false, true);
			LocationSetupUtil.didLoadedSets = true;
		}

		this.isPrepared = true;
	}
	 */

	public async getContactsForRole({ duties, role }:{ duties: FpApi.Resource.Duty.Schedule[], role: string }) {
		const setupIds = chain(duties).map(e => e.data.dsrlsid).uniq().value();
		// load setups
		const setupsMap = new Map((await this.app.store.resource.locationSetup.getIdList(setupIds)).map(e => [ e.id, e ]));
		return chain(duties).map(duty => {
			const setup = setupsMap.get(duty.data.dsrlsid);
			const position = setup.data.positions.find(e => e.id === duty.data.dsrdsidPos);
			return [ duty, setup, position ] as const;
		}).filter(([ , , position ]) => position.role === role)
			.map(([ duty ]) => +duty.linkId).value();
	}

	protected syncGetLinkType<T = unknown>(linkId: string, linkIdType: string): T {
		switch(linkIdType) {
			case "fpvid":
				return this.app.store.resource.aircraft.getId(+linkId) as any;
		}
	}

	public getAssignmentsFromPosition(position: FpApi.Resource.Duty.Position): { fpdirgrp?: number, fpdirpos?: number }[] {
		const assignments: ReturnType<typeof this.getAssignmentsFromPosition> = [];
		if(position.target) {
			position.target.forEach(t => {
				if(t.fpdirgrp || t.fpdirpos) {
					assignments.push(t);
				}
			});
		}
		if(position.set) {
			const set = this.app.store.certificateV3Store.setsObj[position.set];
			if(set) {
				set.relations.filter(rel => rel.type === "fpdirlink" && rel.category === "ASSIGNEDTO")
					.forEach(rel => {
						assignments.push({
							fpdirgrp: rel.fpdirgrp,
							fpdirpos: rel.fpdirpos,
						});
					});
			}
		}
		return assignments;
	}

	/**
	 * To eject contacts when certificates did expire, set keepBySetInvalid to false
	 * its true per default
	 * */
	public getValidMemberForPosition(dscaid: number, position: FpApi.Resource.Duty.Position, keepBySetInvalid = true) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const membersForContact = this.app.store.fpDir.directory.getMembersByResource("dscaid", dscaid);
		let response: DirectoryMember;
		if(position.set) {
			const set = this.app.store.certificateV3Store.setsObj[position.set];
			if(set) {
				const isSetValid = this.app.store.certificateV3Store.isSetValidForLinkContactState(set, this.contactStates.get(dscaid));
				const applicableRelations = set.relations.filter(rel => rel.category === "ASSIGNEDTO" && (rel.fpdirgrp || rel.fpdirpos));
				const filters = applicableRelations.map(rel => {
					return omitBy({
						fpdirgrp: rel.fpdirgrp,
						fpdirpos: rel.fpdirpos,
					}, e => !e);
				});
				const applicableMember = membersForContact.find(member => {
					return filters.find(filter => {
						return isMatch(member, filter);
					});
				});

				if(!isSetValid) response = applicableMember;
				else response = null;
			}
		}
		/** when its now validated to true, we can asume this contact is fine */
		/** if not, we still need to checks for positions */
		const memberFilter = new Map(membersForContact.map(member => [ member, (omitBy({
			fpdirgrp: member.grp,
			fpdirpos: member.pos,
		}, e => !e)) ]));
		if(response || !position.target) return response;
		for(const member of membersForContact) {
			for(const target of position.target) {
				if(isMatch(memberFilter.get(member), target)) {
					return member;
				}
			}
		}
	}

	private transformToFilter(relation: FpApi.InlineRelation) {
		return omitBy({
			fpdirgrp: relation.fpdirgrp,
			fpdirpos: relation.fpdirpos,
			fpdirloc: relation.fpdirloc,
		}, e => !e);
	}

	/**
	 * To eject contacts when certificates did expire, set keepBySetInvalid to false
	 * its true per default
	 * */
	public getValidMembersForPosition(dscaid: number, position: FpApi.Resource.Duty.Position, keepBySetInvalid = true) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const membersForContact = this.app.store.fpDir.directory.getMembersByResource("dscaid", dscaid);
		let response: DirectoryMember[];
		if(position.set) {
			const set = this.app.store.certificateV3Store.setsObj[position.set];
			if(set) {
				const isSetValid = this.app.store.certificateV3Store.isSetValidForLinkContactState(set, this.contactStates.get(dscaid));
				const applicableRelations = set.relations.filter(rel => rel.category === "ASSIGNEDTO" && (rel.fpdirgrp || rel.fpdirpos));
				const filters = applicableRelations.map(rel => {
					return omitBy({
						fpdirgrp: rel.fpdirgrp,
						fpdirpos: rel.fpdirpos,
					}, e => !e);
				});
				const applicableMembers = membersForContact.filter(member => {
					return filters.find(filter => {
						return isMatch(member, filter);
					});
				});

				if (!isSetValid) response = applicableMembers;
				else response = null;
			}
		}
		/** when its now validated to true, we can asume this contact is fine */
		/** if not, we still need to checks for positions */
		const memberFilter = new Map(membersForContact.map(member => [ member, (omitBy({
			fpdirgrp: member.grp,
			fpdirpos: member.pos,
		}, e => !e)) ]));
		if(!position.target) return response;
		for(const member of membersForContact) {
			for(const target of position.target) {
				if(isMatch(memberFilter.get(member), target)) {
					if(!response) response = [];
					response.push(member);
				}
			}
		}
		return response;
	}


	/**
	 * 
	 * @returns mapped by dscaid X member
	 */
	public getApplicableMembersForPosition({ position, filter, providedMembers }: {position: FpApi.Resource.Duty.Position, date: DateTime, filter?: { loc?: number }, providedMembers?: DirectoryMember[], skipValidation?: boolean, shift?: string }) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const applicableMembers = new Map<string, DirectoryMember>();
		const members = providedMembers ?? this.app.store.fpDir.directory.getMembers();
		for(const member of members) {
			// if this member is not a dscaid (contact), continue
			if(member.linktype !== "dscaid") continue;
			// if this member is not applicable for our filter, continue
			if(filter && !Object.keys(filter).every(key => {
				return member[key] === filter[key];
			})) {
				continue;
			}
			if(position.target) {
				position.target.forEach(t => {
					if(isMatch({
						grp: member.grp,
						pos: member.pos,
					}, omitBy({
						grp: t.fpdirgrp,
						pos: t.fpdirpos,
					}, isNil))) {
						applicableMembers.set(member.linkid, member);
					}
				});
			}
		}
		return applicableMembers;
	}

	public getLicenseEndorsements(setup: FpApi.Resource.Duty.LocationSetup, dsrdsidRes?: string): Array<string> {
		const licenseEndorsements = new Set<string>();
		const resources: FpApi.Resource.Duty.Resource[] = dsrdsidRes ? setup.data.resources.filter(e => e.id === dsrdsidRes) : setup.data.resources;
		resources.forEach(e => {
			if(e.linkType === "fpvid") {
				if(e.data?.fpvid?.licenseEndorsement) {
					licenseEndorsements.add(e.data.fpvid.licenseEndorsement);
				} else if (e.data?.fpvid?.fpdbvmid) {
					const le = this.app.store.resource.aircraftModel.getId(e.data.fpvid.fpdbvmid)?.licenceEndorsement;
					if(le) {
						licenseEndorsements.add(le);
					}
				}
			}
		});
		return Array.from(licenseEndorsements);
	}

	public isValidForSetup({ setup, date, providedMembers, skipValidation, shift }: {setup: FpApi.Resource.Duty.LocationSetup, date: DateTime, providedMembers?: DirectoryMember[], skipValidation?: boolean, shift?: string }) {
		const validContacts = new Set<number>();
		for(const position of setup.data.positions) {
			const members = this.getApplicableMembersForPosition({ position, date, providedMembers, shift });
			members.forEach((_, value) => {
				validContacts.add(+value);
			});
		}
		return Array.from(validContacts);
	}

	public getSetValidityVariant(setId: number, dscaid: number) {
		return this.app.store.certificateV3Store.getSetValidityVariantContactState(setId, this.contactStates.get(dscaid));
	}

	public getSetsFromPosition(position: FpApi.Resource.Duty.Position, dsrsid?: string): number[] {
		const behaviour = position.shiftSettings?.[dsrsid]?.setBehavior;
		if(!behaviour) {
			// whatever, there can be only one set
			return [ position.set ].filter(Boolean);
		}
		if(behaviour === "replace") {
			return [ position.shiftSettings?.[dsrsid]?.set ?? position.set ].filter(Boolean);
		} else if(behaviour === "add") {
			return [ position.set, position.shiftSettings?.[dsrsid]?.set ].filter(Boolean);
		}
		return [];
	}

	// skip validation skips the certificate set, and only matches the ASSIGNEDTO relations
	public validateSet(setId: number, member: FpDirMember<DirectoryMemberData>, skipValidation: boolean) {
		const set = this.app.store.certificateV3Store.setsObj[setId];
		if(set) {
			const isSetValid = this.app.store.certificateV3Store.isSetValidForLinkContactState(set, this.contactStates.get(+member.linkid));
			if(skipValidation) {
				const relations = set.relations.filter(rel => rel.category === "ASSIGNEDTO");
				for(const relation of relations) {
					if(isMatch(pickBy({
						fpdirgrp: member.grp,
						fpdirpos: member.pos,
						fpdirloc: member.loc,
					}, Boolean), this.transformToFilter(relation))) {
						return true;
					}
				}
			}
			if(!skipValidation && isSetValid) {
				return true;
			}
			if(skipValidation && !set.strict) {
				return true;
			}
		}
		return false;
	}

	public getValidShiftsForPosition({ position, date, linkId, skipValidation, setup, allowSuitableRoles } : {position: FpApi.Resource.Duty.Position, date?: DateTime, linkId: string, skipValidation?: boolean, setup: FpApi.Resource.Duty.LocationSetup, allowSuitableRoles: boolean }) {
		// get all shifts first
		const shifts = new Set([ ...position.shifts ]);
		const members = this.app.store.fpDir.directory.getMembersByResource("dscaid", linkId);
		const shiftSettings = position.shiftSettings;
		const validShifts = new Set<string>();
		function acceptShift(shift: string) {
			validShifts.add(shift);
			shifts.delete(shift);
		}
		const positionTargets = allowSuitableRoles ? this.getSuitableTargets({ position, otherPositions: setup.data.positions }) : [ ...(position.target ?? []) ];
		for(const member of members) {
			// check if position has target, if it has a target, match it with the member. If target does not match -> continue
			// -> when the contact does not even match the target -> it cannot be valid for any shift
			//TODO
			// 2024-07-29 - [PR] Call with RW and DL, For now we will validate against OR.
			if(position.validationBehavior === "AND") {
				if(positionTargets?.length) {
					const target = positionTargets.find(t => {
						return isMatch({
							fpdirgrp: member.grp,
							fpdirpos: member.pos,
						}, t);
					});
					if(!target) continue;
				}
				for(const shift of shifts) {
					if(shiftSettings == null || !shiftSettings[shift]) {
						acceptShift(shift);
						continue;
					} // no settings, so we can use it. There is no limit
					const shiftSetting = shiftSettings[shift];
					if(!shiftSetting.set) {
						// check if there is any other set, which is valid for this member
						if(position.set) {
							if(this.validateSet(position.set, member, skipValidation)) // -> this would mean the whole position is not valid for the contact. 
								acceptShift(shift);
							continue;
						} else {
							// in case there is no set at all, we're
							acceptShift(shift);
							continue;
						}
					} // no set, so we can use it. There is no limit
					const setBehavior = shiftSetting.setBehavior ?? "replace";
					if(setBehavior === "replace" || setBehavior == null) {
						// we only need to check against the shift set
						if(this.validateSet(shiftSetting.set, member, skipValidation)) {
							acceptShift(shift);
						}
					} else if (setBehavior === "add") {
						// we need to match against the position set and the shift set. If there is no position set, we skip it
						if([ shiftSetting.set, position.set ].filter(Boolean).every(set => this.validateSet(set, member, skipValidation))) {
							acceptShift(shift);
						}
					}
					// no need to loop further if we already accepted all shifts
					if(shifts.size === 0) break;
				}
			} else {
				if(positionTargets?.length) {
					const target = positionTargets.find(t => {
						return isMatch({
							fpdirgrp: member.grp,
							fpdirpos: member.pos,
						}, t);
					});
					if(target) {
						// accept all shifts
						shifts.forEach(acceptShift);
						break;
					}
				} else {
					for(const shift of shifts) {
						if(shiftSettings == null || !shiftSettings[shift]) {
							acceptShift(shift);
							continue;
						} // no settings, so we can use it. There is no limit
						const shiftSetting = shiftSettings[shift];
						if(!shiftSetting.set) {
							// check if there is any other set, which is valid for this member
							if(position.set) {
								if(this.validateSet(position.set, member, skipValidation)) // -> this would mean the whole position is not valid for the contact. 
									acceptShift(shift);
								continue;
							} else {
								// in case there is no set at all, we're
								acceptShift(shift);
								continue;
							}
						} // no set, so we can use it. There is no limit
						const setBehavior = shiftSetting.setBehavior ?? "replace";
						if(setBehavior === "replace" || setBehavior == null) {
							// we only need to check against the shift set
							if(this.validateSet(shiftSetting.set, member, skipValidation)) {
								acceptShift(shift);
							}
						} else if (setBehavior === "add") {
							// we need to match against the position set and the shift set. If there is no position set, we skip it
							if([ shiftSetting.set, position.set ].filter(Boolean).every(set => this.validateSet(set, member, skipValidation))) {
								acceptShift(shift);
							}
						}
						// no need to loop further if we already accepted all shifts
						if(shifts.size === 0) break;
					}
				}
			}
		}
		return Array.from(validShifts);
	}

	public getAllApplicableMembersForPosition({ position, filter, providedMembers, setup, allowSuitableRoles }: {position: FpApi.Resource.Duty.Position, date: DateTime, filter?: { loc?: number }, providedMembers?: DirectoryMember[], skipValidation?: boolean, shift?: string, setup: FpApi.Resource.Duty.LocationSetup, allowSuitableRoles: boolean }) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const applicableMembers = new Map<string, DirectoryMember[]>();
		if(!position) return applicableMembers;
		const members = providedMembers ?? this.app.store.fpDir.directory.getMembers();
		for(const member of members) {
			// if this member is not a dscaid (contact), continue
			if(member.linktype !== "dscaid") continue;
			if(member.sec) continue;
			// if this member is not applicable for our filter, continue
			if(filter && !Object.keys(filter).every(key => {
				return member[key] === filter[key];
			})) {
				continue;
			}
			// in case a shift is provided

			const targets = allowSuitableRoles ? this.getSuitableTargets({ position, otherPositions: setup.data.positions }) : [ ...(position.target ?? []) ];

			if(targets) {
				targets.forEach(t => {
					const copy = pickBy({
						fpdirgrp: t.fpdirgrp,
						fpdirpos: t.fpdirpos,
					}, Boolean);
					const memberComparator = pickBy({
						fpdirgrp: member.grp,
						fpdirpos: member.pos,
					}, Boolean);
					if(isMatch(memberComparator, copy)) {
						if(!applicableMembers.has(member.linkid)) {
							applicableMembers.set(member.linkid, []);
						}
						applicableMembers.get(member.linkid).push(member);
					}
				});
			}
		}
		return applicableMembers;
	}

	public validateSetup(params: LocationValidationSetupParams): LocationValidationSetupResult {
		if (!this.isPrepared) throw new Error("SetupUtil not prepared");
		const setupResult = super.validateSetup(params);

		for (const dateResult of setupResult.dates.values()) {
			for (const positionResult of dateResult.positions) {
				if (positionResult.position.set) {
					const dscaidList = uniq(positionResult.schedules.map(v => +v.linkId));
					dscaidList.forEach(dsc => {
						const validity = this.app.store.crewCheck.getValidityForSet(dsc, dateResult.date, positionResult.position.set);
						validity.sets.forEach((e, i) => {
							if (e["missing"].length) {
								positionResult.missingCerts = e["missing"];
							}
						});
					});
				}
			}
		}

		return setupResult;
	}



	public performPositionCheckReason(params: { schedule: FpApi.Resource.Duty.Schedule; position: FpApi.Resource.Duty.Position; setup: FpApi.Resource.Duty.LocationSetup, allowSuitableRoles: boolean }): PositionCheckCalculation {

		const calculation: PositionCheckCalculation = {
			totalVariant: "success",
			fatal: false,
			isInvalid: false,
			set: [],
			isArchived: false,
			isRemoved: false,
			isMissingPosition: false,
		};
		// if(!params.position) reasons.push({ title: "Position is not available", message: "The position is not available anymore", code: "POSITION_NOT_AVAILABLE" });
		// check if contact is archived
		const contact = this.app.store.contact.getId(+params.schedule.linkId);
		const from = DateTime.fromISO(params.schedule.dateFrom);
		if(!contact) {
			calculation.isRemoved = true;
			calculation.fatal = true;
		}
		if(contact?.isActive === false) {
			calculation.isArchived = true;
			calculation.fatal = true;
		}
		// check if certs are fine, if they are required
		const members = this.app.store.fpDir.directory.getMembersByResource("dscaid", params.schedule.linkId);
		const applicable = this.getAllApplicableMembersForPosition({
			position: params.position,
			date: from,
			providedMembers: members,
			shift: params.schedule.data.dsrsid,
			skipValidation: true,
			setup: params.setup,
			allowSuitableRoles: params.allowSuitableRoles,
		});
		if(!applicable.get(params.schedule.linkId)?.length) {
			calculation.isMissingPosition = true;
			calculation.fatal = true;
		}
		// if set, check certificates
		const sets: Array<number> = [];
		const shiftSettings = params.position.shiftSettings?.[params.schedule.data.dsrsid];
		if(shiftSettings?.set) {
			if(shiftSettings.setBehavior === "add") {
				sets.push(...([ shiftSettings.set, params.position.set ].filter(Boolean)));
			} else { // replace or undefined
				sets.push(shiftSettings.set);
			}
		} else if(params.position.set) {
			sets.push(params.position.set);
		}
		const licenseEndorsements = this.getLicenseEndorsements(params.setup);
		if(sets.length) {
			const setStatus: Record<number, PositionCheckCalculation["set"][number]> = {};
			const result = every(sets, set => {
				setStatus[set] = {
					certificatesValid: true,
					recencyValid: true,
					recency: {},
					set,
					variant: "success",
				};
				calculation.set.push(setStatus[set]);
				// doesnt matter which member, the method only uses the linkid
				// TODO Talk to DL: Need to validate set for a given timestamp
				const response = this.app.store.certificateV3Store.isSetValidForLinkContactState(set, this.contactStates.get(+params.schedule.linkId), params.schedule.dateFrom);
				// if(response.isValid == false) debugger;
				if(response == undefined) { // missing a permission, thus the whole validation is invalid
					calculation.isInvalid = true; // missing a permission
					setStatus[set].certificatesValid = false;
					setStatus[set].certificateFailReason = "SET_MISSING_PERMISSION";
					setStatus[set].variant = "cancelled";
				}
				// only when the set is valid, we add this to the failed sets
				if(response === false && calculation.isInvalid === false) {
					setStatus[set].certificatesValid = false;
					setStatus[set].certificateFailReason = "CERTIFICATE_INVALID";
					setStatus[set].variant = "danger";
				}
				return response;
			});
			if(result === false && calculation.isInvalid === false) { // cert validation results in false, thus this is a fatal error
				calculation.fatal = true;
			}
			if(calculation.isInvalid) {
				// if it is invalid we are missing permissions, thus we cannot read the recency on the set. So we abort early since we cannot check for the said recency
				return this.finalizeCalculation(calculation);
			}

			sets.map(e => this.app.store.certificateV3Store.setsObj[e.toString()]).forEach(e => {
				if(!e.recency) return;
				const validation = setStatus[e.id];
				licenseEndorsements.forEach(endorsement => {
					const recCalculation = this.app.store.certificateV3Store.getRecencyCalculation(e, this.contactStates.get(+params.schedule.linkId), endorsement, from);
					validation.recency[endorsement] = recCalculation;
				});
				validation.recency["global"] = this.app.store.certificateV3Store.getRecencyCalculation(e, this.contactStates.get(+params.schedule.linkId), undefined, from);
			});
			// now loop again over the sets and now do the check for recency on this set
		}
		return this.finalizeCalculation(calculation);
	}

	private getWorstVariant(variants: ThemeVariant[]): ThemeVariant {
		if(variants.includes("danger")) return "danger";
		if(variants.includes("cancelled")) return "cancelled";
		if(variants.includes("warning")) return "warning";
		return "success";
	}


	/**
	 * the finalize calculation will determine the overall status of the calculation
	 * @param calculation 
	 */
	private finalizeCalculation(calculation: PositionCheckCalculation): PositionCheckCalculation {
		calculation.set.forEach(e => {
			this.finalizeSetValidation(e);
		});
		if(calculation.isInvalid) {
			calculation.totalVariant = "cancelled";
			return calculation;
		}
		if(calculation.fatal) {
			calculation.totalVariant = "danger";
			// its fatal, no need to check further
			return calculation;
		}
		calculation.totalVariant = this.getWorstVariant([ calculation.totalVariant, ...calculation.set.map(e => e.variant) ]);
		if(calculation.totalVariant === "danger") {
			calculation.fatal = true;
		}
		return calculation;
	}

	private finalizeSetValidation(set: PositionCheckCalculation["set"][number]): PositionCheckCalculation["set"][number] {
		if(set.certificatesValid === false) {
			set.variant = "danger";
		}

		const variants: ThemeVariant[] = [];
		for(const key in set.recency) {
			const obj = set.recency[key];
			variants.push(obj.variant);
		}
		const recencyVariant = this.getWorstVariant(variants);
		set.recencyValid = recencyVariant !== "danger";
		set.variant = this.getWorstVariant([ set.variant, ...variants ]);
		return set;
	}

	public performPositionCheck(params: { schedule: FpApi.Resource.Duty.Schedule; position: FpApi.Resource.Duty.Position; setup: FpApi.Resource.Duty.LocationSetup; allowSuitableRoles: boolean }): boolean {
		const results = this.performPositionCheckReason(params);
		if(results.isInvalid) return undefined;
		return results.totalVariant !== "danger";
	}

	public getCertificateSetsForContact(dscaid: number, setups: FpApi.Resource.Duty.LocationSetup[] = []): Array<number> {
		const uniSetsFromPositions = chain(setups)
			.map(e => e.data.positions.map(e => e.set))
			.flatten()
			.filter(Boolean)
			.uniq()
			.value();
		const fpSets = uniSetsFromPositions.map(e => this.app.store.certificateV3Store.setsObj[e]);
		const resSets = new Set<number>();
		for(const fpSet of fpSets) {
			const isSetValid = this.app.store.certificateV3Store.isSetValidForLinkContactState(fpSet, this.contactStates.get(dscaid));
			if(!isSetValid) continue;
			if(isSetValid) {
				resSets.add(fpSet.id);
			}
		}
		return Array.from(resSets);
	}

	public groupContactsByValidRoles(dscaids: number[], setups: FpApi.Resource.Duty.LocationSetup[] = []): Map<string, number[]> {
		const res = new Map<string, Set<number>>();
		for(const dscaid of dscaids) {
			this.getApplicablePositionsForContact(dscaid, setups)
				.forEach(position => {
					if(!position.role) return;
					if(!res.has(position.role)) {
						res.set(position.role, new Set<number>());
					}
					res.get(position.role).add(dscaid);
				});
		}
		return new Map(Array.from(res.entries()).map(([ key, value ]) => [ key, Array.from(value) ]));
	}

	public getRolesForMember(members: DirectoryMember[], setups: FpApi.Resource.Duty.LocationSetup[]) {
		const roles = new Set<FpApi.Calendar.Event.EventResourceRole>;
		for(const setup of setups) {
			for(const position of setup.data.positions) {
				for(const target of position.target) {
					const matcher = matches(pickBy({
						grp: target.fpdirgrp,
						pos: target.fpdirpos
					}, Boolean));
					for(const member of members) {
						if(matcher(member)) {
							if(position.role) {
								roles.add(position.role);
								break;
							}
						}
					}
				}
			}
		}
		return Array.from(roles);
	}

	public groupValidRolesByContact(dscaids: number[], setups: FpApi.Resource.Duty.LocationSetup[] = []): Map<number, string[]> {
		const res = new Map<number, string[]>();
		for (const dscaid of dscaids) {
			const roles = this.getApplicablePositionsForContact(dscaid, setups).map(position => position.role).filter(Boolean);
			if (roles.length) {
				res.set(dscaid, roles);
			}
		}
		return res;
	}

	public static flushCache(): void {
		LocationSetupUtil.getApplicablePositionsForContactCache.clear();
	}
	private static getApplicablePositionsForContactCache: Map<string, (FpApi.Resource.Duty.Position & { dsrlsid: number, fpdirloc: number, setup: FpApi.Resource.Duty.LocationSetup })[]> = new Map();
	private static getApplicablePositionsForContactGenerateKey(dscaid: number, setups: FpApi.Resource.Duty.LocationSetup[] = [], allowSuitableRoles: boolean): string {
		return `${dscaid}-${JSON.stringify(setups)}-${allowSuitableRoles ? "YES": "NO"}`;
	}
	/**
	 * @description useful method for the scheduler to get a list of positions, which are applicable for a given contact. Results are cached globally.
	 * @returns an array of positions, which are applicable for the given contact on the given setups
	 */
	public getApplicablePositionsForContact(dscaid: number, setups: FpApi.Resource.Duty.LocationSetup[] = [], force = false, allowSuitableRoles = false): (FpApi.Resource.Duty.Position & { dsrlsid: number, fpdirloc: number, setup: FpApi.Resource.Duty.LocationSetup })[] {
		if(!dscaid) return [];
		if(Number.isNaN(dscaid)) return [];
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const cacheKey = LocationSetupUtil.getApplicablePositionsForContactGenerateKey(dscaid, setups, allowSuitableRoles);
		if(!force && LocationSetupUtil.getApplicablePositionsForContactCache.has(cacheKey)) {
			return LocationSetupUtil.getApplicablePositionsForContactCache.get(cacheKey);
		}
		// get the users members
		const userMembers = this.app.store.fpDir.directory.getMembersByResource("dscaid", dscaid.toString());
		// get all positions out of the setups
		let filterMembers: Partial<{
			fpdirgrp: number;
			fpdirpos: number;
		}>[];
		const positions = flatten(setups.map(e => e.data?.positions.map(pos => ({ ...pos, dsrlsid: e.id, fpdirloc: e.fpdirloc, setup: e })) ?? []));
		const responsePositions: (FpApi.Resource.Duty.Position & { dsrlsid: number, fpdirloc: number, setup: FpApi.Resource.Duty.LocationSetup })[] = [];
		/**m loop over positions  */
		// if(dscaid === 5018231) debugger;
		const workPosition = (position: typeof positions[number]) => {
			const targets = allowSuitableRoles ? this.getSuitableTargets({ position, otherPositions: positions }) : [ ...(position.target ?? []) ];
			if(targets) {
				/** build filter members if did not previously exist */
				if(!filterMembers) {
					filterMembers = chain(userMembers).map(e => {
						const obj = chain({
							fpdirgrp: e.grp,
							fpdirpos: e.pos,
						}).omitBy(e => !e).value();
						if(Object.keys(obj).length) {
							return obj;
						}
					}).filter(Boolean).value();
				}
				// check if the member is in the target using _.isMatch
				const matched = targets.find(target => {
					return filterMembers.find(e => {
						return isMatch(e, target);
					});
				});
				if(matched) {
					responsePositions.push(position);
				}
			}
		};
		for(const position of positions) {
			/** when set is defined, check if set matches contact */
			workPosition(position);
		}
		LocationSetupUtil.getApplicablePositionsForContactCache.set(cacheKey, responsePositions);
		return responsePositions;
	}

	protected validateResourceStatus<T>(type: string, resource: T, date: DateTime): { status: LocationSetupStatus; reason: string; } {
		switch(type) {
			case "fpvid": {
				const aircraft = resource as FpApi.Resource.Aircraft;
				const acState = this.app.store.resource.aircraftState.getId(aircraft.id);
				const daysLeft = acState.maintenance?.next_maintenance_at_date ? Math.floor(DateTime.fromISO(acState.maintenance.next_maintenance_at_date).diff(date, "days").days) : null;
				if(daysLeft != null && daysLeft < 0) {
					return {
						status: LocationSetupStatus.NotOk,
						reason: "Needs Maintenance",
					};
				}
			}
		}
	}

	/*
	private validatePosition(schedules: Array<FpApi.Resource.Duty.Schedule>, position: FpApi.Resource.Duty.Position): ValidationPositionResult {
		throw new Error("validatePosition");
		if (!this.isPrepared) throw new Error("SetupUtil not prepared");

		const validations = super.validatePosition(schedules, position);
		if (position.set) {
			const dscaids = uniq(validations.duties.map(v => +v.linkId));
			dscaids.forEach(dsc => {
				const validity = this.app.store.crewCheck.getValidityForSet(dsc, day, position.set);
				validity.sets.forEach((e, i) => {
					if (e["missing"].length) {
						validations["missing_certs"] = e["missing"];
					}
				});
			});
		}

		return validations;
	}
	*/

	/**
	 * @description useful method for the scheduler to get a map of roles and dscaids for a given setup
	 * 
	 * @param setups the setups to get the role map from
	 * @param date if none is provided, DateTime.now() is used
	 * @param loc if none is provided, all locations are used
	 * @param skipValidation if true, the certificate validation-filter is skipped. Useful to get all potential members
	 * @returns Map with role as key and an array of dscaids as value. Dscaids are unique within the array.
	 */
	public getRoleMapFromSetups(
		{ setups, date, loc, skipSetValidationFilter }
		: {
			setups: FpApi.Resource.Duty.LocationSetup[],
			date?: DateTime,
			loc?: number,
			skipSetValidationFilter?: boolean
		}): Map<string, number[]> {
		const map = new Map<string, Set<number>>();
		// go over all setups and then over all positions
		for(const setup of setups) {
			for(const position of setup.data.positions) {
				// we want to map after the role, so we need to skip those without a role
				if(!position.role) continue;
				// get all members for this position
				const members = this.getApplicableMembersForPosition({
					position,
					date: date ?? DateTime.now(),
					filter: {
						loc
					},
					providedMembers: this.app.store.fpDir.directory.getMembers(),
					skipValidation: skipSetValidationFilter,
				});
				for(const member of members.values()) {
					const key = position.role;
					if(!map.has(key)) {
						map.set(key, new Set<number>());
					}
					map.get(key).add(+member.linkid);
				}
			}
		}
		// create Array map from Set Map
		return new Map(Array.from(map.entries()).map(([ key, value ]) => [ key, Array.from(value) ]));
	}

	public getPermittedApplicableAssignments({
		position,
		setup,
		linkId,
		linkType = "dscaid",
		shift,
		skipValidation = true,
		allowSuitableRoles = true,
		date = DateTime.now(),
	} : {
		position: FpApi.Resource.Duty.Position | string,
		setup: FpApi.Resource.Duty.LocationSetup,
		linkId: string,
		linkType?: string,
		shift?: string,
		skipValidation?: boolean,
		allowSuitableRoles?: boolean,
		date?: DateTime,
	}): {grp?: number, pos?: number}[] {
		if(typeof position === "string") {
			position = setup.data.positions.find(e => e.id === position);
		}
		const members = this.getAllApplicableMembersForPosition({
			position: position,
			date: date,
			providedMembers: this.app.store.fpDir.directory.getMembersByResource(linkType, linkId),
			shift: shift,
			skipValidation: skipValidation,
			setup: setup,
			allowSuitableRoles: allowSuitableRoles,
		}).get(`${linkId}`);
		return this.getPermittedAssignmentsFromMembers(members, this.app.ctx, setup.fpdirloc);
	}
}
