import Color from "color";
import { omit } from "lodash";
import React from "react";
import styled from "styled-components";
import { useThemeColor } from "../../Css";
import { Flex, FlexItem } from "../Flex";
import { Label } from "../Label";
import { Text } from "../Text";
const WorkflowWidgetCtx = React.createContext({ condensed: false, border: "top" });
const InternalWorkflowWidget = function InternalWorkflowWidget({ condensed = false, border = "top", noGap = false, style, children, }) {
    return (React.createElement(WorkflowWidgetCtx.Provider, { value: {
            border,
            condensed,
            noGap,
            style,
        } },
        React.createElement(Flex, { gap: noGap ? "unset" : ".5rem", wrap: true, style: style }, children)));
};
const ActiveCtx = React.createContext(null);
const WorkflowWidgetLevel = function WorkflowWidgetLevel(props) {
    const { condensed } = React.useContext(WorkflowWidgetCtx);
    const color = useThemeColor(props.variant, "#ddd");
    return (React.createElement(ActiveCtx.Provider, { value: props.isActive ?? null },
        React.createElement(WorkflowWidgetContainer, { column: true, style: props.style },
            condensed ? (React.createElement(FlexItem, { style: { height: "2px", backgroundColor: color[500].main } })) : (React.createElement(Label, { fullWidth: true, variant: props.variant, style: { lineHeight: ".25rem" } },
                React.createElement(Text, { size: "xs" }, props.title))),
            React.createElement(Flex, { gap: ".5rem" }, props.children))));
};
const Border = styled.div `
	height: 2px;
	width: 100%;
	background: ${p => p.background};
`;
export const WorkflowWidgetContainer = styled(Flex) `
gap: 3px;
`;
const WorkflowWidgetStep = function WorkflowWidgetStep(props) {
    const style = { ...(props.style ?? {}) };
    const color = useThemeColor(props.variant, "#ddd");
    const providedIsActive = React.useContext(ActiveCtx);
    const isActive = props.isActive ?? providedIsActive;
    const { condensed, border } = React.useContext(WorkflowWidgetCtx);
    const bg = new Color(color[500].main).mix(new Color("#fff"), .8).hex();
    const hover = new Color(bg).darken(.1).hex();
    let passedProps = omit(props, "variant", "isActive", "children", "ref");
    // this is dirty but 'Step' is a div and doesn't honor 'disabled'
    if (props.disabled) {
        passedProps = omit(passedProps, "onClick");
        style.cursor = "default";
    }
    // set cursor if the button is clickable
    else if (passedProps.onClick) {
        style.cursor = "pointer";
    }
    return (React.createElement(Flex, { column: true },
        border === "top" && !condensed && React.createElement(Border, { background: color[500].main }),
        React.createElement(Step, { style: style, isactive: isActive, bg: bg, condensed: condensed, hover: hover, ...passedProps }, props.children),
        border === "bottom" && !condensed && React.createElement(Border, { background: color[500].main })));
};
const InternalLabel = styled(Label) `
	padding: .25rem .5rem;
`;
const Step = styled.div `
	/*
	padding-left: 1.5rem - 1rem cut the arrow "cuts into" this padding, so visually we have = 0.5rem
	padding-right: 0.5rem + 1rem of the arrow that extends to the right, so visually we have = 0.5rem
	*/
	padding: ${p => p.condensed ? "1px .5rem 1px 1.5rem" : "0px .5rem 0px 1.5rem"};
	position: relative;

	&::after {
		content: "";

		position: absolute;
		top: 50%;
		right: -1rem;
		width: 1rem;
		height: 100%;
		background: ${p => p.bg};
		transform: translateY(-50%) translateX(-1px);
		clip-path: polygon(0% 0%,100% 50%,0% 100%);
		z-index: 1;
	}

	&:hover {
		background: ${p => p.hover};
		&:after {
			background: ${p => p.hover};
		}
	}

	opacity: ${props => props.isactive ? 1 : .5};
	clip-path: polygon(100% 0%, 115% 50%, 100% 100%, 0 100%, 1rem 50%, 0 0);
	background: ${p => p.bg};
`;
export const WorkflowWidget = Object.assign(InternalWorkflowWidget, {
    Level: WorkflowWidgetLevel,
    Step: Object.assign(WorkflowWidgetStep, {
        Label: InternalLabel,
    }),
});
