

import { icon } from "@fortawesome/fontawesome-svg-core";
import {
	faAngleDown, faAngleLeft, faAngleRight, faAnglesUpDown, faAngleUp, faArrowDownLeftAndArrowUpRightToCenter,
	faArrowDownShortWide, faArrowDownWideShort, faArrowLeft, faArrowRight, faArrowUp,
	faArrowUpRightAndArrowDownLeftFromCenter, faBackwardFast, faBan, faBars, faChartSimple, faCheck,
	faChevronDown, faChevronLeft, faChevronRight, faCircleMinus, faCirclePlus, faCopy,
	faDownload, faEye, faEyeSlash, faFileCsv, faFileExcel, faFill, faFilter, faForwardFast,
	faGripVertical, faHorizontalRule, faLink, faLinkHorizontalSlash, faObjectGroup, faPaste,
	faScissors, faSpinnerThird, faTableColumns, faTablePivot, faThumbTack, faTimes,
	IconDefinition
} from "@fortawesome/pro-light-svg-icons";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { AgChartsCommunityModule } from "ag-charts-community";
import { AlignedGridsModule, CellApiModule, CellStyleModule, ClientSideRowModelApiModule, ClientSideRowModelModule, ColumnApiModule,
	ColumnAutoSizeModule, ColumnHoverModule, CustomEditorModule, CustomFilterModule, DateFilterModule,
	DragAndDropModule, EventApiModule, ExternalFilterModule, GridStateModule, HighlightChangesModule,
	iconOverrides, ModuleRegistry, NumberEditorModule, NumberFilterModule, PinnedRowModule, provideGlobalGridOptions, QuickFilterModule,
	RenderApiModule, RowApiModule, RowAutoHeightModule, RowDragModule, RowSelectionModule, RowStyleModule, ScrollApiModule,
	TextEditorModule,
	TextFilterModule, themeBalham, TooltipModule, ValidationModule, ValueCacheModule
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AdvancedFilterModule, CellSelectionModule, ClipboardModule, ColumnMenuModule,
	ContextMenuModule, ExcelExportModule, GroupFilterModule, LicenseManager,
	MasterDetailModule, MultiFilterModule, RowGroupingModule, RowGroupingPanelModule,
	SetFilterModule, SparklinesModule, StatusBarModule, TreeDataModule } from "ag-grid-enterprise";

function makeIcon(def: IconDefinition): { svg: string } {
	return { svg: icon(def).html.join("") };
}

const fpIcons = iconOverrides({
	type: "image",
	// mask: true,
	icons: {
		"filter": makeIcon(faFilter),
		"menu": makeIcon(faBars),
		"columns": makeIcon(faTableColumns),
		"tree-closed": makeIcon(faChevronRight),
		"tree-open": makeIcon(faChevronDown),
		"asc": makeIcon(faArrowDownWideShort),
		"desc": makeIcon(faArrowDownShortWide),
		"cancel": makeIcon(faTimes),
		"chart": makeIcon(faChartSimple),
		"color-picker": makeIcon(faFill),
		"contracted": makeIcon(faChevronRight),
		"copy": makeIcon(faCopy),
		"cut": makeIcon(faScissors),
		"cross": makeIcon(faTimes),
		"csv": makeIcon(faFileCsv),
		"excel": makeIcon(faFileExcel),
		"expanded": makeIcon(faChevronLeft),
		"eye-slash": makeIcon(faEyeSlash),
		"eye": makeIcon(faEye),
		"first": makeIcon(faBackwardFast),
		"grip": makeIcon(faGripVertical),
		"group": makeIcon(faObjectGroup),
		"last": makeIcon(faForwardFast),
		"left": makeIcon(faArrowLeft),
		"linked": makeIcon(faLink),
		"loading": makeIcon(faSpinnerThird),
		"maximize": makeIcon(faArrowUpRightAndArrowDownLeftFromCenter),
		"menu-alt": makeIcon(faEllipsis),
		"minimize": makeIcon(faArrowDownLeftAndArrowUpRightToCenter),
		"minus": makeIcon(faCircleMinus),
		"next": makeIcon(faChevronRight),
		"none": makeIcon(faAnglesUpDown),
		"not-allowed": makeIcon(faBan),
		"paste": makeIcon(faPaste),
		"pin": makeIcon(faThumbTack),
		"pivot": makeIcon(faTablePivot),
		"plus": makeIcon(faCirclePlus),
		"previous": makeIcon(faChevronLeft),
		"right": makeIcon(faArrowRight),
		"save": makeIcon(faDownload),
		"small-down": makeIcon(faAngleDown),
		"small-left": makeIcon(faAngleLeft),
		"small-right": makeIcon(faAngleRight),
		"small-up": makeIcon(faAngleUp),
		"tick": makeIcon(faCheck),
		"tree-indeterminate": makeIcon(faHorizontalRule),
		"unlinked": makeIcon(faLinkHorizontalSlash),
		"up": makeIcon(faArrowUp),
	},
});

// to use myTheme in an application, pass it to the theme grid option
const fleetplanAgGridTheme = themeBalham
	// .withPart(iconSetQuartzLight)
	.withPart(fpIcons)
	// .withPart(fontAwesomeIcons)
	.withParams({
		accentColor: "rgb(51, 122, 183)",
		borderRadius: 0,
		cellTextColor: "#181D1F",
		fontFamily: "inherit",
		fontSize: 12,
		headerBackgroundColor: "#f5f7f7",
		headerFontSize: 12,
		headerHeight: 32,
		iconButtonHoverColor: "#FFFFFF00",
		iconSize: 12,
		inputHeight: 18,
		rowHeight: 28,
		wrapperBorderRadius: 0,
		headerFontWeight: 500,
	});

// console.log(icon(faHelicopter).html);

// Register all enterprise features
ModuleRegistry.registerModules([
	SparklinesModule.with(AgChartsCommunityModule),
	ClientSideRowModelModule,
	AlignedGridsModule,
	RowGroupingModule,
	RowGroupingPanelModule,
	GroupFilterModule,
	TreeDataModule,
	MasterDetailModule,
	ColumnMenuModule,
	ContextMenuModule,
	StatusBarModule,
	ExcelExportModule,
	ClipboardModule,
	DragAndDropModule,
	ValueCacheModule,
	GridStateModule,
	ColumnApiModule,
	RowApiModule,
	ScrollApiModule,
	RenderApiModule,
	EventApiModule,
	CustomEditorModule,
	RowSelectionModule,
	CellSelectionModule,
	// filter
	AdvancedFilterModule,
	ExternalFilterModule,
	QuickFilterModule,
	TextFilterModule,
	NumberFilterModule,
	DateFilterModule,
	SetFilterModule,
	MultiFilterModule,
	CustomFilterModule,
	// cells
	CellStyleModule,
	HighlightChangesModule,
	TooltipModule,
	// rows
	PinnedRowModule,
	RowAutoHeightModule,
	RowStyleModule,
	TextEditorModule,
	NumberEditorModule,
	// PaginationModule
	RowDragModule,
	ColumnHoverModule,
	ColumnAutoSizeModule,
	ValidationModule,
	ClientSideRowModelApiModule,
	CellApiModule,
]);

// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: fleetplanAgGridTheme });

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-055598}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Team_Centric_Software_GmbH_&_Co._KG}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{fleetplan}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{fleetplan}_need_to_be_licensed___{fleetplan}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{26_April_2025}____[v3]_[01]_MTc0NTYyMjAwMDAwMA==848dc10d9bd5d1a79658dcff7cc3eb82");