import { FpApi } from "@tcs-rliess/fp-core";
import { Label, LabelProps } from "@tcs-rliess/fp-web-ui";
import React from "react";

import { useApp } from "../../../FleetplanApp";

export interface SystemCategoryLabelProps extends LabelProps {
	dscatid: number | FpApi.System.SystemCategory;
	hideText?: boolean;
	iconPosition?: "left" | "right";
	categoryDefaults?: Partial<FpApi.System.SystemCategory>;
	useAbbr?: boolean
	fallbackIcon?: string;
	text?: React.ReactText;
	isText?: boolean;
}

export const SystemCategoryLabel: React.FC<SystemCategoryLabelProps > = function SystemCategoryLabel({
	dscatid,
	hideText = false,
	iconPosition = "left",
	categoryDefaults,
	useAbbr,
	text,
	isText,
	...props
}) {
	const app = useApp();
	const [ category, setCategory ] = React.useState<FpApi.System.SystemCategory>(typeof dscatid === "number" ? null : { ...dscatid, ...categoryDefaults });

	React.useEffect(() => {
		// setCategory(null);

		if (dscatid && typeof dscatid === "number") {
			const category = app.store.systemCategory.getId(dscatid);
			setCategory({ ...category, ...categoryDefaults });
		}
	}, [ dscatid ]);

	if (category == null) return null;

	const hasColor = category.color?.length > 0;
	const hasClassname = !!category.classNames;

	// const bgColor = Color(category.color || "#ffffff");
	// const color = bgColor.isLight() ? "#000000" : "#ffffff";
	if (!dscatid) return null;
	if (isText) return <div>
		{((category.icon || props.fallbackIcon) && iconPosition === "left") && <span><i className={`${category.icon || props.fallbackIcon} fa-fw`}/>&nbsp;&nbsp;</span>}
		{!hideText && (useAbbr ? category.abbr : category.name)}
		{((category.icon || props.fallbackIcon) && iconPosition === "right") && <span>&nbsp;&nbsp;<i className={`${category.icon || props.fallbackIcon} fa-fw`}/></span>}
		{text}
	</div>;

	return <Label
		{...props}
		variant={props.variant ? props.variant : hasClassname ? category.classNames : hasColor ? category.color : "info"}
	>
		{((category.icon || props.fallbackIcon) && iconPosition === "left") && <span><i className={`${category.icon || props.fallbackIcon} fa-fw`}/>&nbsp;&nbsp;</span>}
		{!hideText && (useAbbr ? category.abbr : category.name)}
		{((category.icon || props.fallbackIcon) && iconPosition === "right") && <span>&nbsp;&nbsp;<i className={`${category.icon || props.fallbackIcon} fa-fw`}/></span>}
		{text}
	</Label>;
};
