import { CategoryUtil, FpApi } from "@tcs-rliess/fp-core";

import { FleetplanApp } from "../../FleetplanApp";
import { ControlledDocumentState } from "../../modules/ControlledDocument/lib/ControlledDocumentState";
import { GlobalMapStore } from "../../modules/Demo/FPMap/map-state";
import { CrewCheckState } from "../../modules/HumanResources/CrewCheck/lib/CrewCheckBucket";
import { LocationSetupUtil } from "../Resource";
import { SecurityPolicyUtil } from "../Security";
import { StatusPageStore } from "../stores";

import {
	ActiveContractStore, AircraftModelStore, AircraftStateStore, AircraftStore, ArticleStore, BookingItemStore, CalendarStore, CertificateV3Store,
	ContactStateStore,
	ContactStore,
	ContactTargetWorkingTimeCorrectionStore,
	ControlStore,
	ControlledDocumentWorkflowStore,
	CountryStore, DirectoryStore, DocumentDsmidStore, DocumentStore,
	EventStore, ExternalGroupStore,
	HRContractStore,
	HolidaySetStore,
	IdentityProviderStore, LandingFieldStore, NotificationGroupStore,
	PartialQualityEventStore, ProcedureStore, ProcessStore, ProductItemStore, QualityAuditStore, ReportStore, ResourceCheckInStore, ResourceDutyStore,
	ResourceDutyWishStore, ResourceSchedulePublishStore, ResourceScheduleStore, RevisionStore, RiskStore, RouteStore, ScheduleWorkPatternStore,
	SecurityRoleStore, SecurityUserStore, SettingsStore, ShiftStore, ShopProductItemStore, StockLocationStore, SystemCategoryStore, TagStore,
	TaskTemplateStore, UserLogTypeIdNameStore, UserLogTypeStore, WorkflowStore,
} from "./impls";
import { ContactTargetWorkingTimeCorrectionDeltaDeltaStore } from "./impls/ContactTargetWorkingTimeCorrectionDeltaStore";
import { DirectoryReadStore } from "./impls/DirectoryReadStore";
import { ResourceLocationSetupStore } from "./impls/Resource/ResourceLocationSetupStore";
import { ScheduleBalanceStore } from "./impls/Resource/ScheduleBalanceStore/ScheduleBalanceStore";
export class ClientStore {
	private app: FleetplanApp;

	// controlled document
	public readonly controlledDocument: {
		article: ArticleStore;
		document: DocumentStore;
		/** @deprecated use dssid if possible */
		documentDsmid: DocumentDsmidStore;
		workflow: ControlledDocumentWorkflowStore;
		revision: RevisionStore;
	};

	// quality
	public readonly quality: {
		control: ControlStore;
		risk: RiskStore;
		procedure: ProcedureStore;
		process: ProcessStore;
	};

	// resource
	public readonly resource: {
		aircraft: AircraftStore;
		aircraftState: AircraftStateStore;
		aircraftModel: AircraftModelStore;

		/** @deprecated replace by resource schedule */
		duty: ResourceDutyStore;
		dutyWish: ResourceDutyWishStore;
		checkIn: ResourceCheckInStore;
		schedule: ResourceScheduleStore;
		locationSetup: ResourceLocationSetupStore;
		schedulePublish: ResourceSchedulePublishStore;
		scheduleWorkPattern: ScheduleWorkPatternStore;
		shift: ShiftStore;
		userLogType: UserLogTypeStore;
		userLogTypeIdName: UserLogTypeIdNameStore;
		locationSetupUtil: LocationSetupUtil;
		scheduleBalance: ScheduleBalanceStore;
	};

	// security
	public readonly security: {
		externalGroup: ExternalGroupStore;
		identityProvider: IdentityProviderStore;
		role: SecurityRoleStore;
		user: SecurityUserStore;
	};

	// shop
	public readonly shop: {
		productItem: ShopProductItemStore;
	};

	// stock
	public readonly stock: {
		location: StockLocationStore;
	};

	// human resources
	public readonly hr: {
		targetWorkingTimeCorrection: ContactTargetWorkingTimeCorrectionStore;
		targetWorkingTimeCorrectionDelta: ContactTargetWorkingTimeCorrectionDeltaDeltaStore;

	};

	// system
	public readonly notificationGroup: NotificationGroupStore;
	public readonly workflow: WorkflowStore;
	public readonly settingsUser: SettingsStore;
	public readonly settingsProject: SettingsStore;
	public readonly systemCategory: SystemCategoryStore;
	public readonly categoryUtil: CategoryUtil;
	public readonly tags: TagStore;
	public readonly taskTemplate: TaskTemplateStore;
	public readonly calendar: CalendarStore;
	public readonly fpMap: GlobalMapStore;

	// security
	public securityPolicyUtil: SecurityPolicyUtil;

	// directory
	public readonly fpDir: {
		[Prop in keyof Omit<FpApi.Customer.Customer["fpdir"], "directory">]: DirectoryStore<Prop>;
	} & {
		directory: DirectoryReadStore;
	};

	// TODO: unsorted
	public readonly crewCheck: CrewCheckState;
	public readonly contact: ContactStore;
	public readonly contactState: ContactStateStore;
	public readonly controlleddocument: ControlledDocumentState;
	public readonly landingField: LandingFieldStore;
	public readonly routes: RouteStore;
	public readonly certificateV3Store: CertificateV3Store;
	public readonly productItemStore: ProductItemStore;
	public readonly hrContractStore: HRContractStore;
	public readonly activeContractStore: ActiveContractStore;
	public readonly qualityReport: ReportStore;
	public readonly partialQualityEvent: PartialQualityEventStore;
	public readonly qualityAudit: QualityAuditStore;
	public readonly countryStore: CountryStore;
	public readonly event: EventStore;
	public readonly holidaySet: HolidaySetStore;
	public readonly bookingItem: BookingItemStore;
	public readonly statusPage: StatusPageStore;

	constructor(app: FleetplanApp) {
		this.app = app;

		// controlled document
		this.controlledDocument = {
			article: new ArticleStore(this.app),
			document: new DocumentStore(this.app),
			documentDsmid: new DocumentDsmidStore(this.app),
			workflow: new ControlledDocumentWorkflowStore(this.app),
			revision: new RevisionStore(this.app),
		};

		// quality safety
		this.quality = {
			control: new ControlStore(this.app),
			risk: new RiskStore(this.app),
			procedure: new ProcedureStore(this.app),
			process: new ProcessStore(this.app),
		};

		// resource
		this.resource = {
			aircraft: new AircraftStore(this.app),
			aircraftState: new AircraftStateStore(this.app),
			aircraftModel: new AircraftModelStore(this.app),
			locationSetup: new ResourceLocationSetupStore(this.app),
			locationSetupUtil: new LocationSetupUtil(this.app),
			duty: new ResourceDutyStore(this.app),
			dutyWish: new ResourceDutyWishStore(this.app),
			checkIn: new ResourceCheckInStore(this.app),
			schedule: new ResourceScheduleStore(this.app),
			schedulePublish: new ResourceSchedulePublishStore(this.app),
			scheduleWorkPattern: new ScheduleWorkPatternStore(this.app),
			shift: new ShiftStore(this.app),
			userLogType: new UserLogTypeStore(this.app),
			userLogTypeIdName: new UserLogTypeIdNameStore(this.app),
			scheduleBalance: new ScheduleBalanceStore(this.app),
		};

		// security
		this.security = {
			externalGroup: new ExternalGroupStore(this.app),
			identityProvider: new IdentityProviderStore(this.app),
			role: new SecurityRoleStore(this.app),
			user: new SecurityUserStore(this.app),
		};

		// shop
		this.shop = {
			productItem: new ShopProductItemStore(this.app),
		};

		// stock
		this.stock = {
			location: new StockLocationStore(this.app),
		};

		// system
		this.notificationGroup = new NotificationGroupStore(this.app);
		this.workflow = new WorkflowStore(this.app);
		this.tags = new TagStore(this.app);
		this.systemCategory = new SystemCategoryStore(this.app);
		this.calendar = new CalendarStore(this.app);
		this.settingsProject = new SettingsStore(app.ctx, {
			linkIdType: "dscid",
			linkId: app.ctx.dscid?.toString(),
			level: 1,
		});
		this.settingsUser = new SettingsStore(app.ctx, {
			linkIdType: "dsuid",
			linkId: app.ctx.dsuid?.toString() ?? "0",
			level: 2,
		});
		this.categoryUtil = new CategoryUtil({
			dirClient: this.app.fpDirClient,
			dscid: this.app.dscid,
			dirId: this.app.customer?.fpdir.category,
		});
		this.taskTemplate = new TaskTemplateStore(this.app);
		this.fpMap = new GlobalMapStore();

		// directory
		this.fpDir = {
			category: new DirectoryStore(this.app, "category"),
			certificate: new DirectoryStore(this.app, "certificate"),
			directory: new DirectoryReadStore(this.app, "directory"),
			document: new DirectoryStore(this.app, "document"),
			hazard: new DirectoryStore(this.app, "hazard"),
		};

		// security
		this.securityPolicyUtil = new SecurityPolicyUtil(this.app);

		// TODO: unsorted
		this.hr = {
			targetWorkingTimeCorrection: new ContactTargetWorkingTimeCorrectionStore(this.app),
			targetWorkingTimeCorrectionDelta: new ContactTargetWorkingTimeCorrectionDeltaDeltaStore(this.app),
		};

		this.contact = new ContactStore(this.app);
		this.contactState = new ContactStateStore(this.app);
		this.controlleddocument = new ControlledDocumentState(this.app);
		this.landingField = new LandingFieldStore(this.app);
		this.routes = new RouteStore(this.app);
		this.certificateV3Store = new CertificateV3Store(this.app);
		this.productItemStore = new ProductItemStore(this.app);
		this.hrContractStore = new HRContractStore(this.app);
		this.activeContractStore = new ActiveContractStore(this.app);
		this.qualityReport = new ReportStore(this.app);
		this.partialQualityEvent = new PartialQualityEventStore(this.app);
		this.qualityAudit = new QualityAuditStore(this.app);
		this.countryStore = new CountryStore();
		this.event = new EventStore(this.app);
		this.holidaySet = new HolidaySetStore(this.app);
		this.bookingItem = new BookingItemStore(this.app);
		this.statusPage = new StatusPageStore();
		this.crewCheck = new CrewCheckState(this.app);
	}
}
