import { Format } from "@tcs-rliess/fp-web-ui";
import { DateTime } from "luxon";
import React from "react";
import styled from "styled-components";
import { usePrintContext } from "./PrintContext";
const Footer = styled.footer `
	position: running(footerIdentifier);
	
	font-size: .8rem;
	display: flex;
	flex-direction: row;
	border-top: 1px solid black;

	& > div {
		flex: 1;
		padding: .5em;
	}
	& > div:nth-child(1) {
		text-align: left;
	}
	& > div:nth-child(2) {
		text-align: center;
	}
	& > div:nth-child(3) {
		text-align: right;
	}

	.substitution-page:after { content: counter(page); }
	.substitution-pages:after { content: counter(pages); }
`;
export const DefaultFooter = function DefaultFooter() {
    const { brand, contact, job, title } = usePrintContext();
    let displayName = contact.$person?.abbreviation;
    if (displayName?.trim().length === 0) {
        displayName = `${contact.$person?.givenName} ${contact.$person?.lastName}`;
    }
    return React.createElement(Footer, null,
        React.createElement("div", null,
            brand.name,
            " \u00A9 ",
            DateTime.utc().year),
        React.createElement("div", null, title),
        React.createElement("div", null,
            React.createElement("div", { style: { float: "right", marginLeft: ".5em" } },
                React.createElement("img", { src: brand.image.iconImg200, style: { maxWidth: ".65cm" } })),
            React.createElement("div", { style: { float: "right" } },
                React.createElement(Format.DateTime, null, job.rlog?.c),
                " by ",
                displayName,
                React.createElement("br", null),
                "Page ",
                React.createElement("span", { className: "substitution-page" }),
                " / ",
                React.createElement("span", { className: "substitution-pages" }))));
};
