import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ControlledDocumentUtil, FpApi } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import { CommandBar, DockedPanel, Flex, FlexItem, Skeleton, Text, lazyComponent, useDockedManager } from "@tcs-rliess/fp-web-ui";
import { toNumber } from "lodash-es";
import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";

import { FleetplanApp, useApp } from "../../../FleetplanApp";
import { ArticleData, RevisionDataManager } from "../../../lib";
import { useDocumentState } from "../../ControlledDocument/lib/ControlledDocumentHook";

const ControlledDocumentArticle = lazyComponent(() => import("../../ControlledDocument/chunk"), m => m.ControlledDocumentArticle);

interface HistoryItem {
	dssid?: number;
	dsaid: number;
}

class FpLinkArticleState {
	@observable private byDssid: Map<number, RevisionDataManager> = new Map();
	@observable.ref public couldNotLoadDocument: Array<number> = [];
	@observable public history: Array<HistoryItem> = [];

	@computed public get activeItem(): ArticleData { return this.mapItem(this.history[0]); }

	constructor(private app: FleetplanApp) {  }

	private getDocument(item: HistoryItem): RevisionDataManager {
		// check if we have it
		if (this.byDssid.has(item.dssid)) return this.byDssid.get(item.dssid);

		// create new manager and load
		const revisionData = RevisionDataManager.forOptions(this.app, { dssid: item.dssid });
		this.byDssid.set(item.dssid, revisionData);

		revisionData.load().then(() => {
			this.byDssid.set(revisionData.document.dssid, revisionData);
		}).catch((err) => {
			fpLog.error(err);
			this.couldNotLoadDocument = [ ...this.couldNotLoadDocument, item.dssid ];
		});

		return revisionData;
	}

	public mapItem(item: HistoryItem): ArticleData {
		if (item == null) return null;
		const revisionData = this.getDocument(item);
		return revisionData?.getDsaid(item.dsaid);
	}

	@action
	public openArticle(item: HistoryItem): void {
		const index = this.history.findIndex(e => e.dsaid === item.dsaid);
		if (index !== -1) {
			this.history.splice(index, 1);
		}
		this.history.unshift(item);
	}

	@action
	public removeFromHistory(dsaid: number): void {
		this.history = this.history.filter(item => item.dsaid !== dsaid);
	}
}

const FpLinkArticleStateCtx = React.createContext<FpLinkArticleState>(null);

const HistoryItem = styled(Flex)`
	cursor: pointer;
	&:hover {
		background-color: #f0f8ff;
	}
	padding: 8px;
`;

export const FpArticleBottom: React.FC<{
	children?: React.ReactNode;
}> = observer((props) => {
	const app = useApp();
	const manager = useDockedManager();
	// figure out ctx
	const parentState = React.useContext(FpLinkArticleStateCtx);
	const [ state ] = React.useState(() => new FpLinkArticleState(app));

	const sym = React.useMemo(() => Symbol(), []);

	React.useEffect(() => {
		if (state?.history.length) {
			manager.openPanel(sym);
		}
	}, [ state?.history.length ]);

	// already inside a <FpArticleBottom/>
	if (parentState) return <>{props.children}</>;

	return <FpLinkArticleStateCtx.Provider value={state}>
		{state.history.length > 0 && (
			<DockedPanel
				panelKey={sym}
				initialPosition="bottom"
				openOnMount
				label="Controlled Documents"
			>
				<DockedPanel.Header style={{ backgroundColor: "#f0f8ff", width: "100%" }}>
					<CommandBar.Right>
						<DockedPanel.Title>Controlled Documents</DockedPanel.Title>
					</CommandBar.Right>
				</DockedPanel.Header>
				<DockedPanel.Body>
					<Flex style={{ height: "100%" }}>
						<FlexItem grow style={{ overflow: "auto", padding: "0px 8px" }}>
							<React.Suspense fallback={null}>
								{state.activeItem && <ControlledDocumentArticle articleData={state.activeItem}/>}
							</React.Suspense>
						</FlexItem>
						<FlexItem shrink style={{ maxWidth: 300, minWidth: 300, borderLeft: "1px solid #ccc", overflowY: "auto", height: "100%" }}>
							{state.history.map(item => {
								const isActive = state.history[0].dsaid === item.dsaid;
								const articleData = state.mapItem(item);

								return <HistoryItem
									key={item.dsaid}
									style={{
										backgroundColor: isActive ? app.theme.color.info[100].main : undefined,
									}}
									onClick={() => {
										void state.openArticle({ dssid: item.dssid, dsaid: item.dsaid });
									}}
								>
									<FlexItem grow>
										{articleData == null ? (
											<>{state.couldNotLoadDocument.includes(item.dssid) ?
												<Text muted italic>Could not load Document</Text>
												:
												<Skeleton.Line width={250}/>}</>
										) : <>
											{ControlledDocumentUtil.isNumberingActive(articleData.manager.revision.settings) ? (
												<span style={{ display: "inline-block", minWidth: 70 }}>
													{ControlledDocumentUtil.formatNumbering(articleData.article, articleData.manager.revision.settings)}
												&nbsp;
												</span>
											) : null}
											<span dangerouslySetInnerHTML={{ __html: articleData.article.title }}/>
										</>}
									</FlexItem>
									<FlexItem shrink onClick={(e) => {
										e.stopPropagation();
										state.removeFromHistory(item.dsaid);
									}}>
										<FontAwesomeIcon className="pull-right" fixedWidth icon={faTimes} color={app.theme.color.danger[500].main}/>
									</FlexItem>
								</HistoryItem>;
							})}
						</FlexItem>
					</Flex>
				</DockedPanel.Body>
			</DockedPanel>
		)}
		{props.children}
	</FpLinkArticleStateCtx.Provider>;
});

interface FpLinkArticleProps {
	dsaid: number;
	dssid: number;
	component?: React.ComponentType<{
		onClick?: () => void;
		children?: React.ReactNode;
	}>;
	onLoadArticle?: (article: FpApi.ControlledDocument.Article) => void;
	children?: React.ReactNode;
}

export const FpLinkArticle: React.FC<FpLinkArticleProps> = function FpLinkArticle(props) {
	const ctx = React.useContext(FpLinkArticleStateCtx);

	// check permission
	const documentState = useDocumentState();
	const hasPermission = documentState.isAllowed(toNumber(props.dssid), "reader");

	// only show label if no permission
	if (hasPermission === false) {
		return props.children;
	}

	if (props.component) {
		return <props.component
			onClick={() => {
				void ctx?.openArticle({ dssid: props.dssid, dsaid: props.dsaid });
			}}
		>
			{props.children}
		</props.component>;
	} else {
		return <a
			href="javascript:;"
			onClick={() => {
				ctx?.openArticle({ dssid: props.dssid, dsaid: props.dsaid });
			}}
		>{props.children}</a>;
	}
};
