import { ClientCategoryUtil, EventUtil as EventUtilCore, FpApi, FPEvent, FPEventResource, FPEventWaypoint, FPFlightSearchResult, TimeSpan } from "@tcs-rliess/fp-core";
import { cloneDeep } from "lodash-es";
import { DateTime, Duration } from "luxon";

import { FleetplanApp } from "../FleetplanApp";

export interface WaypointPayload {
	mass: Record<"crew" | "pax" | "cargo" | "total" | "max", number>;
	amount: Partial<Record<"crew" | "pax" | "cargo" | "total" | "cargo_freight" | "cargo_mail", number>>;
	capacity: Record<"crew" | "pax" | "total", number>;
}

export interface SearchFlightParams {
	dateEarliest: string;
	dateLatest: string;
	maxStopDuration?: string;
	from: number;
	to: number;
	maxStops?: number;
	dscatidMission?: number;
	fpvid?: number;

	// optional extra check when from or/and to is a coordinate or text input
	// so filter should only match the date and aircraft
	noFromToCheck?: boolean;
}

interface PayloadResource extends FPEventResource {
	stops: string[]; // array of waypoint ids where this resource flies through
}

interface SearchParams {
	events: FPEvent[];
	containers: FPEvent[];
	startTimeSpan: TimeSpan;
	endTimeSpan: TimeSpan;

	from: number;
	to: number;
	// dateEarliest: string;
	// dateLatest: string;
	maxStops: number;
	maxStopDuration: Duration;
}

export class EventUtil extends EventUtilCore {
	public static async getPayload(params: {
		app: FleetplanApp;
		/** with with: type = flight */
		event: FPEvent;
		fromWaypoint: FPEventWaypoint;
		toWaypoint: FPEventWaypoint;
		calculatePeak?: boolean;
	}): Promise<WaypointPayload> {
		const { app, event, fromWaypoint, toWaypoint, calculatePeak } = params;
		const resources = cloneDeep(event.resources); // clone them in case we modify them

		// resolve booking items - needed for pax bagWeight calculation
		const bookingItemIds = event.resources.filter(r => r.link_type === "dsbiid").map(r => r.link_id);
		let bookingItems = new Map<string, FpApi.Booking.BookingItem>();
		if (bookingItemIds.length) {
			bookingItems = new Map((await app.store.bookingItem.getIdList(bookingItemIds)).map(bi => [ bi.id, bi ]));
		}

		const flightWaypointsIds = event.waypoints.map(wp => wp.id);

		const payload: WaypointPayload = {
			mass: { crew: 0, pax: 0, cargo: 0, total: 0, max: 0 },
			amount: { crew: 0, pax: 0, cargo: 0, cargo_freight: 0, cargo_mail: 0, total: 0 },
			capacity: { crew: 0, pax: 0, total: 0 },
		};
		if (event.flight == null || event.waypoints == null) return payload;

		// find aircraft to calculate capacity
		const aircraftResource = resources.find(r => r.link_type === "fpvid");
		let aircraft: FpApi.Resource.Aircraft;
		if (aircraftResource?.link_id != null) {
			aircraft = app.store.resource.aircraft.getId(parseInt(aircraftResource.link_id));

			if (aircraft) {
				payload.capacity.crew = aircraft.capacityPersonMax - aircraft.capacityPersonPax;
				payload.capacity.pax = fromWaypoint?.available_seats ?? aircraft.capacityPersonPax;
				payload.capacity.total = payload.capacity.pax + payload.capacity.crew;
			}
		}

		const crew: PayloadResource[] = [];
		const pax: PayloadResource[] = [];
		const cargo: PayloadResource[] = [];

		// pax cargo is the bagweight of pax items, which is then added to cargo total
		const paxCargo: number[] = [];

		// case a: calculate peak payload and lowest capacity during given waypoints
		// case b: calculate payload for given waypoints;
		if (calculatePeak) {
			// loop over selected waypoints to get the highest(mass) and lowest(capacity, amount) values
			const wps = event.waypoints.slice(flightWaypointsIds.indexOf(fromWaypoint.id), flightWaypointsIds.indexOf(toWaypoint.id) + 1);
			for (let i = 0; i < wps.length - 1; i++) {
				const wpPayload = await this.getPayload({
					app,
					event,
					fromWaypoint: wps[i],
					toWaypoint: wps[i + 1],
				});

				payload.amount.crew = Math.max(payload.amount.crew, wpPayload.amount.crew);
				payload.capacity.crew = Math.min(payload.capacity.crew, wpPayload.capacity.crew);
				payload.mass.crew = Math.max(payload.mass.crew, wpPayload.mass.crew);

				payload.mass.cargo = Math.max(payload.mass.cargo, wpPayload.mass.cargo);
				payload.amount.cargo = Math.max(payload.amount.cargo, wpPayload.amount.cargo);

				const seatsLeft = wpPayload.capacity.pax - wpPayload.amount.pax;
				if (seatsLeft < payload.capacity.pax - payload.amount.pax) {
					payload.amount.pax = wpPayload.amount.pax;
					payload.capacity.pax = wpPayload.capacity.pax;
				}
				payload.mass.pax = Math.max(payload.mass.pax, wpPayload.mass.pax);

				payload.amount.total = Math.max(payload.amount.total, wpPayload.amount.total);
				payload.mass.total = Math.max(payload.mass.total, wpPayload.mass.total);

				payload.mass.max = !payload.mass.max ? wpPayload.mass.max : Math.min(payload.mass.max, wpPayload.mass.max);

				payload.capacity.total = Math.min(payload.capacity.total, wpPayload.capacity.total);
			}
		} else {
			for (const resource of resources) {
				const fromIndex: number = flightWaypointsIds.findIndex(w => w === resource.dsefwpid_from);
				const toIndex: number = flightWaypointsIds.findIndex(w => w === resource.dsefwpid_to);

				if (fromIndex === -1 || toIndex === -1) {
					if (resource.inherit_route) {
						if (resource.link_type === "dscaid") {
						// do not count crew for drones
							if (!aircraft || aircraft.subType !== FpApi.Resource.AircraftType.UAS) crew.push({ ...resource, stops: flightWaypointsIds });
							continue;
						}
					}
					// couldn't find route
					continue;
				}

				const flightStops = flightWaypointsIds.slice(fromIndex, (toIndex + 1));
				// check if resource is on the route we're calculating payload for
				if (flightStops.includes(fromWaypoint.id) && flightStops.includes(toWaypoint.id)) {
					const role = ClientCategoryUtil.byEnum(FpApi.Calendar.Event.EventResourceRole).getValue(resource.dserrid);
					if (role == null) continue;

					// count pax bagWeight seperately
					let bi: FpApi.Booking.BookingItem;
					if ([ FpApi.Calendar.Event.EventResourceRole.BookingItemPax, FpApi.Calendar.Event.EventResourceRole.BookingItemCargo ].includes(role)) {
						bi = bookingItems?.get(resource.link_id);
						if (bi && bi.pax && bi.pax.bagWeight) {
							paxCargo.push(bi.pax.bagWeight);
						}
					}

					switch (role) {
						case FpApi.Calendar.Event.EventResourceRole.Aircraft:
							break;
						case FpApi.Calendar.Event.EventResourceRole.BookingItemCargo:
							cargo.push({ ...resource, stops: flightStops });

							if (bi.subType === FpApi.Booking.BookingItemSubType.Freight) payload.amount.cargo_freight++;
							if (bi.subType === FpApi.Booking.BookingItemSubType.Mail) payload.amount.cargo_mail++;
							break;
						case FpApi.Calendar.Event.EventResourceRole.BookingItemPax:
							pax.push({ ...resource, stops: flightStops });
							break;
						default:
							break;
					}
				}
			}

			payload.mass.crew = crew.reduce((mass, r) => mass + (r.weight ?? 0), 0);
			payload.mass.pax = pax.reduce((mass, r) => mass + (r.weight ?? 0), 0) + paxCargo.reduce((partialSum, a) => partialSum + a, 0);
			payload.mass.cargo = cargo.reduce((mass, r) => mass + (r.weight ?? 0), 0);

			// 2025-01-15 - [AP, DL] the crew weight is now considered below in the max payload calculation
			payload.mass.total = /*payload.mass.crew*/ payload.mass.pax + payload.mass.cargo;

			// this can be only calculated if we have an aircraft, scheduling calendars don't have these values
			if (aircraft) payload.mass.max = fromWaypoint.allowed_payload_kg ?? aircraft.technicalMaxZeroFuelMass - (aircraft.technicalBasicEmptyMass + payload.mass.crew);
			else payload.mass.max = fromWaypoint.allowed_payload_kg ?? 0;

			payload.amount.crew = crew.length;
			payload.amount.pax = pax.length;
			payload.amount.cargo = cargo.length;
			payload.amount.total = payload.amount.crew + payload.amount.pax + payload.amount.cargo;
		}

		return payload;
	}


	public async searchFlight(app: FleetplanApp, params: SearchFlightParams): Promise<FPFlightSearchResult[]> {
		params = {
			maxStops: 1,
			maxStopDuration: Duration.fromObject({ hours: 2 }).toISO(),
			...params,
		};
		const dateEarliest = DateTime.fromISO(params.dateEarliest);
		const dateLatest = DateTime.fromISO(params.dateLatest);
		const maxStopDuration = Duration.fromISO(params.maxStopDuration);

		let events = await app.store.event.getRange(dateEarliest, dateLatest);
		if (params.dscatidMission) events = events.filter(e => e.type === FpApi.Calendar.Event.EventType.Container || (e.type === FpApi.Calendar.Event.EventType.Flight && e.dscatid_category === params.dscatidMission));
		if (params.fpvid) events = events.filter(e => e.resources.some(r => r.link_type === "fpvid" && r.link_id === params.fpvid.toString()));

		let results: FPFlightSearchResult[] = [];
		const flights = events.filter(e => e.type === FpApi.Calendar.Event.EventType.Flight);
		const container = events.filter(e => e.type === FpApi.Calendar.Event.EventType.Container);

		/* 2024-12-12 - [VT]
			Optional / additional check when DEP and/or DES is a coordinate or text, OR when we have toggled the option to search for all flights of this aircraft on a specific date.
			This filter will only match the date and aircraft, providing the relevant results.
		*/
		if (params.noFromToCheck) {
			for (const flight of flights) {
				if (!flight.waypoints?.length) continue;
				for (let i = 0; i < flight.waypoints.length - 1; i++) {
					results.push({
						events: [{
							children: [ flight ],
							container: container.find(e => e.id.includes(flight.id)),
							flight: flight.id,
							fromWaypoint: flight.waypoints[i].id,
							toWaypoint: flight.waypoints[i+1].id,
						}],
					});
				}
			}
		} else {
			results = this.internalSearch({
				events: flights,
				containers: container,
				from: params.from,
				to: params.to,
				maxStopDuration: maxStopDuration,
				maxStops: params.maxStops,

				startTimeSpan: new TimeSpan(dateEarliest, dateLatest),
				endTimeSpan: new TimeSpan(dateEarliest, dateLatest),
			});
		}

		return results;
	}

	public mapSingleEvent(event: FPEvent, containerEvent: FPEvent, params: SearchFlightParams) {
		const results = [];

		let fromWaypoint: string;
		for (let i = 0; i < event.waypoints.length; i++) {
			const waypoint = event.waypoints[i];
			const nextWaypoint = event.waypoints[i + 1];

			// stop searching if maxstops are exceeded
			if (i > params.maxStops) break;

			// set the new 'from' until we find a matching 'to'
			if (waypoint.fplaid === params.from) fromWaypoint = waypoint.id;

			// if 'from' is set and we have a next waypoint (aka destination), check if it matches with our params
			if (fromWaypoint && nextWaypoint) {
				if (nextWaypoint.fplaid === params.to) {
					results.push({
						events: [{
							children: [ event ],
							container: [ containerEvent ],
							flight: event.id,
							fromWaypoint: fromWaypoint,
							toWaypoint: nextWaypoint.id,
						}],
					});
					// clear the 'from' as it's being used now
					fromWaypoint = null;
				}
			}
		}

		return results;
	}

	private internalSearch(params: SearchParams): FPFlightSearchResult[] {
		const results: FPFlightSearchResult[] = [];

		for (const event of params.events) {
			// 2024-12-11 - [AP & VT] Aborted events must be linked to aircraft logbook as well
			if ([ /* FpApi.Calendar.Event.EventStatus.Aborted, */ FpApi.Calendar.Event.EventStatus.Completed, FpApi.Calendar.Event.EventStatus.Cancelled ].includes(event.status)) continue;
			if (event.type !== FpApi.Calendar.Event.EventType.Flight) continue;

			const fromIndices = this.findIndices(event, params.from);
			for (const fromIndex of fromIndices) {
				const fromWaypoint = event.waypoints[fromIndex];

				const std = DateTime.fromISO(fromWaypoint.std);
				if (params.startTimeSpan.includes(std) === false) {
					// departure not within search parameters
					continue;
				}

				for (let toIndex = fromIndex + 1; toIndex < event.waypoints.length; toIndex++) {
					const toWaypoint = event.waypoints[toIndex];

					const sta = DateTime.fromISO(toWaypoint.sta);
					if (params.endTimeSpan.includes(sta) === false) {
						// arrival not within search parameters
						continue;
					}

					if (params.to == toWaypoint.fplaid) {
						// direct connection
						results.push({
							events: [{
								children: params.events,
								container: params.containers.find(e => e.id.includes(event.id)),
								flight: event.id,
								fromWaypoint: fromWaypoint.id,
								toWaypoint: toWaypoint.id,
							}],
							//waypoints: event.flight.waypoints.slice(fromIndex, toIndex + 1),
						});
					} else if (params.maxStops > 0) {
						// look for connection
						const connectResults = this.internalSearch({
							events: params.events,
							containers: params.containers,
							from: toWaypoint.fplaid,
							to: params.to,
							// next flight must depart within the `maxStopDuration`
							startTimeSpan: new TimeSpan(sta, sta.plus(params.maxStopDuration)),
							// can end whenever
							endTimeSpan: new TimeSpan(sta, params.endTimeSpan.end),
							maxStops: params.maxStops - 1,
							maxStopDuration: params.maxStopDuration,
						});

						for (const connectResult of connectResults) {
							results.push(connectResult);
						}
					}
				}
			}
		}

		return results;
	}

	/**
	 * Search for waypoints with the given fplaid, return their indices.
	 */
	private findIndices(event: FPEvent, fplaid: number): number[] {
		const leftOver = event.waypoints;

		const indices: number[] = [];
		for (let i = 0; i < leftOver?.length; i++) {
			const waypoint = leftOver[i];

			if (fplaid == waypoint.fplaid) {
				indices.push(i);
			}
		}

		return indices;
	}
}

export const eventUtil = new EventUtil();
