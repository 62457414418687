import { MatrixAxis } from "./RiskMatrix";
export class RiskMatrixUtil {
    static fromTemplate(template) {
        if (!template.matrix)
            return null;
        const matrix = new RiskMatrixUtil();
        matrix.impact = template.impact;
        matrix.likelihood = template.likelihood;
        matrix.schema = template.schema;
        matrix.matrix = template.matrix;
        const cellMap = new Map();
        template.matrix.forEach(e => {
            if (cellMap.has(e.x)) {
                cellMap.get(e.x).set(e.y, e);
            }
            else {
                cellMap.set(e.x, new Map());
                cellMap.get(e.x).set(e.y, e);
            }
        });
        matrix.cells = cellMap;
        matrix.outputTemplate = template.outputTemplate;
        matrix.calculation = template.calculation;
        return matrix;
    }
    static isValid(obj) {
        if (obj == null)
            return false;
        if (typeof obj !== "object")
            return false;
        if (Object.keys(obj).length === 0)
            return false;
        try {
            RiskMatrixUtil.fromTemplate(obj);
            return true;
        }
        catch (err) {
            return false;
        }
    }
    getCell(x, y) {
        return this.cells.get(x).get(y);
    }
    getAxisValue(axis, value) {
        const realAxis = this.impact.pos === axis ? this.impact : this.likelihood;
        return realAxis.level[value];
    }
    getSchema(id) {
        return this.schema.find(s => s.id === id);
    }
    score(x, y) {
        if (x == null || y == null)
            return { color: "black", label: "Not defined" };
        const cell = this.getCell(x, y);
        const schema = this.getSchema(cell.schemaId);
        const xAxisValue = this.getAxisValue(MatrixAxis.X, x);
        const yAxisValue = this.getAxisValue(MatrixAxis.Y, y);
        const score = this.outputTemplate
            .replace("$yLabel", `${yAxisValue?.name ?? y ?? ""}`)
            .replace("$xLabel", `${xAxisValue?.name ?? x ?? ""}`)
            .replace("$name", schema?.name)
            .replace("$value", (() => {
            switch (this.calculation) {
                case "computed": return `${((xAxisValue?.value ?? x ?? 0) * (yAxisValue?.value ?? y ?? 0)) || "0"}`;
                case "custom": return `${cell?.value}` || "-1";
            }
        })())
            .replace("$customValue", `${cell?.value || ((xAxisValue?.value ?? x ?? 0) * (yAxisValue?.value ?? y ?? 0)) || ""}`)
            .replace("$x", `${x ?? ""}`)
            .replace("$y", `${y ?? ""}`);
        return {
            color: schema?.color ?? "black",
            label: score
        };
    }
    get xAxis() {
        return this.impact.pos === MatrixAxis.X ? this.impact : this.likelihood;
    }
    get yAxis() {
        return this.impact.pos === MatrixAxis.Y ? this.impact : this.likelihood;
    }
    getImpactLabel([x, y]) {
        const impact = this.xAxis === this.impact ? x : y;
        return this.getAxisValue(this.impact.pos, impact).name;
    }
    getLikelyHoodLabel([x, y]) {
        const likelihood = this.yAxis === this.likelihood ? y : x;
        return this.getAxisValue(this.likelihood.pos, likelihood).name;
    }
}
