import { faCertificate } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CertificateStatus, FpDirClient, FpDirClientState, FpDirClientStateCertificate } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import React from "react";

import { BaseStoreSingle } from "../BaseStoreSingle";

import { CertificateV3Store } from "./CertificateV3Store";


type CertificateValidity = (Omit<FpDirClientStateCertificate, "properties"> & FpDirClientStateCertificate["properties"]) & {
	isValid: boolean;
	isExpired: boolean;
	isInGrace: boolean;
	isInRenewal: boolean;
	isDue: boolean;
};

export class ContactStateStore extends BaseStoreSingle<FpDirClientState, number> {
	private dirClient = new FpDirClient({ use: "ContactStateStore" })

	protected itemId(item: FpDirClientState): number {
		return item.id;
	}

	protected async fetchIdList(idList: number[]): Promise<FpDirClientState[]> {
		const filteredIdList = this.app.store.contact.getIds(idList).map(e => e.id);
		if (filteredIdList.length) {
			if(filteredIdList.length > 200) {
				fpLog.warn("Too many ids. Max limit is 200, only loading first 200");
			}
			const result = await this.dirClient.getContactState(this.app.dscid, filteredIdList.slice(0, 200));
			return result.rows;
		}

		return [];
	}

	public getStats(state: FpDirClientState): ReturnType<CertificateV3Store["certificatesToStats"]> {
		return this.app.store.certificateV3Store.certificatesToStats(this.getValidity(state));
	}

	/**
	 * 
	 * @param state 
	 * @param date date in ms
	 */
	public getValidity(state: FpDirClientState, date?: number): Array<CertificateValidity> {
		if(state.certificates == null || !Array.isArray(state.certificates.certs)) return [];
		return state.certificates.certs.filter(c => c.properties.isRequired).map((cert) => {
			return { ...cert.properties, ...this.app.store.certificateV3Store.getTotalsFromStateCertificate(cert as unknown as any, date) };
		});
	}

	public getValidityVariant(state: FpDirClientState, date?: number) {
		if(!state?.certificates?.certs?.length) return "cancelled";
		const certs = this.getValidity(state, date);
		// if(state.id === 5045036) debugger;
		if(certs.some(c => c.isExpired)) return "danger";
		if(certs.some(c => c.isInRenewal)) return "warning";
		return "success";
	}

	public isAnyRequested(state: FpDirClientState, date?: number) {
		return this.getValidity(state, date).some(c => c.dscatidStatus === CertificateStatus.Requested);
	}

	public renderValidity(state: FpDirClientState, date?: number, hideOnNone = false) {
		const variant = this.getValidityVariant(state, date);

		if (variant === "cancelled" && hideOnNone == false) {
			return <>
				<FontAwesomeIcon style={{ visibility: "hidden" }} fixedWidth icon={faCertificate} color={this.app.theme.color.info[500].main}/>
				{<sup title="" style={{ color: this.app.theme.color.purple[500].main, visibility: "hidden", top: -3 }}>R</sup>}
			</>;
		}
		if(variant === "cancelled") return null;

		const isAnyRequested = this.isAnyRequested(state);
		return <>
			<FontAwesomeIcon fixedWidth icon={faCertificate} color={this.app.theme.color[variant][500].main}/>
			{<sup title="There are requested certificates" style={{ color: this.app.theme.color.purple[500].main, visibility: isAnyRequested ? undefined : "hidden", cursor: "help", top: -3 }}>R</sup>}
		</>;
	}
}
