import { faBug, faMessageQuestion } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiError } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import { CommandBar, lazyComponent } from "@tcs-rliess/fp-web-ui";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";

import { ErrorBoundary, FpProvider } from "./common";
import { ToastMessageParams } from "./lib";
import { FP_APP } from "./main";

const ErrorReportDialog = lazyComponent(() => import("./ErrorReportDialog"), m => m.ErrorReportDialog);

export enum ReportEventKind {
	Navigation = "NAVIGATION",
	MQTT = "MQTT",
	Fetch = "FETCH",
	Error = "ERROR",
}

export interface ErrorReport {
	/** iso8601 */
	date: string;
	dscid: number;
	dscaid: number;
	/** given / family name */
	$dscaid: string;
	userAgent: string;
	tz: string;
	url: string;
	buildInfo: {
		time: string;
		branch: string;
		commit: string;
		teamcityBuild: number;
		teamcityAgentName: string;
	};
	errorEvents: ReportEvent[];
}

export interface ReportEvent {
	kind: ReportEventKind;
	/** time of the event */
	time: string;
	/** location href at the time of the event */
	url: string;

	apiError?: ApiError;
	mqtt?: {
		topic: string;
		payload?: string;
	};
	fetch?: {
		method: string;
		url: string | RequestInfo | URL;
		time?: number;
		status?: number;
	};
}
const reportEvents = observable.box<ReportEvent[]>([], { deep: false });

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
// add report event
// -------------------------------------------------------------------------------------------------------------------------------------------------------------

export const addReportEvent = (event: Omit<ReportEvent, "time" | "url">): ReportEvent => {
	const current = reportEvents.get();

	const item: ReportEvent = {
		...event,
		time: new Date().toISOString(),
		url: document.location.href,
	};
	current.push(item);

	// only keep the last 100 events
	if (current.length > 100) {
		current.splice(0, current.length - 100);
	}

	return item;
};

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
// track navigation
// -------------------------------------------------------------------------------------------------------------------------------------------------------------

let previousHref = document.location.href;
window.addEventListener("load", () => {
	const observer = new MutationObserver(() => {
		if (previousHref != document.location.href) {
			previousHref = document.location.href;

			addReportEvent({ kind: ReportEventKind.Navigation });
		}
	});

	observer.observe(document.querySelector("body"), {
		childList: true,
		subtree: true
	});
}, { once: true });

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
// unhandled promise rejection
// -------------------------------------------------------------------------------------------------------------------------------------------------------------

window.addEventListener("unhandledrejection", event => {
	try {
		throw new ApiError(
			0,
			"UNHANDLED_REJECTION",
			"unhandled promise rejection",
			{
				promise: event.promise,
				reason: event.reason,
			},
		);
	} catch (thrown) {
		handleError(thrown);
	}
});

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
// unhandled error
// -------------------------------------------------------------------------------------------------------------------------------------------------------------

// window.addEventListener("error", event => {
// 	handleError(event.error);
// });

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
// track fetch
// -------------------------------------------------------------------------------------------------------------------------------------------------------------

const originalFetch = window.fetch;
window.fetch = async function fetch(...args) {
	const start = +new Date();
	const input = args[0];
	const init = args[1];

	const event = addReportEvent({
		kind: ReportEventKind.Fetch,
		fetch: {
			method: init?.method?.toUpperCase() ?? "GET",
			url: input,
		},
	});

	// run the fetch
	const fetchRes: Response = await originalFetch.apply(this, args);

	// track some fields once the response is here
	const end = +new Date();
	event.fetch.time = end - start;
	event.fetch.status = fetchRes.status;

	return fetchRes;
};


// -------------------------------------------------------------------------------------------------------------------------------------------------------------
// error handler
// also show the report toast etc.
// -------------------------------------------------------------------------------------------------------------------------------------------------------------

let toastKey: string;
const showErrorReportDialog = observable.box(false);
let reportEventsSnapshot: ReportEvent[];
export const handleError = (error: Error): void => {
	fpLog.error("handleError", { error });

	let apiError: ApiError;
	if (error instanceof ApiError) {
		// keep api errors
		apiError = error;
	} else if (error instanceof Error) {
		// keep the error "message" if we have a proper error object
		apiError = new ApiError(0, "UNHANDLED_JS_ERROR", error.message, error);
	} else {
		// something unknown, generic message
		apiError = new ApiError(0, "UNHANDLED_JS_ERROR", "An unexpected error happened.", error);
	}

	// if the stack is missing, throw error to generate one
	if (apiError.stack == null) {
		try {
			throw apiError;
		} catch (thrownApiError) {
			apiError = thrownApiError;
		}
	}

	addReportEvent({
		kind: ReportEventKind.Error,
		apiError: apiError,
	});
	// take a snapshot
	// - so our error event can't be overwritten by newer events
	// - our error event is always the last item
	reportEventsSnapshot = Array.from(toJS(reportEvents.get()));

	const params: ToastMessageParams = {
		icon: faBug,
		app: "Error",
		variant: "danger",
		autoDismiss: false,
		body: <>
			Sorry, something went wrong<br/>
			{apiError.name}
		</>,
		buttons: <>
			<CommandBar.PulseButton
				variant="danger"
				onClick={() => {
					showErrorReportDialog.set(true);
					FP_APP.toastManager.dismiss(toastKey);
				}}
			>
				<FontAwesomeIcon icon={faMessageQuestion}/>&nbsp;
				Report
			</CommandBar.PulseButton>
		</>
	};

	if (toastKey) {
		FP_APP.toastManager.update(toastKey, params);
	} else {
		toastKey = FP_APP.toastManager.add(params);
	}
};

const ErrorDisplayer: React.FC = observer(() => (
	<ErrorBoundary>
		<FpProvider app={FP_APP}>
			{showErrorReportDialog.get() ? (
				<React.Suspense fallback={<div/>}>
					<ErrorReportDialog
						errorEvents={reportEventsSnapshot}
						onHide={() => {
							// hide dialog
							showErrorReportDialog.set(false);
						}}
						onAfterSubmit={() => {
							// empty events
							// reportEvents.set([]);
						}}
					/>
				</React.Suspense>
			): null}
		</FpProvider>
	</ErrorBoundary>
));

const errorRoot = document.createElement("div");
errorRoot.id = "REACT_ERROR_ROOT";

document.addEventListener("readystatechange", () => {
	if (document.readyState !== "loading") {
		document.body.appendChild(errorRoot);
		ReactDOM.render(<ErrorDisplayer/>, errorRoot);
	}
}, { once: true });
