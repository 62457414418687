import React from "react";
import { useThemeColor } from "../../Css/ThemeColor";
import { Flex, FlexItem } from "../Flex";
const COLOR_DEFAULT = "#777777";
/**
 * @deprecated use ProgressBar.Child
 */
export const ProgressBarChild_old = (props) => {
    const background = useThemeColor(props.color, COLOR_DEFAULT)[500].main;
    return React.createElement(FlexItem, { style: { backgroundColor: background, }, basis: props.value / props.total * props.maxWidth });
};
/**
 * @deprecated use ProgressBar
 */
export const ProgressBar_old = ({ max = 100, width = 100, height = 20, children, value, color, label, }) => {
    if (!children) {
        return React.createElement("div", { style: { position: "relative", width: width } },
            label && React.createElement("div", { style: { position: "absolute", left: "50%", transform: "translateX(-50%)" } }, label),
            React.createElement(ProgressBar_old, { max: max, width: width, height: height, value: value, color: color, label: label },
                React.createElement(ProgressBarChild_old, { color: color, value: value, maxWidth: width, total: max })));
    }
    return React.createElement("div", { style: { width: width, position: "relative" } },
        label && React.createElement("div", { style: { position: "absolute", left: "50%", transform: "translateX(-50%)" } }, label),
        React.createElement(Flex, { basis: width, style: { backgroundColor: "#ddd", height: height } }, React.Children.map(children, (child) => {
            return React.cloneElement(child, { total: max, maxWidth: width });
        })));
};
ProgressBar_old.Child = ProgressBarChild_old;
