import { formatLandingField } from "@tcs-rliess/fp-core";
import { Format, Flex, Text } from "@tcs-rliess/fp-web-ui";
import React from "react";
import { PrintContext } from "../../lib";
import { FlightOrderTable } from "../../lib/styledExportComponents";
export const FlightOrder = (props) => {
    const data = props.data;
    const printContext = React.useContext(PrintContext);
    if (!data)
        return React.createElement(React.Fragment, null, "Error! No data!");
    const paddedLegs = Array.from({ ...data.legs, length: data.legs.length < 6 ? 6 : data.legs.length }, (v) => v ?? {});
    return (React.createElement(React.Fragment, null,
        React.createElement(Flex, { justifyContent: "space-between" },
            React.createElement("div", null,
                React.createElement(Text, { style: { fontSize: "20px" } }, "OM-A_APP_083 / Flight Order/Operational Flight Plan"),
                React.createElement("br", null),
                React.createElement(Text, { size: "md", style: { color: "#777777" } }, "\uFF0D Rev. 3, 01.03.2023")),
            React.createElement("div", null,
                React.createElement("img", { src: printContext?.customer.$dscaid?.avatarTn, style: { width: "100px" } }))),
        React.createElement("p", { style: { margin: "20px 0 15px 0" } },
            React.createElement(Text, { style: { fontSize: "28px" } }, "FLIGHT ORDER\u00A0"),
            React.createElement(Text, { style: { fontSize: "22px", color: "#777777" } }, "DE.313.AOC.SPO.HR")),
        React.createElement(FlightOrderTable, { "$tdPadding": "2px", "$fontSize": "10px" },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null,
                        React.createElement(Text, { bold: true }, "Date")),
                    React.createElement("td", null,
                        React.createElement(Format.Date, null, data.event.date_start),
                        " to ",
                        React.createElement(Format.Date, null, data.event.date_end)),
                    React.createElement("td", { style: { textAlign: "right" } },
                        React.createElement(Text, { bold: true }, "Mission")),
                    React.createElement("td", { colSpan: 2 }, data.formatted.mission)),
                React.createElement("tr", null,
                    React.createElement("td", null,
                        React.createElement(Text, { bold: true }, "A/C Type")),
                    React.createElement("td", null, data.vehicle.aircraft.$fpdbvmid?.vehicleModel ?? React.createElement(React.Fragment, null, "\u00A0")),
                    React.createElement("td", { style: { textAlign: "right" } },
                        React.createElement(Text, { bold: true }, "Pilot 1 / Role")),
                    React.createElement("td", null, data.crew.flightCrew[0]?.$contactFormatted ?? React.createElement(React.Fragment, null, "\u00A0")),
                    React.createElement("td", null, data.crew.flightCrew[0]?.dserrid ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("tr", null,
                    React.createElement("td", null,
                        React.createElement(Text, { bold: true }, "Call-Sign")),
                    React.createElement("td", null, data.vehicle.aircraft.registrationCode ?? React.createElement(React.Fragment, null, "\u00A0")),
                    React.createElement("td", { style: { textAlign: "right" } },
                        React.createElement(Text, { bold: true }, "Pilot 2 / Role")),
                    React.createElement("td", null, data.crew.flightCrew[1]?.$contactFormatted ?? React.createElement(React.Fragment, null, "\u00A0")),
                    React.createElement("td", null, data.crew.flightCrew[1]?.dserrid ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("tr", null,
                    React.createElement("td", null,
                        React.createElement(Text, { bold: true }, "Customer")),
                    React.createElement("td", null, data.formatted.customer),
                    React.createElement("td", { style: { textAlign: "right" } },
                        React.createElement(Text, { bold: true }, "Reference")),
                    React.createElement("td", { colSpan: 2 }, data.event.reference)))),
        React.createElement("p", { style: { marginBottom: "10px", marginTop: "10px" } },
            React.createElement(Text, { style: { fontSize: "22px" } }, data.event.data?.order?.work_no)),
        React.createElement("p", { style: { marginBottom: "10px" } },
            React.createElement(Text, { size: "lg" }, "Dokumente zur Aufbewahrung")),
        React.createElement(FlightOrderTable, { "$borderWidth": "0px", "$tdPadding": "2px", "$fontSize": "10px" },
            React.createElement("tbody", null,
                React.createElement("tr", { style: { borderTop: "1px solid #ddd" } },
                    React.createElement("td", { style: { width: "10%" } }),
                    React.createElement("td", null, "\u2610 Flugauftrag"),
                    React.createElement("td", null, "\u2610 Wetter Informationen")),
                React.createElement("tr", { style: { borderTop: "1px solid #ddd" } },
                    React.createElement("td", null),
                    React.createElement("td", null, "\u2610 ATC Flugplan"),
                    React.createElement("td", null, "\u2610 Notam Briefing")),
                React.createElement("tr", { style: { borderTop: "1px solid #ddd" } },
                    React.createElement("td", null),
                    React.createElement("td", null, "\u2610 Beladung, Schwerpunkt & Performance"),
                    React.createElement("td", null, "\u2610 Flugdurchf\u00FChrungsplan")),
                React.createElement("tr", { style: { borderTop: "1px solid #ddd" } },
                    React.createElement("td", null),
                    React.createElement("td", null, "\u2610 Besondere Erlaubnisse"),
                    React.createElement("td", null)))),
        React.createElement(FlightOrderTable, { "$tdPadding": "2px", "$fontSize": "10px", style: { marginTop: "10px" } },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null,
                        "Der Luftfahrzeugf\u00FChrer versichert, dass das Personal an Bord eine Sicherheitseinweisung erhalten hat und er weder durch den Genuss alkoholischer Getr\u00E4nke oder anderer berauschender Mittel, noch infolge geistiger oder k\u00F6rperlicher M\u00E4ngel in der Wahrnehmung seiner Aufgaben als verantwortlicher Luftfahrzeugf\u00FChrer behindert ist. Er versichert ferner, dass die Flugvorbereitung gem. OM-A 8.1 durchgef\u00FChrt wurde. Der NPFO oder eine von ihm bevollm\u00E4chtigte Person erteilt hiermit den Auftrag, die oben aufgef\u00FChrten Fl\u00FCge durchzuf\u00FChren. Die Flugvorbereitung ist nach dem Flug mit den o.g. Dokumenten zur Aufbewahrung vollst\u00E4ndig an ",
                        React.createElement("span", { style: { color: "#044493" } },
                            React.createElement("u", null, "flugvorbereitung@rotorflug.de")),
                        " zu \u00FCbermitteln.")))),
        React.createElement(Flex, { justifyContent: "space-between", style: { marginTop: "40px" }, alignItems: "flex-end" },
            React.createElement("div", { style: { width: "40%", textAlign: "center" } },
                React.createElement("div", { style: { borderTop: "1px solid #ddd", fontSize: "10px" } },
                    "Unterschrift PIC",
                    React.createElement("br", null),
                    "\u00A0")),
            React.createElement("div", { style: { width: "40%", textAlign: "center" } },
                React.createElement("img", { src: "https://rotorflug.fleetplan.net/project/rotorflug/image/signature_robin.png", style: { height: "40px" } }),
                React.createElement("div", { style: { borderTop: "1px solid #ddd", fontSize: "10px" } },
                    "Unterschrift",
                    React.createElement("br", null),
                    "NPFO/Dispatcher"))),
        React.createElement("hr", { style: { borderBottom: "1px dashed #f1f3f6", borderTop: "none", margin: "30px 0 20px 0", backgroundColor: "white" } }),
        React.createElement("p", { style: { marginBottom: "5px" } },
            React.createElement(Text, { style: { fontSize: "22px" } }, "OPS RETURN / FLIGHT LOG")),
        React.createElement(FlightOrderTable, { "$fontSize": "11px" },
            React.createElement("thead", null,
                React.createElement("tr", { style: { textAlign: "center", fontSize: "10px" } },
                    React.createElement("td", { rowSpan: 2 }, "Nr."),
                    React.createElement("td", { rowSpan: 2 }, "Datum"),
                    React.createElement("td", null, "From"),
                    React.createElement("td", null, "Crew"),
                    React.createElement("td", null, "Block Off"),
                    React.createElement("td", { rowSpan: 2 }, "Block"),
                    React.createElement("td", null, "Flight T/O"),
                    React.createElement("td", { rowSpan: 2 }, "Flight"),
                    React.createElement("td", null, "Refuel"),
                    React.createElement("td", { rowSpan: 2 }, "Fuel (l)"),
                    React.createElement("td", { rowSpan: 2 }, "Soll/Ist")),
                React.createElement("tr", { style: { textAlign: "center", backgroundColor: "#F1F3F6", fontSize: "10px" } },
                    React.createElement("td", null, "To"),
                    React.createElement("td", null, "Pax"),
                    React.createElement("td", null, "Block On"),
                    React.createElement("td", null, "Flight LDG"),
                    React.createElement("td", null, "LDG"))),
            React.createElement("tbody", null, paddedLegs.map((leg, i) => (React.createElement(React.Fragment, null,
                React.createElement("tr", { key: "leglist_" + i },
                    React.createElement("td", { rowSpan: 2, style: { textAlign: "center" } },
                        React.createElement(Text, { size: "md" }, (i + 1))),
                    React.createElement("td", { rowSpan: 2 },
                        React.createElement(Format.Date, { tz: printContext?.userSettings?.tz }, leg.std)),
                    React.createElement("td", null, typeof leg.from === "string" ? leg.from : formatLandingField(leg.from)),
                    React.createElement("td", null),
                    React.createElement("td", null, "\u00A0"),
                    React.createElement("td", { rowSpan: 2 }),
                    React.createElement("td", null, "\u00A0"),
                    React.createElement("td", { rowSpan: 2 }),
                    React.createElement("td", null),
                    React.createElement("td", { rowSpan: 2 }),
                    React.createElement("td", { rowSpan: 2 })),
                React.createElement("tr", { style: { backgroundColor: "#F1F3F6" } },
                    React.createElement("td", null, typeof leg.to === "string" ? leg.to : formatLandingField(leg.to)),
                    React.createElement("td", null, "\u00A0"),
                    React.createElement("td", null, "\u00A0"),
                    React.createElement("td", null, "\u00A0"),
                    React.createElement("td", null, "\u00A0"))))))),
        React.createElement(Flex, { justifyContent: "space-between", style: { marginTop: "10px", fontSize: "9px", color: "#044493" } },
            React.createElement("div", { style: { width: "23%" } },
                "Rotorflug GmbH",
                React.createElement("br", null),
                "Peter-Geibel-Stra\u00DFe 24",
                React.createElement("br", null),
                "61381 Friedrichsdorf"),
            React.createElement("div", { style: { width: "23%" } },
                "AG Bad Homburg HRB 14644",
                React.createElement("br", null),
                "Gesch\u00E4ftsf\u00FChrung: Dirk Herr",
                React.createElement("br", null),
                "USt-ID: DE114106201"),
            React.createElement("div", { style: { width: "16%" } },
                "Luftfahrtunternehmen:",
                React.createElement("br", null),
                "Instandhaltungsbetrieb:",
                React.createElement("br", null),
                "CAMO:"),
            React.createElement("div", { style: { width: "16%" } },
                "D - 313 AOC",
                React.createElement("br", null),
                "DE.145.0310",
                React.createElement("br", null),
                "DE.CAMO.313.AOC"),
            React.createElement("div", { style: { width: "6%" } },
                "Telefon:",
                React.createElement("br", null),
                "E-Mail:",
                React.createElement("br", null),
                "Internet:"),
            React.createElement("div", { style: { width: "16%" } },
                "+49-6007-9141-0",
                React.createElement("br", null),
                "info@rotorflug.de",
                React.createElement("br", null),
                "www.rotorflug.de"))));
};
