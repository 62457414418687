import React from "react";
import { Button, CommandBar, Flex, FlexItem, Format, Text } from "../../../Components";
import { useOutsideAlerter } from "../../../lib";
import { Overlay, Wrapper } from "../util";
import { DateRangePicker } from "./DateRangePicker";
export const DateRangePickerButton = (props) => {
    const [show, setShow] = React.useState(false);
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, () => { if (show) {
        setShow(false);
        props.onClose?.();
    } });
    const startDate = props.dateRange?.start;
    const endDate = props.dateRange?.end;
    const days = (startDate && endDate) ? Math.round(endDate.diff(startDate).as("days")) : -1;
    return (React.createElement(Wrapper, { ref: wrapperRef, style: props.fullHeight ? { height: "100%" } : undefined },
        show && React.createElement(Overlay, null,
            React.createElement(DateRangePicker, { minDate: props.minDate, maxDate: props.maxDate, dateRange: props.dateRange, onChange: (st) => {
                    props.onChange(st);
                }, maxNumberOfDays: props.maxNumberOfDays, showPresets: props.showPresets, presets: props.presets, tz: props.tz }),
            props.withFooter && React.createElement(Flex, { justifyContent: "flex-end", style: { marginRight: "1rem" } },
                React.createElement(FlexItem, null,
                    React.createElement(CommandBar.Right, null,
                        React.createElement(CommandBar.Button, { size: "xs", variant: "link", onClick: () => setShow(false) }, "Close"),
                        React.createElement(CommandBar.Button, { size: "xs", variant: "primary", onClick: () => setShow(false) }, "Apply"))))),
        React.createElement(props.component, { ...(props.componentProps ?? {}), [props.clickHandlerPath ?? "onClick"]: () => {
                if (show) {
                    props.onClose?.();
                }
                else {
                    props.onOpen?.();
                }
                setShow(!show);
            } }, startDate
            ? React.createElement(React.Fragment, null,
                startDate.weekdayShort.toUpperCase(),
                "\u00A0",
                React.createElement(Format.Date, { tz: props.tz, showTz: false }, startDate),
                endDate && days > 1
                    ? React.createElement(React.Fragment, null,
                        React.createElement("span", null, "\u00A0-\u00A0"),
                        endDate.weekdayShort.toUpperCase(),
                        "\u00A0",
                        React.createElement(Format.Date, { tz: props.tz, showTz: false }, endDate),
                        startDate.startOf("week").equals(startDate) && endDate.endOf("week").equals(endDate)
                            ? React.createElement("span", null,
                                "\u00A0(CW ",
                                startDate.weekNumber,
                                ")")
                            : React.createElement("span", null,
                                "\u00A0(",
                                days,
                                " days)"))
                    : null)
            : React.createElement(Text, { muted: true, italic: true }, "No range selected"))));
};
DateRangePickerButton.defaultProps = {
    component: Button,
};
