import { faRepeat } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clone, cloneDeep, toNumber } from "lodash";
import { DateTime } from "luxon";
import { useObservable } from "mobx-react-lite";
import React from "react";
import Select from "react-select";
import * as RRule from "rrule";
import styled from "styled-components";
import { Flex, FlexItem, Text, useTimezone } from "../";
import { Form } from "../..";
import { DateInput, Input, fpBaseTheme } from "../../Forms";
import { Button } from "../Button";
const optionsWeekday = [{
        value: RRule.RRule.MO,
        label: "MO",
    }, {
        value: RRule.RRule.TU,
        label: "TU",
    }, {
        value: RRule.RRule.WE,
        label: "WE",
    }, {
        value: RRule.RRule.TH,
        label: "TH",
    }, {
        value: RRule.RRule.FR,
        label: "FR",
    }, {
        value: RRule.RRule.SA,
        label: "SA",
    }, {
        value: RRule.RRule.SU,
        label: "SU",
    }];
var OnChangeTypesRRule;
(function (OnChangeTypesRRule) {
    OnChangeTypesRRule["Frequency"] = "freq";
    OnChangeTypesRRule["Weekdays"] = "byweekday";
    OnChangeTypesRRule["DateEnd"] = "until";
    OnChangeTypesRRule["DateStart"] = "dtstart";
    OnChangeTypesRRule["Interval"] = "interval";
    OnChangeTypesRRule["Count"] = "count";
    // on change until
    OnChangeTypesRRule["Date"] = "date";
    OnChangeTypesRRule["Event"] = "event";
})(OnChangeTypesRRule || (OnChangeTypesRRule = {}));
const optionsFrequency = [{
        value: RRule.RRule.DAILY,
        label: "Day(s)",
    }, {
        value: RRule.RRule.WEEKLY,
        label: "Week(s)",
    }, {
        value: RRule.RRule.MONTHLY,
        label: "Month(s)",
    }];
export const RRuleInput = function RRuleInput({ value, onChange, enableUntil, disabled, withTz, bootstrap = true, }) {
    const tz = useTimezone();
    const valueInternal = React.useMemo(() => {
        return value ? typeof value === "string" ? RRule.RRule.fromString(value) : value : null;
    }, [value]);
    const [limitType, setLimitType] = React.useState(valueInternal?.origOptions?.count ? OnChangeTypesRRule.Event : OnChangeTypesRRule.Date);
    React.useEffect(() => {
        const nextLimitType = valueInternal?.origOptions?.count ? OnChangeTypesRRule.Event : OnChangeTypesRRule.Date;
        if (limitType === nextLimitType)
            return;
        setLimitType(nextLimitType);
    }, [valueInternal]);
    const backupLimitType = useObservable({
        [OnChangeTypesRRule.Date]: null,
        [OnChangeTypesRRule.Event]: null,
    });
    // const [ checked, setChecked ] = React.useState(!!value?.options.until);
    React.useEffect(() => {
        if (!valueInternal) {
            const rule = new RRule.RRule({ freq: RRule.Frequency.WEEKLY, interval: 1, tzid: withTz ? tz : undefined });
            onChange?.(rule);
        }
    }, [valueInternal]);
    const onChangeUntil = React.useCallback((newValue) => {
        if (!enableUntil)
            return null;
        if (!valueInternal)
            return null;
        const rule = cloneDeep(valueInternal.origOptions);
        switch (limitType) {
            case OnChangeTypesRRule.Date: {
                newValue = newValue;
                delete rule.count;
                rule.until = newValue.toJSDate();
                break;
            }
            case OnChangeTypesRRule.Event: {
                newValue = newValue;
                const realValue = toNumber(newValue.target.value);
                if (realValue > 999)
                    return;
                if (realValue < 0)
                    return;
                delete rule.until;
                rule.count = realValue;
                break;
            }
            default:
                break;
        }
        const newRule = new RRule.RRule({ ...rule, tzid: withTz ? tz : undefined });
        onChange?.(newRule);
    }, [limitType, enableUntil, valueInternal]);
    React.useEffect(() => {
        if (!valueInternal)
            return;
        if (!limitType) {
            if (valueInternal.origOptions.count) {
                setLimitType(OnChangeTypesRRule.Event);
                backupLimitType[OnChangeTypesRRule.Event] = valueInternal.origOptions.count;
            }
            else if (valueInternal.origOptions.until) {
                setLimitType(OnChangeTypesRRule.Date);
                backupLimitType[OnChangeTypesRRule.Date] = valueInternal.origOptions.until;
            }
        }
        else {
            if (limitType === OnChangeTypesRRule.Date) {
                valueInternal.origOptions.until = backupLimitType[OnChangeTypesRRule.Date];
            }
            else {
                valueInternal.origOptions.count = backupLimitType[OnChangeTypesRRule.Event];
            }
        }
    }, [limitType]);
    const until = (() => {
        if (!enableUntil)
            return null;
        switch (limitType) {
            case OnChangeTypesRRule.Date:
                return React.createElement(DateInput, { key: "1", min: valueInternal?.origOptions.dtstart?.toISOString(), max: DateTime.fromJSDate(valueInternal?.origOptions.dtstart).plus({ days: 365 }).toISO(), wrapperStyle: { width: "100%" }, value: valueInternal?.options?.until?.toISOString(), onChange: onChangeUntil, left: true, bootstrap: bootstrap });
            case OnChangeTypesRRule.Event:
                return React.createElement(Input, { type: "number", key: "2", bootstrap: bootstrap, max: 365, style: { textAlign: "right" }, value: valueInternal?.options?.count, onChange: onChangeUntil });
            default:
                break;
        }
    })();
    const firstRun = React.useRef(true);
    React.useEffect(() => {
        if (!enableUntil)
            return;
        if (!valueInternal)
            return;
        if (firstRun.current)
            return;
        const rule = cloneDeep(valueInternal.origOptions);
        switch (limitType) {
            case OnChangeTypesRRule.Date: {
                backupLimitType[OnChangeTypesRRule.Event] = rule.count; // save backup
                delete rule.count;
                if (backupLimitType[OnChangeTypesRRule.Date]) // apply backup
                    rule.until = backupLimitType[OnChangeTypesRRule.Date];
                break;
            }
            case OnChangeTypesRRule.Event:
                backupLimitType[OnChangeTypesRRule.Date] = rule.until; // save backup
                delete rule.until;
                if (backupLimitType[OnChangeTypesRRule.Event]) // apply backup
                    rule.count = backupLimitType[OnChangeTypesRRule.Event];
                break;
            default:
                break;
        }
        const newRule = new RRule.RRule({ ...rule, tzid: withTz ? tz : undefined });
        onChange?.(newRule);
    }, [limitType]);
    function onChangeInternal(type, newValue) {
        const rule = cloneDeep(valueInternal?.origOptions ?? new RRule.RRule().origOptions);
        switch (type) {
            case OnChangeTypesRRule.Event:
            case OnChangeTypesRRule.Date:
                if (type === OnChangeTypesRRule.Event) {
                    // changed to count
                    rule.count = 10;
                    delete rule.until;
                }
                else {
                    // changed to until
                    rule.until = DateTime.local().plus({ months: 1 }).toJSDate();
                    delete rule.count;
                }
                break;
            case OnChangeTypesRRule.Count:
                rule.count = Math.max(1, newValue);
                break;
            case OnChangeTypesRRule.Frequency:
                if (newValue === RRule.RRule.WEEKLY) {
                    rule.byweekday = [
                        RRule.RRule.MO,
                        RRule.RRule.TU,
                        RRule.RRule.WE,
                        RRule.RRule.TH,
                        RRule.RRule.FR,
                    ];
                }
                else {
                    delete rule.byweekday;
                }
                rule.freq = newValue;
                break;
            case OnChangeTypesRRule.DateStart: {
                const _tz = withTz ? tz : valueInternal.options.tzid ?? "UTC";
                rule.dtstart = DateTime.fromISO(newValue).setZone(_tz).toJSDate();
                break;
            }
            default:
                rule[type] = newValue;
                break;
        }
        const rrule = new RRule.RRule({ ...rule, tzid: withTz ? tz : undefined });
        if (value !== rrule.toString()) {
            onChange?.(rrule);
        }
    }
    function toggleWeekday(weekday) {
        const weekdays = clone(valueInternal.origOptions.byweekday);
        // "weekdays" might be an array of weekdays or a single weekday
        if (Array.isArray(weekdays)) {
            // if the element is not in the array, we can just use concat
            if (weekdays.indexOf(weekday) === -1) {
                weekdays.push(weekday);
                return weekdays;
            }
            else {
                weekdays.splice(weekdays.indexOf(weekday), 1);
                return weekdays;
            }
        }
        else {
            if (weekdays === weekday) {
                // the only set weekday is the one we are looking for
                return [];
            }
            else {
                // if not, no weekday means we can just set it
                return [weekday];
            }
        }
    }
    React.useEffect(() => {
        firstRun.current = false;
    }, []);
    if (bootstrap) {
        return React.createElement(Flex, { column: true },
            React.createElement(Flex, null,
                React.createElement(FlexItem, { shrink: true, style: {
                        color: "#b3b3b3",
                        padding: "5px 8px",
                        border: "1px solid #ccc",
                        borderRight: "none",
                        fontSize: "14px",
                        fontWeight: 400,
                        height: 34,
                    } }, "Every"),
                React.createElement(FlexItem, { grow: true },
                    React.createElement("input", { disabled: disabled, type: "number", className: "form-control", style: { zIndex: 0, borderRadius: 0, borderRight: 0, textAlign: "right" }, min: 1, max: 99, value: valueInternal?.options.interval, onChange: (e) => {
                            onChangeInternal(OnChangeTypesRRule.Interval, e.target.value ? e.target.value : 1);
                        } })),
                React.createElement(FlexItem, { grow: true },
                    React.createElement(Select, { isDisabled: disabled, theme: fpBaseTheme, value: optionsFrequency.filter(e => valueInternal?.origOptions.freq === e.value), options: optionsFrequency, isClearable: false, onChange: (e) => onChangeInternal(OnChangeTypesRRule.Frequency, e.value) }))),
            valueInternal?.options.freq === RRule.Frequency.WEEKLY && React.createElement(Flex, null, optionsWeekday.map(option => React.createElement(FlexItem, { grow: true, key: option.value.toString() },
                React.createElement(Button, { disabled: disabled, style: { width: "100%" }, variant: valueInternal.options.byweekday.includes(option.value.weekday) ? "primary" : null, onClick: () => {
                        onChangeInternal(OnChangeTypesRRule.Weekdays, toggleWeekday(option.value));
                    } }, option.label)))),
            enableUntil && React.createElement(Flex, { style: { marginTop: 8, position: "relative" } },
                React.createElement(Arrow, null,
                    React.createElement("div", { style: {
                            color: "transparent",
                            padding: "5px 8px",
                            border: "1px solid transparent",
                            borderRight: "none",
                            fontSize: "14px",
                            fontWeight: 400,
                            height: 34,
                        } }, "Every")),
                React.createElement(FlexItem, { style: {
                        color: "#b3b3b3",
                        padding: "5px",
                        border: "1px solid #ccc",
                        borderRight: "none",
                        fontSize: "14px",
                        fontWeight: 400,
                    } },
                    OnChangeTypesRRule.Event ? "Repeat" : "Until",
                    " \u00A0",
                    React.createElement("select", { value: limitType, style: { border: "none", background: "none" }, onChange: (e) => setLimitType(e.target.value) },
                        React.createElement("option", { value: OnChangeTypesRRule.Date }, "Date"),
                        React.createElement("option", { value: OnChangeTypesRRule.Event }, "Events"))),
                React.createElement(FlexItem, { grow: true }, until)));
    }
    return React.createElement("div", null,
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "1fr 2fr 4fr 4fr 4fr", gap: 2 } },
            React.createElement(Input.Display, null,
                React.createElement(Text, { muted: true }, "Every")),
            React.createElement(Input, { disabled: disabled, type: "number", style: { zIndex: 0, borderRadius: 0, borderRight: 0, textAlign: "right" }, min: 1, max: 99, value: valueInternal?.options.interval, onChange: (e) => {
                    onChangeInternal(OnChangeTypesRRule.Interval, e.target.value ? e.target.value : 1);
                } }),
            React.createElement(Select, { isDisabled: disabled, value: optionsFrequency.filter(e => valueInternal?.origOptions.freq === e.value), options: optionsFrequency, isClearable: false, onChange: (e) => onChangeInternal(OnChangeTypesRRule.Frequency, e.value), ...Form.SelectTheme }),
            enableUntil && React.createElement(Input.Display, null,
                limitType === OnChangeTypesRRule.Event ? React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: faRepeat }) : "Until",
                " \u00A0",
                React.createElement("select", { value: limitType, style: { border: "none", background: "none" }, onChange: (e) => setLimitType(e.target.value) },
                    React.createElement("option", { value: OnChangeTypesRRule.Date }, "Date"),
                    React.createElement("option", { value: OnChangeTypesRRule.Event }, "Event"))),
            until),
        valueInternal?.options.freq === RRule.Frequency.WEEKLY && React.createElement("div", { style: { display: "grid", gridTemplateColumns: "1fr 10fr", gap: 2 } },
            React.createElement(Input.Display, null,
                React.createElement(Text, { muted: true }, "On")),
            React.createElement(Input.Display, null,
                React.createElement(Flex, null, optionsWeekday.map(option => React.createElement(FlexItem, { grow: true, key: option.value.toString() },
                    React.createElement(Button, { disabled: disabled, size: "xs", style: { width: "100%", height: 14, padding: 0, fontSize: 9 }, variant: valueInternal.options.byweekday.includes(option.value.weekday) ? "primary" : null, onClick: () => {
                            onChangeInternal(OnChangeTypesRRule.Weekdays, toggleWeekday(option.value));
                        } }, option.label)))))));
};
const Arrow = styled.div `
	position: relative;
	&::before {
		content: "";
		display: block;
		/* width: 13px; */
		height: calc(50% + 8px);
		width: 50%;
		/* border-top: 10px solid transparent; */
		/* border-bottom: 10px solid transparent; */
		/* border-right: 10px solid #ccc; */
		margin-top: 0;
		border-left: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		margin-left: 50%;
		bottom: 8px;
		position: relative;
	}
	&::after{
		content: "";
		display: block;
		position: absolute;
		width: 0px;
		height: 0px;
		top: calc(50% - 5px); // take into account the border
		left: calc(100% - 10px);
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-left: 10px solid #ccc;
	}
`;
