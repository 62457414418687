/* eslint-disable import/order, import/first */
import "./publicPath";

// polyfills
import "core-js/modules/es.string.match-all";
import { NumberFormat } from "intl";
import "intl/locale-data/jsonp/de";
import "intl/locale-data/jsonp/en";
// used in old manual view (loading="lazy" not supported in safari)
import loadingAttributePolyfill from "loading-attribute-polyfill/dist/loading-attribute-polyfill.module.js";

if (!Intl.NumberFormat.prototype.formatToParts) {
	// safari is missing this
	Intl.NumberFormat = NumberFormat;
}

// public moment for old cf apps
import moment from "moment-timezone";
(window as any).moment = moment;

// other stuff
import * as FpWebUi from "@tcs-rliess/fp-web-ui";
import { get } from "lodash-es";
import React from "react";
import ReactDOM from "react-dom";
import "react-virtualized/styles.css";
import "./agGrid";

// ag-grid

// import "ag-grid-community/styles/ag-theme-balham-dark.css";

// initialize api
import "./apiManager";

// icons
import "./icons/Icons";

// normals imports
import { ApiContext, FpId, IFleetplanApi, RelationUtil, UserSettings, apiManager } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import { FleetplanApp, FleetplanAppParams } from "./FleetplanApp";
import "./MutationObserver";
import { ErrorBoundary, FpProvider } from "./common";
import { LinkUtil, MediaApiDropHandler } from "./lib";


// "react-router" ignores all hashes like `#123` (no slash after the "#")
// one initial fix when script is loaded to add a slash
if (window.location.hash && window.location.hash.startsWith("#/") === false) {
	window.location.hash = "#/" + window.location.hash.substr(1);
}

interface RenderOptions {
	el: string | Element;
	module: string;
	view: string;
	props?: any;
	/** wether to wait for data preloading */
	preloadWait?: boolean
}

interface ResetCommand {
	linkidtype: "dselement" | "dscmd" | "cmd" | string;
	linkid: string;
}

export let FP_APP: FleetplanApp;

export const FP_WEB = window["FP_WEB"] = {
	loadingAttributePolyfill: loadingAttributePolyfill,

	initApp(params: FleetplanAppParams): void {
		if (FP_APP != null) throw new Error("FleetplanApp already initialized");

		FP_APP = window["FP_APP"] = new FleetplanApp(params);
		FpId.defaultCtx = FP_APP.ctx;
	},

	FpId: FpId,
	apiManager: apiManager,
	libInstances: {
		relationUtil: RelationUtil,
	},
	ReactDOM: ReactDOM,

	// shortcuts
	get ctx(): ApiContext { return FP_APP.ctx; },
	get formatter(): FpWebUi.Formatter { return FP_APP.formatter; },
	get linkHelper(): LinkUtil { return FP_APP.linkUtil; },
	get fleetplanApi(): IFleetplanApi { return FP_APP.fleetplanApi; },
	get userSettings(): UserSettings { return FP_APP.userSettings; },

	renderGlobalCss: function(): void {
		FpWebUi.insertRoboto();
	},

	render: async function(options: RenderOptions): Promise<{ unmount: () => void }> {
		// workaround for mobile app
		// the mobile app (especially old versions) don't pass in preloadWait, meaning they would get stuck trying to wait
		if (options.module === "ControlledDocument" && options.view === "RevisionViewerApp") {
			options.preloadWait = false;
		}

		const domEl = typeof options.el === "string"
			? document.querySelector(options.el)
			: options.el;

		if (domEl == null) {
			fpLog.error("FP_WEB: el not found", options);
			throw new Error(`FP_WEB: el not found: ${options.el}`);
		}

		// load the module
		const otherModule = options.module === "fp-web-ui"
			? FpWebUi
			: await import(`./modules/${options.module}/chunk`);

		const ViewClass = get(otherModule, options.view);

		if (ViewClass == null) {
			fpLog.error("FP_WEB: ViewClass not found", options);
			throw new Error(`FP_WEB: ViewClass not found: ${options.module}/${options.view}`);
		}

		if (options.props == null) {
			options.props = {};
		}

		// set a "onHide" handler for dialogs, so we can remove it after closing.
		// 2018-10-09 [SH] Added support for array instead of function)
		const orgOnHide = wrap(options.props.onHide);
		options.props.onHide = () => {
			ReactDOM.unmountComponentAtNode(domEl);
			orgOnHide();
		};

		// in case we get an dsresetcommand
		if (options.props.dsresetcommand) {
			// we will inject an onReset method into the component, so we can use the dsresetcommand!
			const dsresetcommand: ResetCommand[] = JSON.parse(decodeURIComponent(options.props.dsresetcommand).replace("dsresetcommand=", ""));

			/**
			 * to use this,
			 * generate an resetcommand string in CF:
			 * _resetCommand = DSResetQueryString('dscmd=#Request.DSCMD.CMD#&dselement=#Request.DSCMD.Element#&dsqmprcsid=#URL.DSQMPRCSID#');
			 * then when using FP_WEB.render add the following prop: "dsresetcommand"
			 * Now you can use "onReset" in your React Component to run the reset command
			 */
			options.props.onReset = () => {
				const commandOptions = dsresetcommand.reduce((prev, next) => {
					switch(next.linkidtype) {
						case "dselement": prev.element = next.linkid; break;
						case "dscmd":
						case "cmd": prev.url = `dynasite.cfm?${next.linkidtype}=${next.linkid}`; break;
						default: prev.options += `&${next.linkidtype}=${next.linkid}`;
					}
					return prev;
				}, {
					url: null,
					element: null,
					options: "",
				} as {
					url: string,
					element: string,
					options: string,
				});

				dsloader(`${commandOptions.url}${commandOptions.options}`, "xhr", commandOptions.element);
			};
			// in case no onAfterSave is defined
		}

		// I have no idea what this exactly does
		options.props.onSave = wrap(options.props.onSave);

		// wait for fleetplan app to finish preload
		// can be disabled per option (mostly only for the login)
		const preloadWait = options.preloadWait ?? true;
		if (preloadWait) {
			await FP_APP.preload;
		}

		const reactEl = (
			<ErrorBoundary>
				<FpProvider app={FP_APP}>
					<ViewClass {...options.props}/>
				</FpProvider>
			</ErrorBoundary>
		);

		domEl.setAttribute("fp-web-root", "");
		ReactDOM.render(reactEl, domEl);

		return {
			unmount: () => ReactDOM.unmountComponentAtNode(domEl),
		};
	},
};

/**
 * dont use this pls
 * @deprecated
 * @param target 
 * @returns 
 */
function wrap(target: unknown): (...args) => void {
	return (...args) => {
		if (typeof target === "function") {
			target(...args);
		} else if (Array.isArray(target)) {
			const fn: unknown = target.shift();
			const allowedCbFns = [ "dsloader" ];

			if (typeof fn !== "string") throw new Error("FP_WEB: method name not a string");
			if (!allowedCbFns.includes(fn)) throw new Error(`FP_WEB: method ${fn} not allowed`);
			const targetFunction: unknown = window[fn];
			if (typeof targetFunction !== "function") throw new Error(`FP_WEB: window.${fn} method not a function`);

			targetFunction.apply(window, target);
		}
	};
}

new MediaApiDropHandler();
