import { last } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { Flex } from "../Flex";
import { WorkflowWidget } from "../WorkflowWidget/WorkflowWidget";
import { PathCtx, SlidingPanelKeyCtx, usePath, usePathContext, useSlidingPanel, useSlidingPanelContext, useSlidingPanelKey } from "./lib/hooks";
import { SlidingPanelCtx } from "./lib/SlidingPanelState";
export function SlidingPanelHoc(WrappedComponent) {
    const Component = function (props) {
        if (props.state == null) {
            return React.createElement(SlidingPanelStateCreator, null,
                React.createElement(WrappedComponent, { ...props }));
        }
        else {
            return React.createElement(SlidingPanelCtx.Provider, { value: props.state },
                React.createElement(WrappedComponent, { ...props }));
        }
    };
    Component.displayName = `SlidingPanelHoc(${WrappedComponent.displayName || WrappedComponent.name || "?"})`;
    return observer(Component);
}
const SlidingPanelStateCreator = ({ children }) => {
    const state = useSlidingPanel();
    return React.createElement(SlidingPanelCtx.Provider, { value: state }, children);
};
const PathWrapper = (props) => {
    const [path, setPath] = React.useState([]);
    const removeFromPath = React.useCallback((id) => {
        setPath(path => path.filter(e => e.id !== id));
    }, [path]);
    return React.createElement(PathCtx.Provider, { value: {
            path,
            setPath,
            removeFromPath,
        } }, props.children);
};
const SlidingPanelProvider = SlidingPanelHoc(({ children }) => {
    return React.createElement(PathWrapper, null, children);
});
// const Container = styled.div<{ direction: Direction }>`
// 	transition: all 600ms;
// 	width: 100%;
// 	// enter from
// 	&.stacked-panel-enter {
// 		background-color: #00ff0058;
// 	}
// 	// enter to
// 	&.stacked-panel-enter-active {
// 		background-color: #00ff0058;
// 	}
// 	/* &.stacked-panel-enter-done {
// 	} */
// 	// exit from
// 	&.stacked-panel-exit {
// 		background-color: #ff370057;
// 	}
// 	// exit to 
// 	&.stacked-panel-exit-active {
// 		background-color: #ff370057;
// 	}
// 	// exit to 
// 	/* &.stacked-panel-exit-done {
// 	} */
// `;
const Container = styled.div `
	width: 100%;
	display: none;
	// enter from
	// cycle:
	// 1. stacked-panel-enter -> start style
	// 2. stacked-panel-enter.stacked-panel-enter-active -> transition style
	// 3. .stacked-panel-enter-done -> finished
	&.stacked-panel-enter:not(.stacked-panel-enter-active) {
		// let it start outside
		display: block;
		position: absolute;
		background-color: red;
		${props => props.direction === "back" ? "left" : "right"}: -100%;
	}

	// enter to
	&.stacked-panel-enter-active.stacked-panel-enter {
		display: block;
		position: absolute;
		transition: all 600ms;
		// position: absolute !important;
		background-color: green;
		${props => props.direction === "back" ? "left" : "right"}: 0;
	}
	&.stacked-panel-enter-done {
		display: block;
	}

	&.stacked-panel-exit:not(.stacked-panel-exit-active) {
		// let it start outside
		display: block;
		position: absolute;
		background-color: red;
		${props => props.direction === "back" ? "left" : "right"}: 0;
	}

	// exit to
	&.stacked-panel-exit-active.stacked-panel-exit {
		display: block;
		position: absolute;
		transition: all 600ms;
		// position: absolute !important;
		background-color: green;
		${props => props.direction === "back" ? "left" : "right"}: 100%;
	}
	&.stacked-panel-exit-done {
		display: none;
	}
`;
const StackedPanelOutlet = observer(function StackedPanelWrapper(props) {
    const state = useSlidingPanelContext();
    return React.createElement(React.Fragment, null,
        state.panels.map((key, i) => {
            return React.createElement(Portal, { panelKey: key, key: i, style: props.portalStyle });
        }),
        props.children);
});
export const StackedPanelFooter = observer(function StackedPanelFooter(props) {
    const state = useSlidingPanelContext();
    const ownKey = useSlidingPanelKey();
    if (state.openPanel !== ownKey)
        return null;
    if (state.footerOutlet)
        return createPortal(props.children, state.footerOutlet);
    return React.createElement(React.Fragment, null, props.children);
});
export const StackedPanelFooterOutlet = observer(function StackedPanelFooterOutlet(props) {
    const state = useSlidingPanelContext();
    const ref = React.useRef();
    React.useEffect(() => {
        state.setFooterOutlet(ref.current);
    });
    return React.createElement("div", { ref: ref });
});
const Portal = (function Test(props, ref) {
    const state = useSlidingPanelContext();
    const innerRef = React.useRef();
    React.useEffect(() => {
        if (innerRef.current) {
            state.registerPortal(props.panelKey, innerRef);
        }
    }, [innerRef.current]);
    return React.createElement("div", { className: "SlidingPanelPortal", ref: innerRef, style: {
            ...(props.style ?? {}), position: "relative"
        } });
});
const Panel = observer(({ onlyRenderOnOpen, mountOnEnter = false, panelKey, showBack, onEnter, onEntering, onEntered, onExit, onExiting, onExited, children, }) => {
    const ctx = useSlidingPanelContext();
    if (!ctx)
        throw new Error("Could not find Context: StackedPanel.Panel may only be used inside of StackedPanel");
    React.useEffect(() => {
        ctx.registerPanel(panelKey);
        return () => ctx.unregisterPanel(panelKey);
    }, [panelKey]);
    const renderElement = React.useMemo(() => ctx.portals.get(panelKey)?.current, [ctx.portals, panelKey]);
    if (!renderElement)
        return null;
    return createPortal(React.createElement(SlidingPanelKeyCtx.Provider, { value: panelKey },
        React.createElement(CSSTransition, { classNames: "stacked-panel", timeout: 600, in: ctx.openPanel === panelKey, out: ctx.openPanel !== panelKey, mountOnEnter: mountOnEnter, onEnter: () => onEnter?.(), onEntering: () => onEntering?.(), onEntered: () => onEntered?.(), onExit: () => onExit?.(), onExiting: () => onExiting?.(), onExited: () => onExited?.() },
            React.createElement(Container, { style: { display: ctx.openPanel === panelKey ? "block" : undefined, height: "100%" }, direction: ctx.direction }, onlyRenderOnOpen ? (ctx.openPanel === panelKey && children) : children))), renderElement);
});
Panel.displayName = "SlidingPanel";
const Path = observer(() => {
    const { path: activityPath } = useSlidingPanelContext();
    const { path } = usePathContext();
    return React.createElement(WorkflowWidget, null, path.filter(e => activityPath.includes(e.id)).map((e, i) => React.createElement(WorkflowWidget.Step, { isActive: last(path) === e, variant: last(path) === e ? "primary" : "cancelled", key: i }, e.element)));
});
const Title = function Title(props) {
    usePath(useSlidingPanelKey(), React.createElement(Flex, { ...props }, props.children));
    return null;
};
export const SlidingPanel = Object.assign(SlidingPanelProvider, {
    Outlet: Object.assign(StackedPanelOutlet, {
        Path: Path,
        Footer: StackedPanelFooterOutlet,
    }),
    Panel: Panel,
    Footer: StackedPanelFooter,
    /** hooks */
    usePath: usePath,
    usePathContext: usePathContext,
    useSlidingPanel: useSlidingPanel,
    useSlidingPanelContext: useSlidingPanelContext,
    Title: Title,
});
