import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { useThemeColor } from "../../Css";
import { CommandBar } from "../CommandBar/CommandBar";
const ToastTimer = styled.div `
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 2px;
	background: ${props => props.bg};
	animation: toast-timer ${e => e.duration}s linear;

	@keyframes toast-timer {
		from {
			width: 0%;
		}
		to {
			width: 100%;
		}
	}
`;
const ToastContent = styled.div `
	position: relative;
	width: 270px;
	background: white;

	&:hover ${ToastTimer} {
		animation-play-state: paused;
	}
`;
const InternalToast = function InternalToast({ variant = "cancelled", icon, title, body, buttons, duration, dismissible, onDismiss, }) {
    const color = useThemeColor(variant);
    return React.createElement(React.Fragment, null,
        React.createElement(ToastContent, { style: { background: color[100].main, border: `1px solid ${color[500].main}` } },
            React.createElement(CommandBar, { noBorder: true, style: { background: color[100].main } },
                icon ? (React.createElement(CommandBar.Text, null,
                    React.createElement(FontAwesomeIcon, { icon: icon, fixedWidth: true }))) : null,
                React.createElement(CommandBar.Text, null, title),
                React.createElement(CommandBar.Right, null,
                    buttons,
                    dismissible ? (React.createElement(CommandBar.Button, { variant: "danger", onClick: onDismiss },
                        React.createElement(FontAwesomeIcon, { icon: faTimes, fixedWidth: true }))) : null)),
            body ? (React.createElement("div", { style: { padding: "0 7px 7px 7px" } }, body)) : null,
            duration ? (React.createElement(ToastTimer, { bg: color[500].main, duration: duration, onAnimationEnd: onDismiss })) : null));
};
export const Toast = Object.assign(InternalToast, {
    Content: ToastContent,
    Timer: ToastTimer,
});
