import { FpApi, FpId } from "@tcs-rliess/fp-core";
import { PDFExport, PDFExportProps, PrintContext } from "@tcs-rliess/fp-export";
import * as React from "react";

import { useApp } from "../FleetplanApp";

interface Props extends PDFExportProps {
	template?: string;
	[key: string]: any;
}

export const PDFExportPreview = (props: Props) => {
	const app = useApp();

	const job: FpApi.Export.ExportJob = {
		id: FpId.new(),
		dscid: app.dscid,
		rlog: {
			c: new Date().toISOString(),
			m: new Date().toISOString(),
			cb: app.dscaid,
			mb: app.dscaid,
		},

		batchId: FpId.new(),
		template: props.template,
		params: {} as any, // we don't need this for the preview, this is used for the actual PDF generation
		isPreview: true,

		start: new Date().toISOString(),
		finish: new Date().toISOString(),

		results: [],
	};

	return (
		<PrintContext.Provider value={{
			ctx: app.ctx,
			brand: app.brand, // 'fleetplan' or 'documently'
			customer: app.customer, // depends on project this job comes from
			contact: app.$dscaid,
			userSettings: app.userSettings,
			job: job,
			title: "PDF Preview",
		}}>
			<PDFExport {...props} />
		</PrintContext.Provider>
	);
};
