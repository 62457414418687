import React from "react";
import styled from "styled-components";
const Node = styled.div `
	position: relative;
	min-width: 100%;

	background: ${p => p.active ? p.theme.tree.active : "transparent"};
	&:hover {
		background: ${p => p.active ? p.theme.tree.activeHover : p.theme.tree.hover};
	}
`;
export const FleetplanTreeNodeRenderer = function FleetplanTreeNodeRenderer({ children, listIndex, swapFrom = null, swapLength = null, swapDepth = null, scaffoldBlockPxWidth, lowerSiblingCounts, connectDropTarget, isOver, draggedNode = null, canDrop = false, treeIndex, treeId, // Delete from otherProps
getPrevRow, // Delete from otherProps
node, // Delete from otherProps
path, // Delete from otherProps
rowHeight = 25, disableDropFromOutside = false, ...otherProps }) {
    return React.createElement(Node, { ...otherProps, active: node.active, ref: connectDropTarget }, React.Children.map(children, (child) => React.cloneElement(child, {
        isOver,
        canDrop,
        draggedNode,
        lowerSiblingCounts,
        listIndex,
        swapFrom,
        swapLength,
        swapDepth,
        rowHeight,
    })));
};
