var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { faChevronDoubleRight, faChevronDoubleUp, faChevronDown, faChevronLeft, faChevronRight, faFilter, faChevronDoubleLeft, faChevronUp, faChevronDoubleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce, differenceBy, last } from "lodash";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import styled, { useTheme } from "styled-components";
import { Input } from "../../Forms";
import { Button } from "../Button";
import { Flex, FlexItem } from "../Flex";
import { Label } from "../Label";
class FilterState {
    constructor() {
        this.debounceFilter = debounce((str) => {
            action(() => {
                this.filter = str;
            })();
        }, 1000);
    }
    setFilterInput(input) {
        this.filterInput = input;
        this.debounceFilter(this.filterInput);
    }
    setFilter(filter) {
        this.filter = filter;
    }
    doesMatch(str) {
        if (!this.filter)
            return true;
        return str.toLowerCase().includes(this.filter.toLowerCase());
    }
}
__decorate([
    observable
], FilterState.prototype, "filter", void 0);
__decorate([
    observable
], FilterState.prototype, "filterInput", void 0);
__decorate([
    action
], FilterState.prototype, "setFilterInput", null);
__decorate([
    action
], FilterState.prototype, "setFilter", null);
__decorate([
    action
], FilterState.prototype, "debounceFilter", void 0);
export class TransferListState {
    constructor(origOptions) {
        this.origOptions = origOptions;
        this.list = [];
        this.listMap = new Map();
        this._sideA = [];
        this._sideB = [];
        this.filters = {
            "in": new FilterState(),
            "out": new FilterState(),
        };
        this.value = [];
        this.selectionA = [];
        this.selectionB = [];
        this.setKeyGetter(origOptions.getKey);
        this.setLabelGetter(origOptions.getLabel);
        // if(origOptions.value) this.setValue(origOptions.value, true); // skip update because setList does also an update
        this.setList(origOptions.list, true);
    }
    forceValues(v) {
        this._sideA = [...v.a];
        this._sideB = [...v.b];
        this.list = [...this._sideA, ...this._sideB];
        this.update();
    }
    setSelection(selection, direction) {
        if (direction === "in") {
            this.selectionA = [...selection];
            this.lastSelectedA = last(selection);
        }
        else {
            this.selectionB = [...selection];
            this.lastSelectedA = last(selection);
        }
    }
    addSelection(item, mode = "replace", direction) {
        if (direction === "in") {
            if (mode === "replace")
                this.selectionA = [item];
            else if (mode === "add")
                this.selectionA.push(item);
            else if (mode === "range") {
                const key = this.getKey(item);
                let start = this._sideA.findIndex(e => this.getKey(e) === key);
                let end = this._sideA.findIndex(e => this.getKey(this.lastSelectedA) === this.getKey(e));
                if (start > end)
                    [start, end] = [end, start]; // swap it
                this.selectionA = this._sideA.slice(start, end + 1);
            }
            this.lastSelectedA = item;
        }
        else {
            if (mode === "replace")
                this.selectionB = [item];
            else if (mode === "add")
                this.selectionB.push(item);
            else if (mode === "range") {
                const key = this.getKey(item);
                let start = this._sideB.findIndex(e => this.getKey(e) === key);
                let end = this._sideB.findIndex(e => this.getKey(this.lastSelectedB) === this.getKey(e));
                if (start > end)
                    [start, end] = [end, start]; // swap it
                this.selectionB = this._sideB.slice(start, end + 1);
            }
            this.lastSelectedB = item;
        }
    }
    setValue(value, skipUpdate = false) {
        this.value = [...value];
        if (!skipUpdate)
            this.update();
    }
    addValues(value, skipUpdate = false) {
        this.value = [...value, ...this.value];
        if (!skipUpdate)
            this.update();
    }
    dropValues(value, skipUpdate = false) {
        this.value = this.value.filter(e => !value.find(p => this.getKey(e) === this.getKey(p)));
        if (!skipUpdate)
            this.update();
    }
    transfer(item, direction) {
        const key = this.getKey(item);
        if (direction === "in") {
            this.setSelection(this.selectionA.filter(e => this.getKey(e) !== key), "in");
            this.value.push(this.list.find(e => key === this.getKey(e)));
        }
        else {
            this.setSelection(this.selectionB.filter(e => this.getKey(e) !== key), "out");
            this.value = this.value.filter(e => this.getKey(e) !== key);
        }
        this.update();
    }
    get sideA() {
        return this._sideA;
    }
    get sideB() {
        return this._sideB;
    }
    update() {
        const clone = [...this.sideB];
        // call internal update and call onChange event
        [this._sideA, this._sideB] = Array.from(this.listMap.entries()).reduce(([a, b], next) => {
            const key = next[0];
            if (this.value.find(n => this.getKey(n) === key))
                b.push(next[1]);
            else
                a.push(next[1]);
            return [a, b];
        }, [[], []]);
        const added = differenceBy(this._sideB, clone, this.getKey);
        const removed = differenceBy(clone, this._sideB, this.getKey);
        let type;
        if (this._sideB.length === this.list.length)
            type = "added_all";
        else if (this._sideB.length === 0)
            type = "removed_all";
        else if (added.length > 0)
            type = "added";
        else if (removed.length > 0)
            type = "removed";
        if (added.length || removed.length)
            this.onChange?.(this._sideB, {
                added,
                removed,
                action: type,
            });
    }
    setList(list, skipUpdate = false) {
        this.list = [...list];
        this.listMap = new Map(list.map(e => [this.getKey(e), e]));
        if (!skipUpdate)
            this.update();
    }
    setKeyGetter(getKey) {
        this.getKey = getKey;
    }
    setOnChange(setter) {
        this.onChange = setter;
    }
    setLabelGetter(getLabel) {
        this.getLabel = getLabel;
    }
}
__decorate([
    observable.shallow
], TransferListState.prototype, "list", void 0);
__decorate([
    observable
], TransferListState.prototype, "_sideA", void 0);
__decorate([
    observable
], TransferListState.prototype, "_sideB", void 0);
__decorate([
    observable
], TransferListState.prototype, "value", void 0);
__decorate([
    observable.shallow
], TransferListState.prototype, "selectionA", void 0);
__decorate([
    observable.shallow
], TransferListState.prototype, "lastSelectedA", void 0);
__decorate([
    observable.shallow
], TransferListState.prototype, "selectionB", void 0);
__decorate([
    observable.shallow
], TransferListState.prototype, "lastSelectedB", void 0);
__decorate([
    action
], TransferListState.prototype, "forceValues", null);
__decorate([
    action
], TransferListState.prototype, "setSelection", null);
__decorate([
    action
], TransferListState.prototype, "addSelection", null);
__decorate([
    action
], TransferListState.prototype, "setValue", null);
__decorate([
    action
], TransferListState.prototype, "addValues", null);
__decorate([
    action
], TransferListState.prototype, "dropValues", null);
__decorate([
    action
], TransferListState.prototype, "transfer", null);
__decorate([
    action
], TransferListState.prototype, "update", null);
__decorate([
    action
], TransferListState.prototype, "setList", null);
export const TransferList = observer(function TransferList(props) {
    const [state] = React.useState(() => new TransferListState(props));
    React.useEffect(() => {
        state.setList(props.list);
    }, [props.list, state]);
    React.useEffect(() => {
        const getLabel = (typeof props.getLabel === "string") ? (item) => item[props.getLabel] : props.getLabel;
        if (getLabel)
            state.setLabelGetter(getLabel);
    }, [props.getLabel, state]);
    React.useEffect(() => {
        const getKey = (typeof props.getKey === "string") ? (item) => item[props.getKey] : props.getKey;
        state.setKeyGetter(getKey);
    }, [props.getKey, state]);
    React.useEffect(() => {
        if (props.value)
            state.setValue(props.value);
    }, [props.value, state]);
    React.useEffect(() => {
        if (props.onChange)
            state.setOnChange(props.onChange);
    }, [props.onChange, state]);
    React.useEffect(() => {
        if (props.simpleValue)
            state.setValue(state.list.filter(e => props.simpleValue.includes(state.getKey(e))));
    }, [props.simpleValue, state]);
    return React.createElement(Flex, { column: props.layout === "up-down", row: props.layout === "left-right", style: { height: `calc(${props.styles.left.height} + ${props.styles.right.height} + 30px)` } },
        React.createElement(Flex, { grow: true, column: true, style: { border: "1px solid #ccc", height: 200, ...props.styles.left } },
            React.createElement(Title, null, props.containerTitle),
            React.createElement(FlexItem, null,
                React.createElement(FilterBar, { state: state.filters["in"] })),
            React.createElement(FlexItem, { style: { height: "calc(100% - 50px)", overflow: "auto" } },
                React.createElement(ItemList, { ...props, actionType: "in", list: state._sideA, selection: state.selectionA, state: state }))),
        React.createElement(Flex, { shrink: true, column: true, gap: 5 },
            React.createElement(FlexItem, { grow: true }),
            React.createElement(Flex, { style: { margin: "auto" }, column: props.layout === "left-right", row: props.layout === "up-down" },
                React.createElement(Button, { size: "sm", variant: "link", onClick: () => {
                        state.setValue([]);
                        state.setSelection([], "out");
                    } },
                    React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: props.layout === "left-right" ? faChevronDoubleLeft : faChevronDoubleUp })),
                React.createElement(Button, { size: "sm", variant: "link", disabled: state.selectionB.length === 0, onClick: () => {
                        state.dropValues(state.selectionB);
                        state.setSelection([], "out");
                    } },
                    React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: props.layout === "left-right" ? faChevronLeft : faChevronUp })),
                React.createElement(Button, { size: "sm", variant: "link", disabled: state.selectionA.length === 0, onClick: () => {
                        state.addValues(state.selectionA);
                        state.setSelection([], "in");
                    } },
                    React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: props.layout === "left-right" ? faChevronRight : faChevronDown })),
                React.createElement(Button, { size: "sm", variant: "link", onClick: () => {
                        state.setValue(state.list);
                        state.setSelection([], "in");
                    } },
                    React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: props.layout === "left-right" ? faChevronDoubleRight : faChevronDoubleDown }))),
            React.createElement(FlexItem, { grow: true })),
        React.createElement(Flex, { grow: true, column: true, style: { border: "1px solid #ccc", height: 200, ...props.styles.right } },
            React.createElement(Title, null, props.valueTitle),
            React.createElement(FlexItem, null,
                React.createElement(FilterBar, { state: state.filters["out"] })),
            React.createElement(FlexItem, { style: { height: "calc(100% - 50px)", overflow: "auto" } },
                React.createElement(ItemList, { ...props, actionType: "out", list: state._sideB, selection: state.selectionB, state: state }))));
});
const Title = styled(FlexItem) `
	padding: 2px 8px;
`;
const TransferInline = styled(Flex) `
	cursor: pointer;
	margin: -4px;
	padding: 4px;
	padding-left: 12px;
	padding-right: 12px;
	&:hover {
		background-color: ${e => (e.$activeVariant ? (e.$activeVariant in e.theme.color) ? e.theme.color[e.$activeVariant].background : e.$activeVariant : e.theme.color.primary[100].main)};
		filter: brightness(0.9);
	}
`;
const Item = styled(Label) `
	padding-left: 4px;
	padding-right: 4px;
	user-select: none;
	display: flex;
	text-transform: none !important;
	border-left: none;
	border-right: none;
	border-left: none;
	&:not(:last-child) {
		border-bottom: none;
	}
	&:first-child {
		border-top: none;
	}
	${e => e.$active && `
		background-color: ${(e.$activeVariant ? (e.$activeVariant in e.theme.color) ? e.theme.color[e.$activeVariant].background : e.$activeVariant : e.theme.color.primary[100].main)} !important;
	`}
	&:hover {
		background-color: ${e => (e.$activeVariant ? (e.$activeVariant in e.theme.color) ? e.theme.color[e.$activeVariant].background : e.$activeVariant : e.theme.color.primary[100].main)};
		filter: brightness(0.9);
	}
`;
const FilterBar = observer(function FilterBar({ state }) {
    const theme = useTheme();
    return React.createElement("div", { style: { position: "relative" } },
        React.createElement(Input, { bootstrap: false, placeholder: "Filter...", style: { width: "100%" }, value: state.filterInput, onChange: (e) => state.setFilterInput(e.target.value) }),
        state.filter && React.createElement("span", { onClick: () => {
                state.setFilter("");
                state.setFilterInput("");
            }, style: { position: "absolute", top: ".2rem", right: ".2rem", color: theme.color.primary[500].main, textDecoration: "underline", cursor: "pointer" } },
            React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: faFilter })));
});
const ItemList = React.memo(observer(function ItemList({ list, actionType, selection, state, ...props }) {
    return React.createElement(React.Fragment, null, list.filter(e => {
        const label = props.getFilterValue ? props.getFilterValue(e) : state.getLabel(e);
        return state.filters[actionType].doesMatch(label);
    }).map((item, i) => {
        return React.createElement(Item, { key: i, fullWidth: true, variant: "white", "$activeVariant": "primary", "$active": selection.includes(item), onClick: (e) => {
                if (e.shiftKey) {
                    state.addSelection(item, "range", actionType);
                }
                else if (e.metaKey) {
                    state.addSelection(item, "add", actionType);
                }
                else {
                    state.addSelection(item, "replace", actionType);
                }
            } },
            props.layout === "up-down" && React.createElement(React.Fragment, null,
                React.createElement(FlexItem, { shrink: true }, props.renderLabel ? props.renderLabel(state.getLabel(item), item) : state.getLabel(item)),
                React.createElement(FlexItem, { grow: true })),
            React.createElement(TransferInline, { justifyContent: "center", alignItems: "center", onClick: e => {
                    e.stopPropagation();
                    state.transfer(item, actionType);
                } },
                React.createElement(FontAwesomeIcon, { onClick: () => {
                        state.transfer(item, actionType);
                    }, fixedWidth: true, icon: actionType === "out" ?
                        props.layout === "left-right" ? faChevronLeft : faChevronUp :
                        props.layout === "left-right" ? faChevronRight : faChevronDown })),
            props.layout === "left-right" && React.createElement(React.Fragment, null,
                React.createElement(FlexItem, { grow: true }),
                React.createElement(FlexItem, { shrink: true }, props.renderLabel ? props.renderLabel(state.getLabel(item), item) : state.getLabel(item))));
    }));
}), (prev, next) => {
    return prev.list === next.list && prev.actionType === next.actionType && prev.selection === next.selection;
});
TransferList.defaultProps = {
    enabledSelectionTypes: ["replace", "add", "range"],
    layout: "left-right",
};
