import { fpLog } from "@tcs-rliess/fp-log";
import { isMatch, remove } from "lodash-es";

import { FleetplanApp } from "../../FleetplanApp";
import { handleError } from "../../handleError";

export enum EventStreamImpact {
	/**
	 * You do not react this event by generating a network request.
	 * 
	 * Example:
	 * Show the user an indicator that there (might) is new data available for the grid he's currently viewing. But not automatically loading it.
	 */
	NoNetworkReaction,
	/**
	 * One or only a few users are actively waiting for this event, mainly after they took another action.
	 * 
	 * Example:
	 * You're in a detail view and and are waiting for the result of an asynchronous action. For example the creation of a flight log entry or comdoc item.
	 */
	OneUserActivelyWaiting,
	/**
	 * One or only a few users will listen and react to this Event. But no user is actively waiting for this event.
	 * 
	 * Example:
	 * Reload a detail view after some one else updated the item.
	 */
	SomeUsers,
	/**
	 * All Users will react to this event.
	 * 
	 * Example:
	 * An update to the directory, all users will reload the directory.
	 */
	AllUsers,
}

/**
 * Jitter amount in milliseconds.
 * We will apply a random if jitter to each event we receive to spread out requests and not DDOS our own server.
 */
const JITTER: Record<EventStreamImpact, number> = {
	[EventStreamImpact.NoNetworkReaction]: 250,
	[EventStreamImpact.OneUserActivelyWaiting]: 500,
	[EventStreamImpact.SomeUsers]: 10_000,
	[EventStreamImpact.AllUsers]: 30_000,
};

/**
 * all fields will always arrive as a string!
 * 
 * @link https://github.com/smrchy/tcs-logs/blob/master/_src/eventstream/README.md
 */
export interface EventStreamItem {
	/**
	 * time of event
	 * `${millisecond timestamp}-${sequence number}`
	 */
	i: string;
	/** application */
	a: string;
	/** scope */
	s: string;
	/** type */
	t: string;
	/** editor */
	e?: string;

	/** dscaid */
	ca?: string;
	/** crewlog id */
	cl?: string;
	/** leg id */
	l?: string;
	/** fpreslogid */
	lb?: string;
	/** fpvid */
	v?: string;
	/** tree id */
	tr?: string;
	/** directory id */
	d?: string;
	/** member id */
	me?: string;
	/** calendar event id */
	ei?: string;
	/** resource duty id */
	rd?: string;
	/** resource schedule id */
	rs?: string;
	/** media target (e.g. certificate id) */
	mt?: string;
	/** media key (last part of uuid) */
	mk?: string;

	/**
	 * start
	 * millisecond timestamp
	 */
	st?: string;
	/**
	 * end
	 * millisecond timestamp
	 */
	en?: string;
}

type ListenerCallBack = (items: EventStreamItem[]) => void;

interface Listener {
	// eslint-disable-next-line @typescript-eslint/ban-types
	filter: object;
	callback: ListenerCallBack;
	impact: EventStreamImpact;
	jitter: number;
	once: boolean;

	timeoutId?: number | NodeJS.Timeout;
	queued: EventStreamItem[];
}

export interface EventStreamSubscribeParams {
	impact: EventStreamImpact;
	filter: Partial<EventStreamItem>;
	callback: ListenerCallBack;
	/**
	 * Trigger an unsubscribe after being triggered once.
	 */
	once?: boolean;
}

export class EventStreamManager {
	private log = fpLog.child("EventStreamManager")
	private lastMessageTimestamp = +new Date();

	private listeners: Listener[] = [];

	constructor(app: FleetplanApp) {
		app.mqtt.subscribe("/fp-eventstream", this.handleMessage.bind(this)).catch(handleError);

		app.mqtt.addListener("close", () => {
			this.log.info(`reconnected, last message was: ${this.lastMessageTimestamp}`);
			// technically we could do a replay via the tcs-logs api
			// http://tcs-logs.eks.stage.internal.fleetplan.net:30015/apidoc/#api-Eventstreams-RangeEventstream
		});
	}

	/**
	 * Adds a listener to the event stream, when a message matches the given filter the callback will be called.
	 * 
	 * - events will only be send to the highest impact listener matching
	 *   if there is a "one user" and a "all users" listener, only the "one user" listener will be called
	 * 
	 * @param params your callback, filter, ...
	 * @returns 
	 */
	public subscribe(params: EventStreamSubscribeParams): () => void {
		if (params.filter.a == null) throw new Error("EventStreamManager: filter must have an app!");

		this.listeners.push({
			filter: params.filter,
			callback: params.callback,
			impact: params.impact,
			jitter: JITTER[params.impact],
			once: params.once ?? false,

			queued: [],
		});

		// return a clean up function
		return () => {
			this.unsubscribe(params.callback);
		};
	}

	/**
	 * Removes __all__ listeners for this callback
	 * @param callback callback to remove
	 */
	public unsubscribe(callback: ListenerCallBack): void {
		remove(this.listeners, l => l.callback === callback);
	}

	private handleMessage(channel: string, item: EventStreamItem): void {
		this.lastMessageTimestamp = parseInt(item.i.split("-")[0]);
		// this.log.info("event", { item });

		// find all matching listeners
		let listeners = this.listeners.filter(listener => {
			return isMatch(item, listener.filter);
		});

		// look for the highest "impact" value we have
		// one user > some users > all users
		const highestImpact = Math.min(...listeners.map(l => l.impact));

		// filter listeners again, we're only sending the event to the highest impact / most urgent one
		listeners = listeners.filter(listener => listener.impact === highestImpact);

		// call listeners
		for (const listener of listeners) {
			// unsubscribe if listener is a "once"
			if (listener.once) {
				this.unsubscribe(listener.callback);
			}

			// queue event
			listener.queued.push(item);

			// start a timeout
			if (listener.timeoutId == null) {
				const jitter = Math.random() * listener.jitter;
				listener.timeoutId = setTimeout(() => {
					listener.callback(listener.queued);
					// reset
					listener.queued = [];
					listener.timeoutId = null;
				}, jitter);
			}
		}
	}
}
